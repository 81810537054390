var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { everyone, Role } from '@obtainly/bootstrap';
import { complaintPermissions } from 'src/complaint';
import { creditPermissions } from 'src/credit';
import { distributorPermissions } from 'src/distributor';
import { feedPermissions } from 'src/feed';
import { ledgerPermissions } from 'src/ledger';
import { locationPermissions } from 'src/location';
import { merchantPermissions } from 'src/merchant';
import { orderPermissions } from 'src/order';
import { pricePermissions } from 'src/price';
import { productPermissions } from 'src/product';
import { promotionPermissions } from 'src/promotion';
import { targetPermissions } from 'src/target';
export var permissions = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], complaintPermissions, true), creditPermissions, true), distributorPermissions, true), feedPermissions, true), ledgerPermissions, true), locationPermissions, true), merchantPermissions, true), orderPermissions, true), pricePermissions, true), productPermissions, true), promotionPermissions, true), targetPermissions, true), [
    { id: 'view_reports', roles: [Role.DistributorStaff] },
    { id: 'view_settings', roles: __spreadArray([], everyone, true) },
], false);

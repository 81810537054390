var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import sortBy from 'lodash/sortBy';
import { Autocomplete } from 'src/core/components';
import { useProductReadBy } from '@obtainly/models';
import { useAtom, distributorAtom } from 'src/core/store';
export var ProductAutocomplete = function (props) {
    var value = props.value, onChange = props.onChange, presetProducts = props.presetProducts, rest = __rest(props, ["value", "onChange", "presetProducts"]);
    var distributor = useAtom(distributorAtom)[0];
    var data = useProductReadBy({ distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }).data;
    var products = React.useMemo(function () {
        return (presetProducts === null || presetProducts === void 0 ? void 0 : presetProducts.length) ? presetProducts : data;
    }, [data, presetProducts]);
    var productOptions = React.useMemo(function () {
        if (products === null || products === void 0 ? void 0 : products.length) {
            var options = [];
            if (!props.isMulti && !props.disableSelectAll) {
                options.push({ value: '*', label: 'All Products' });
            }
            var filtered = products
                .filter(function (product) {
                var _a;
                if (((_a = props.productIds) === null || _a === void 0 ? void 0 : _a.length) && !(presetProducts === null || presetProducts === void 0 ? void 0 : presetProducts.length)) {
                    return props.productIds.includes((product === null || product === void 0 ? void 0 : product._id) || '');
                }
                return props.showInactive ? true : product.status === 'active';
            })
                .map(function (product) { return ({
                value: product._id,
                label: product.name,
            }); });
            return __spreadArray(__spreadArray([], options, true), sortBy(filtered, function (product) { return product.label; }), true);
        }
        return [];
    }, [
        products,
        props.isMulti,
        props.disableSelectAll,
        props.productIds,
        presetProducts,
        props.showInactive,
    ]);
    return (_jsx(Autocomplete, __assign({ value: value || null, options: productOptions, placeholder: "Select product", onChange: function (selected) {
            return onChange(selected, products === null || products === void 0 ? void 0 : products.find(function (product) { return product._id === selected.value; }));
        }, isActive: value && value.value !== '*' ? true : false, isDisabled: !(productOptions === null || productOptions === void 0 ? void 0 : productOptions.length) }, rest), void 0));
};

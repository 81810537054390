var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { OrderTag, useProductReadBy, } from '@obtainly/models';
import { useQueryModel } from '@obtainly/http';
import { useOrderStateMachine } from './useOrderStateMachine';
export function useOrder(orderId) {
    var _a = useQueryModel('order.read', { _id: orderId }, { enabled: !!orderId && orderId !== 'new' }), orders = _a.data, orderModel = __rest(_a, ["data"]);
    var order = React.useMemo(function () { return orders === null || orders === void 0 ? void 0 : orders[0]; }, [orders]);
    // Get products
    var _b = useProductReadBy({
        distributor_id: order === null || order === void 0 ? void 0 : order.distributor_id,
        merchant_id: order === null || order === void 0 ? void 0 : order.merchant_id,
    }), allProducts = _b.data, productsModel = __rest(_b, ["data"]);
    var products = React.useMemo(function () {
        return allProducts === null || allProducts === void 0 ? void 0 : allProducts.filter(function (product) { return product.status === 'active'; });
    }, [allProducts]);
    // Handle order tag states
    var orderStates = useOrderStateMachine(order || null, orderModel === null || orderModel === void 0 ? void 0 : orderModel.refetch);
    // Combine related states
    var isLoading = (orderModel === null || orderModel === void 0 ? void 0 : orderModel.isLoading) || (productsModel === null || productsModel === void 0 ? void 0 : productsModel.isLoading);
    var isRefetching = (orderModel === null || orderModel === void 0 ? void 0 : orderModel.isRefetching) || (productsModel === null || productsModel === void 0 ? void 0 : productsModel.isRefetching);
    var isEditable = React.useMemo(function () {
        var _isEditable = true;
        var nonEditableStates = [
            OrderTag.Processing,
            OrderTag.InTransit,
            OrderTag.Delivered,
            OrderTag.Cancelled,
        ];
        if (nonEditableStates.includes(order === null || order === void 0 ? void 0 : order.tag)) {
            return false;
        }
        return _isEditable;
    }, [order === null || order === void 0 ? void 0 : order.tag]); // eslint-disable-line
    return {
        order: order,
        orderModel: orderModel,
        orderItems: (order === null || order === void 0 ? void 0 : order.items) ? Object.values(order.items) : [],
        products: products,
        productsModel: productsModel,
        orderStates: orderStates,
        isLoading: isLoading,
        isRefetching: isRefetching,
        isEditable: isEditable,
    };
}

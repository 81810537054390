var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField, FormGroup, FormikRadioGroup } from 'src/core/components';
import { VStack } from '@chakra-ui/layout';
import { useToast } from 'src/core/hooks';
import { useAtom, distributorAtom } from 'src/core/store';
import { useMutateModel } from '@obtainly/http';
var priceFormSchema = Yup.object({
    ref: Yup.string().required('Price reference is required'),
    status: Yup.string().required('Price status is required'),
    description: Yup.string().required('Price description is required'),
    discount: Yup.number().required('Price discount is required'),
    is_percentage: Yup.string().required('Percentage is required'),
});
export var PriceForm = function (props) {
    var _a;
    var distributor = useAtom(distributorAtom)[0];
    var toast = useToast().toast;
    var action = props.action, price = props.price;
    var createPrice = useMutateModel('price.create');
    var updatePrice = useMutateModel('price.update');
    var initialValues = {
        ref: (price === null || price === void 0 ? void 0 : price.ref) || '',
        status: (price === null || price === void 0 ? void 0 : price.status) || 'active',
        description: (price === null || price === void 0 ? void 0 : price.description) || '',
        discount: (price === null || price === void 0 ? void 0 : price.discount) || 0,
        is_percentage: ((_a = price === null || price === void 0 ? void 0 : price.is_percentage) === null || _a === void 0 ? void 0 : _a.toString()) || 'false',
        distributor_id: (price === null || price === void 0 ? void 0 : price.distributor_id) || (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createPrice.mutate(values, {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Price created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updatePrice.mutate(__assign(__assign({}, values), { _id: price === null || price === void 0 ? void 0 : price._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Price saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: priceFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var values = _a.values, formik = __rest(_a, ["values"]);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Price"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'active', label: 'Active' },
                                                        { value: 'inactive', label: 'Inactive' },
                                                    ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Reference" }, { children: _jsx(FormikField, { size: "sm", name: "ref", placeholder: "Ex. CLASS_A" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Description" }, { children: _jsx(FormikField, { as: "textarea", size: "sm", name: "description", placeholder: "Describe price class like a scribe." }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Discount (positive or negative)" }, { children: _jsx(FormikField, { size: "sm", type: "number", name: "discount", prepend: values.is_percentage === 'true' ? '%' : 'NGN' }, void 0) }), void 0), values.discount !== 0 && (_jsx(FormGroup, __assign({ label: "Is discount in percentage?" }, { children: _jsx(FormikRadioGroup, { name: "is_percentage", options: [
                                                        { value: 'false', label: 'No' },
                                                        { value: 'true', label: 'Yes' },
                                                    ] }, void 0) }), void 0))] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createPrice.isLoading || updatePrice.isLoading, isDisabled: createPrice.isLoading ||
                                                updatePrice.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Price Class"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
PriceForm.defaultProps = {
    action: 'create',
};

import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useOrderItem } from '../hooks';
import { OrderContext } from '../pages/Order';
import { OrderItemContainer } from './OrderItemContainer';
export var OrderItem = function (_a) {
    var orderItem = _a.orderItem;
    var refreshOrder = React.useContext(OrderContext).refreshOrder;
    var _b = useOrderItem({
        orderItem: orderItem,
        refreshOrder: refreshOrder,
    }), quantity = _b.quantity, setQuantity = _b.setQuantity, rate = _b.rate, setRate = _b.setRate, updateItem = _b.updateItem, isUpdating = _b.isUpdating, isRemoving = _b.isRemoving;
    return (_jsx(OrderItemContainer, { type: "item", product: orderItem === null || orderItem === void 0 ? void 0 : orderItem.product, orderItemLogic: {
            rate: rate,
            setRate: setRate,
            quantity: quantity,
            setQuantity: setQuantity,
            isUpdating: isUpdating,
            isRemoving: isRemoving,
            updateItem: updateItem,
        }, freeItem: orderItem === null || orderItem === void 0 ? void 0 : orderItem.free_item, display: quantity > 0 ? 'block' : 'none' }, void 0));
};

import { Role } from '@obtainly/bootstrap';
import { Credits } from './pages/Credits';
export var creditPermissions = [
    { id: 'view_credits', roles: [Role.DistributorStaff] },
    { id: 'create_credit', roles: [Role.DistributorStaff] },
    { id: 'update_credit', roles: [Role.DistributorStaff] },
];
export var creditRoutes = [
    {
        id: 'credits',
        path: '/credits',
        component: Credits,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, Flex, Grid, VStack, Button } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { basicSearch } from '@obtainly/utils';
import { useQueryModel } from '@obtainly/http';
import { useProductReadBy } from '@obtainly/models';
import { useAtom, distributorAtom } from 'src/core/store';
import { PriceDetails, PriceItemsTable, PriceItemForm } from '../components';
import { Card, CardBody, CardHeader, ContentHeader, ContentLoader, Exporter, Importer, Layout, SearchInput, } from 'src/core/components';
var exportTemplateColumns = [
    { label: 'Product ID', accessor: 'product._id', selected: true },
    { label: 'Product Name', accessor: 'product.name', selected: false },
    { label: 'SKU ID', accessor: 'product.sku_id', selected: false },
    {
        label: 'Value',
        accessor: 'price_item_value',
        selected: true,
    },
];
var importColumns = function (price) {
    return [
        { id: 'product_id', label: 'Product ID' },
        { id: 'value', label: 'Value' },
        { id: 'price_id', label: 'Price ID', value: price._id },
    ];
};
export var Price = function () {
    var distributor = useAtom(distributorAtom)[0];
    var history = useHistory();
    var _a = useState(false), createMode = _a[0], setCreateMode = _a[1];
    var _b = useState(''), searchQuery = _b[0], setSearchQuery = _b[1];
    var priceId = useParams().id;
    var _c = useQueryModel('price.read', {
        _id: priceId,
    }), prices = _c.data, isLoading = _c.isLoading, refetch = _c.refetch;
    var price = useMemo(function () {
        return (prices === null || prices === void 0 ? void 0 : prices[0]) || null;
    }, [prices]);
    var _d = useQueryModel('price_item.read_by_price', {
        price_id: price === null || price === void 0 ? void 0 : price._id,
    }), priceItems = _d.data, isLoadingPriceItems = _d.isLoading, refetchPriceItems = _d.refetch;
    var filteredPriceItems = React.useMemo(function () {
        return (priceItems === null || priceItems === void 0 ? void 0 : priceItems.length)
            ? priceItems.filter(function (priceItem) {
                var _a, _b;
                return priceItem.product &&
                    (basicSearch((_a = priceItem.product) === null || _a === void 0 ? void 0 : _a.name, searchQuery) ||
                        basicSearch((_b = priceItem.product) === null || _b === void 0 ? void 0 : _b.sku_id, searchQuery));
            })
            : [];
    }, [searchQuery, priceItems]);
    var _e = useProductReadBy({
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }), products = _e.data, isLoadingProducts = _e.isLoading;
    var templateData = React.useMemo(function () {
        return ((products === null || products === void 0 ? void 0 : products.map(function (product) {
            var priceItem = priceItems === null || priceItems === void 0 ? void 0 : priceItems.find(function (item) { return item.product_id === product._id; });
            return {
                product: product,
                price: price,
                price_item_value: (priceItem === null || priceItem === void 0 ? void 0 : priceItem.value) || 0,
            };
        })) || []);
    }, [products, price, priceItems]);
    return (_jsx(Layout, __assign({ meta: { title: 'Prices' } }, { children: !price ? (_jsx(ContentLoader, { isLoading: isLoading || isLoadingPriceItems, errorSummary: "Price not found" }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(ContentHeader, __assign({ heading: 'Price', onBack: function () { return history.goBack(); } }, { children: _jsx(Flex, __assign({ gridGap: 2 }, { children: price && price.discount === 0 ? (_jsxs(_Fragment, { children: [_jsx(Exporter, { label: "Download Template", filename: "obtainly-prices", data: templateData, columns: exportTemplateColumns, isDisabled: isLoading ||
                                        isLoadingPriceItems ||
                                        isLoadingProducts ||
                                        !(products === null || products === void 0 ? void 0 : products.length) ||
                                        !(templateData === null || templateData === void 0 ? void 0 : templateData.length) ||
                                        price.discount !== 0 }, void 0), _jsx(Importer, { model: "price_item", label: "Upload Price Class", columns: importColumns(price), onSuccess: function () {
                                        refetch();
                                        refetchPriceItems();
                                    } }, void 0)] }, void 0)) : null }), void 0) }), void 0), _jsxs(Grid, __assign({ templateColumns: ['1fr', '1fr', '1fr 2fr'], gridGap: 5 }, { children: [_jsx(Box, { children: _jsx(PriceDetails, { price: price }, void 0) }, void 0), _jsx(VStack, __assign({ spacing: 5, alignItems: "stretch", overflowX: "auto" }, { children: _jsxs(Card, __assign({ position: "sticky" }, { children: [_jsxs(CardHeader, __assign({ heading: "Product Prices" }, { children: [_jsx(Button, __assign({ size: "sm", variant: "ghost", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () { return setCreateMode(true); } }, { children: "Add Product Price" }), void 0), _jsx(PriceItemForm, { price: price, isOpen: createMode, onCancel: function () { return setCreateMode(false); }, onSuccess: function () {
                                                    setCreateMode(false);
                                                } }, void 0)] }), void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: searchQuery || '', onChange: function (value) { return setSearchQuery(value); }, placeholder: "Search product prices", isDisabled: !(priceItems === null || priceItems === void 0 ? void 0 : priceItems.length) }, void 0) }, void 0), _jsx(CardBody, __assign({ p: 0 }, { children: price && price.discount === 0 ? (_jsx(PriceItemsTable, { price: price, priceItems: filteredPriceItems || [] }, void 0)) : (_jsx(ContentLoader, { contentUnavailable: true, errorSummary: "Price discount applied across all products" }, void 0)) }), void 0)] }), void 0) }), void 0)] }), void 0)] }, void 0)) }), void 0));
};

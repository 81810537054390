var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import sortBy from 'lodash/sortBy';
import { Autocomplete } from 'src/core/components';
import { usePriceReadByDistributor } from '@obtainly/models';
import { useAtom, distributorAtom } from 'src/core/store';
export var PriceAutocomplete = function (props) {
    var value = props.value, onChange = props.onChange, rest = __rest(props, ["value", "onChange"]);
    var distributor = useAtom(distributorAtom)[0];
    var prices = usePriceReadByDistributor({
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }).data;
    var priceOptions = React.useMemo(function () {
        if (prices === null || prices === void 0 ? void 0 : prices.length) {
            var options = [];
            if (!props.isMulti && !props.disableSelectAll) {
                options.push({ value: '*', label: 'All Price Classes' });
            }
            var filtered = prices
                .filter(function (price) {
                return price.status === 'active';
            })
                .map(function (price) { return ({
                value: price._id,
                label: price.ref,
            }); });
            return __spreadArray(__spreadArray([], options, true), sortBy(filtered, function (price) { return price.label; }), true);
        }
        return [];
    }, [prices, props.isMulti, props.disableSelectAll]);
    return (_jsx(Autocomplete, __assign({ value: value, options: priceOptions, placeholder: "Select customer", onChange: function (selected) {
            return onChange(selected, prices === null || prices === void 0 ? void 0 : prices.find(function (price) { return price._id === selected.value; }));
        }, isActive: value && value.value !== '*' ? true : false, isDisabled: !(priceOptions === null || priceOptions === void 0 ? void 0 : priceOptions.length) }, rest), void 0));
};

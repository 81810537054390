var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, SearchInput, } from 'src/core/components';
import { useAtom, userAtom } from 'src/core/store';
import { FeedsTable, FeedForm } from '../components';
import { PageTabs } from 'src/core/components/PageTabs';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { basicSearch } from '@obtainly/utils';
import { useQueryModel } from '@obtainly/http';
import { useRouter } from 'src/core/hooks';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
export var Feeds = function () {
    var router = useRouter('feeds');
    var user = useAtom(userAtom)[0];
    var _a = useQueryModel('feed.read_by_distributor', { distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id }, { enabled: !!(user === null || user === void 0 ? void 0 : user.distributor_id) }), feeds = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredFeeds = useFilter(feeds || [], filters, {
        search: function (feed) {
            return basicSearch(feed === null || feed === void 0 ? void 0 : feed.title, filters.search) ||
                basicSearch(feed === null || feed === void 0 ? void 0 : feed.description, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length) {
            return (filters === null || filters === void 0 ? void 0 : filters.status) === 'active'
                ? PageTab.Active
                : (filters === null || filters === void 0 ? void 0 : filters.status) === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery(__assign(__assign({}, filters), { status: 'active' }));
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery(__assign(__assign({}, filters), { status: 'inactive' }));
        }
        else if (filters.search) {
            router.switchQuery({ search: filters.search });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Communication' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Communication", border: 0 }, { children: _jsx(Flex, __assign({ gridGap: 2 }, { children: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                    setCreateMode(true);
                                } }, { children: "Add Communication" }), void 0), _jsx(FeedForm, { isOpen: createMode, onSuccess: function () {
                                    refetch();
                                    setCreateMode(false);
                                }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0) }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search feeds", isDisabled: !(feeds === null || feeds === void 0 ? void 0 : feeds.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(FeedsTable, { feeds: filteredFeeds || [], refetchFeeds: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

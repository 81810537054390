var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Badge, Button, Flex } from '@chakra-ui/react';
import { ContentLoader, TableMaker } from 'src/core/components';
import { DistributorLocationForm } from '../components';
export var DistributorLocationsTable = function (props) {
    var _a, _b;
    var _c = React.useState(null), selectedLocation = _c[0], setSelectedLocation = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'active' ? 'success' : 'orange' }, { children: cell.value }), void 0));
                },
            },
            { accessor: 'name', Header: 'Name' },
            { accessor: 'address', Header: 'Address' },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Flex, __assign({ gridGap: 2 }, { children: _jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () { return setSelectedLocation(row.original); } }, { children: "Edit" }), void 0) }), void 0));
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [!((_a = props.distributorLocations) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No locations found", contentUnavailable: true }, void 0)), !!((_b = props.distributorLocations) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { columns: columns, data: props.distributorLocations, tableProps: { isFit: true, mb: 20 } }, void 0)), _jsx(DistributorLocationForm, { action: "update", isOpen: !!selectedLocation, distributorLocation: selectedLocation, onCancel: function () { return setSelectedLocation(null); }, onSuccess: function () { return setSelectedLocation(null); } }, void 0)] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { SimpleGrid, VStack } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import { useMutateModel } from '@obtainly/http';
import { Form, Formik } from 'formik';
import React from 'react';
import { FormGroup, FormikField, FormikRadioGroup } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { configAtom, useAtom, userAtom } from 'src/core/store';
import * as Yup from 'yup';
var merchantFormSchema = Yup.object({
    business_name: Yup.string()
        .required('Customer business name is required.')
        .min(3, 'Customer business name must be at least 3 characters.'),
    firstname: Yup.string()
        .required('Customer first name is required.')
        .min(3, 'Customer first name must be at least 3 characters.'),
    lastname: Yup.string()
        .required('Customer last name is required.')
        .min(3, 'Customer last name must be at least 3 characters.'),
    phone: Yup.string()
        .required('Customer phone number is required.')
        .matches(/^[0][7-9][0-1][0-9]{8}$/, 'Customer phone number must be valid.'),
    email: Yup.string()
        .required('Customer email is required.')
        .email('Customer email must be valid.'),
    address: Yup.string().required('Customer address is required.'),
});
export var MerchantForm = function (props) {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var defaultStoreTypeId = useAtom(configAtom)[0].defaultStoreTypeId;
    var action = props.action, merchant = props.merchant;
    var signupMerchant = useMutateModel('merchant.signup_for');
    var updateMerchant = useMutateModel('merchant.update');
    var initialValues = {
        business_name: (merchant === null || merchant === void 0 ? void 0 : merchant.business_name) || '',
        firstname: (merchant === null || merchant === void 0 ? void 0 : merchant.firstname) || '',
        lastname: (merchant === null || merchant === void 0 ? void 0 : merchant.lastname) || '',
        phone: (merchant === null || merchant === void 0 ? void 0 : merchant.phone) || '',
        email: (merchant === null || merchant === void 0 ? void 0 : merchant.email) || '',
        address: (merchant === null || merchant === void 0 ? void 0 : merchant.address) || '',
        store_type_id: (merchant === null || merchant === void 0 ? void 0 : merchant.store_type_id) || defaultStoreTypeId,
        status: 'active',
    };
    var onSubmit = function (values) {
        if (action === 'signup') {
            signupMerchant.mutate(__assign(__assign({}, values), { distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id, hash: 'football' }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Merchant created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateMerchant.mutate(__assign(__assign({}, values), { _id: merchant === null || merchant === void 0 ? void 0 : merchant._id, user_id: merchant === null || merchant === void 0 ? void 0 : merchant.user_id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Merchant saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: merchantFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'signup' ? 'Add' : 'Update', " Customer"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'active', label: 'Active' },
                                                        { value: 'inactive', label: 'Inactive' },
                                                    ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Business name" }, { children: _jsx(FormikField, { size: "sm", name: "business_name", placeholder: "Acme Inc." }, void 0) }), void 0), _jsxs(SimpleGrid, __assign({ columns: [1, 2], spacingX: "10px" }, { children: [_jsx(FormGroup, __assign({ label: "First name" }, { children: _jsx(FormikField, { size: "sm", name: "firstname", placeholder: "John" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Last name" }, { children: _jsx(FormikField, { size: "sm", name: "lastname", placeholder: "Doe" }, void 0) }), void 0)] }), void 0), _jsx(FormGroup, __assign({ label: "Phone number" }, { children: _jsx(FormikField, { size: "sm", name: "phone", placeholder: "08012345678", isDisabled: action === 'update' }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Email address" }, { children: _jsx(FormikField, { size: "sm", name: "email", placeholder: "john.doe@acme.co" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Address" }, { children: _jsx(FormikField, { as: "textarea", name: "address", placeholder: "29 Coker Road, Yaba Valley" }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: signupMerchant.isLoading || updateMerchant.isLoading, isDisabled: signupMerchant.isLoading ||
                                                updateMerchant.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'signup' ? 'Add' : 'Update', " Customer"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
MerchantForm.defaultProps = {
    action: 'signup',
};

import { Role } from '@obtainly/bootstrap';
import { Products } from './pages/Products';
export var productPermissions = [
    { id: 'view_products', roles: [Role.DistributorStaff] },
    { id: 'create_product', roles: [Role.DistributorStaff] },
    { id: 'update_product', roles: [Role.DistributorStaff] },
];
export var productRoutes = [
    {
        id: 'products',
        path: '/products',
        component: Products,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Flex, Button } from '@chakra-ui/react';
import { FiArrowUpRight, FiPlus } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { useRouter } from 'src/core/hooks';
import { usePermission } from 'src/core/hooks';
import { Card, CardHeader, ContentLoader } from 'src/core/components';
import { OrdersTable } from 'src/order';
export var MerchantOrders = function (props) {
    var navigate = useRouter('orders');
    var userCan = usePermission().userCan;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('order.read_by_merchant_distributor', {
        merchant_id: (props === null || props === void 0 ? void 0 : props.merchantId) || (user === null || user === void 0 ? void 0 : user._id),
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }), orders = _a.data, isLoading = _a.isLoading;
    return (_jsxs(Card, { children: [_jsx(CardHeader, __assign({ heading: "Orders", mb: "10px" }, { children: _jsxs(Flex, __assign({ gridGap: 2, align: "center", justify: "flex-end" }, { children: [userCan('import_orders') && (_jsx(Button, __assign({ size: "sm", variant: "link", display: ['none', 'none'], leftIcon: _jsx(FiArrowUpRight, {}, void 0) }, { children: "Import Orders" }), void 0)), _jsx(Button, __assign({ size: "sm", variant: "link", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                return navigate.go('order', { id: 'new' }, { merchant_id: props === null || props === void 0 ? void 0 : props.merchantId });
                            } }, { children: "New Order" }), void 0)] }), void 0) }), void 0), _jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(_Fragment, { children: _jsx(OrdersTable, { orders: orders || [] }, void 0) }, void 0))] }, void 0));
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ProductForm } from 'src/product';
import { ContentLoader, SearchInput } from 'src/core/components';
import { basicSearch } from '@obtainly/utils';
import { OrderContext } from '../pages/Order';
import { OrderProduct } from './OrderProduct';
export var OrderProducts = function () {
    var _a = React.useContext(OrderContext), products = _a.products, orderItems = _a.orderItems, productsModel = _a.productsModel;
    var _b = React.useState(), selected = _b[0], setSelected = _b[1];
    var _c = React.useState(''), search = _c[0], setSearch = _c[1];
    var filteredProducts = React.useMemo(function () {
        return products && search
            ? products.filter(function (product) {
                return basicSearch(product.name, search);
            })
            : products || [];
    }, [products, search]);
    return (_jsxs(_Fragment, { children: [_jsx(SearchInput, { mb: 4, value: search, onChange: setSearch, placeholder: "Search products", isDisabled: !(products === null || products === void 0 ? void 0 : products.length) }, void 0), _jsx(ContentLoader, { contentUnavailable: !(filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.length), errorSummary: "No products found" }, void 0), (filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.length)
                ? filteredProducts.map(function (product) {
                    var _a;
                    return (_jsx(OrderProduct, { product: product, orderItem: (_a = orderItems === null || orderItems === void 0 ? void 0 : orderItems.find) === null || _a === void 0 ? void 0 : _a.call(orderItems, function (orderItem) {
                            return orderItem.product_id === product._id;
                        }), editProduct: function () { return setSelected(product); } }, product._id));
                })
                : null, _jsx(ProductForm, { action: "update", product: selected, isOpen: !!selected, onCancel: function () { return setSelected(null); }, onSuccess: function () {
                    var _a;
                    (_a = productsModel === null || productsModel === void 0 ? void 0 : productsModel.refetch) === null || _a === void 0 ? void 0 : _a.call(productsModel);
                    setSelected(null);
                } }, void 0)] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useQueryModel } from '@obtainly/http';
import { Card, CardHeader, ContentLoader, ListMaker } from 'src/core/components';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { LedgerLogItem } from 'src/ledger';
import { LedgerLogTag } from '@obtainly/models';
export var MerchantPaymentHistory = function (props) {
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var payload = {
        _id: (props === null || props === void 0 ? void 0 : props.merchantId) || (user === null || user === void 0 ? void 0 : user._id),
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    };
    var _a = useQueryModel('merchant.statement', payload, {
        enabled: !!payload._id,
    }), data = _a.data, isLoading = _a.isLoading;
    var transactions = useMemo(function () {
        var _a, _b, _c;
        return ((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.all) === null || _a === void 0 ? void 0 : _a.list) === null || _b === void 0 ? void 0 : _b.filter(function (log) { return ![LedgerLogTag.Signup].includes(log.tag); })) === null || _c === void 0 ? void 0 : _c.reverse()) || [];
    }, [data]);
    return (_jsxs(Card, { children: [_jsx(CardHeader, __assign({ heading: "Payments" }, { children: " " }), void 0), _jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(transactions === null || transactions === void 0 ? void 0 : transactions.length), errorSummary: "No transactions found" }, void 0), !isLoading && (transactions === null || transactions === void 0 ? void 0 : transactions.length) ? (_jsx(ListMaker, { data: transactions, component: _jsx(LedgerLogItem, {}, void 0) }, void 0)) : null] }, void 0));
};

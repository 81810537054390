var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { everyone, Role } from '@obtainly/bootstrap';
import { Order, Orders, Invoice, OrderConfirmation } from './pages';
export var orderRoutes = [
    {
        id: 'orders',
        path: '/orders',
        component: Orders,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'order',
        path: '/orders/:id',
        component: Order,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'order-confirmation',
        path: '/orders/:id/confirm',
        component: OrderConfirmation,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'invoice',
        path: '/invoice/:id',
        component: Invoice,
        requiresAuth: true,
        exact: true,
    },
];
export var orderPermissions = [
    { id: 'view_orders', roles: [Role.DistributorStaff] },
    { id: 'import_orders', roles: [Role.DistributorStaff] },
    { id: 'begin_order', roles: __spreadArray([], everyone, true) },
    {
        id: 'create_order',
        roles: [Role.Merchant, Role.MerchantOfficer, Role.DistributorStaff],
    },
    { id: 'process_order', roles: [Role.DistributorStaff] },
    { id: 'ship_order', roles: [Role.DistributorStaff] },
    { id: 'complete_order', roles: __spreadArray([], everyone, true) },
    { id: 'cancel_order', roles: __spreadArray([], everyone, true) },
    { id: 'create_pickup_order', roles: [Role.Merchant, Role.MerchantOfficer] },
    { id: 'create_order_item', roles: [] },
    { id: 'update_order_item', roles: __spreadArray([], everyone, true) },
    { id: 'update_order_merchant', roles: [Role.DistributorStaff] },
    { id: 'update_order_tag', roles: __spreadArray([], everyone, true) },
    { id: 'delete_order', roles: [Role.DistributorStaff] },
    { id: 'create_order_note', roles: [Role.DistributorStaff] },
];
export * from './components';

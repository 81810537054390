var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Badge, Flex, Text } from '@chakra-ui/layout';
import { Link } from 'react-router-dom';
import { formatCurrency } from '@obtainly/utils';
import { ContentLoader, TableMaker } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { PriceForm } from './PriceForm';
export var PricesTable = function (props) {
    var _a, _b;
    var router = useRouter();
    var _c = React.useState(), selected = _c[0], setSelected = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'inactive' ? 'critical' : 'success' }, { children: cell.value }), void 0));
                },
                accessor: 'status',
            },
            { Header: 'Reference', accessor: 'ref' },
            { Header: 'Description', accessor: 'description' },
            {
                Header: 'Discount',
                Cell: function (_a) {
                    var cell = _a.cell, row = _a.row;
                    return row.original.is_percentage ? (_jsx(Text, __assign({ color: cell.value > 0 ? 'green.600' : 'red.600' }, { children: cell.value + '%' }), void 0)) : (formatCurrency(cell.value, { showCurrency: true }));
                },
                props: { isNumeric: true },
                accessor: 'discount',
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () {
                                    setSelected(row.original);
                                } }, { children: "Edit" }), void 0), _jsx(Link, __assign({ to: router.getLink('price', { id: row.original._id }) }, { children: _jsx(Button, __assign({ size: "sm", variant: "outline" }, { children: "View" }), void 0) }), void 0)] }), void 0));
                },
            },
        ];
    }, [router]);
    return (_jsxs(_Fragment, { children: [!((_a = props.prices) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No price class found", contentUnavailable: true }, void 0)), !!((_b = props.prices) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { route: { id: 'prices' }, tableProps: { isFit: true, mb: 20 }, columns: columns, data: props.prices }, void 0)), _jsx(PriceForm, { action: "update", price: selected, isOpen: !!selected, onCancel: function () { return setSelected(null); }, onSuccess: function () {
                    props.refetchPrices();
                    setSelected(null);
                } }, void 0)] }, void 0));
};

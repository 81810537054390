var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { Card, CardBody, ContentHeader, Exporter, Layout, MonthYearPicker, } from 'src/core/components';
import { distributorAtom, useAtom } from 'src/core/store';
import { DistributorPayments } from '../components/DistributorPayments';
var exportColumns = [
    {
        label: 'Customer',
        accessor: 'description',
        selected: true,
        modifier: function (value, row) {
            var _a;
            var description = value;
            try {
                var json = JSON.parse(description);
                description = ((_a = json === null || json === void 0 ? void 0 : json.merchant) === null || _a === void 0 ? void 0 : _a.business_name) || '-';
            }
            catch (error) {
                description = '-';
            }
            return row.value < 0 ? 'Obtainly' : description;
        },
    },
    {
        label: 'Payment',
        accessor: 'value',
        selected: true,
        modifier: function (value) { return formatCurrency(value); },
    },
    {
        label: 'Date',
        accessor: 'created',
        selected: true,
        modifier: function (value) { return formatUnixTime(value); },
    },
];
export var PaymentsReport = function () {
    var distributor = useAtom(distributorAtom)[0];
    var _a = React.useState([]), exportableData = _a[0], setExportableData = _a[1];
    var _b = React.useState(Math.floor(new Date().getTime() / 1000.0)), selectedDate = _b[0], setSelectedDate = _b[1];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(selectedDate);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return {
            _id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            month: month,
            year: year,
        };
    }, [distributor === null || distributor === void 0 ? void 0 : distributor._id, selectedDate]);
    return (_jsxs(Layout, __assign({ meta: { title: 'Payments' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Payments", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(MonthYearPicker, { value: selectedDate, onChange: setSelectedDate, isFullWidth: false }, void 0), _jsx(Exporter, { filename: "obtainly-payments-".concat(getUnixTime(new Date())), data: exportableData, columns: exportColumns, isDisabled: !(exportableData === null || exportableData === void 0 ? void 0 : exportableData.length) }, void 0)] }), void 0) }), void 0), _jsx(Card, { children: _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: _jsx(DistributorPayments, { queryData: payload, setExportableData: setExportableData }, void 0) }), void 0) }, void 0)] }), void 0));
};

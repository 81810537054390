var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { VStack } from '@chakra-ui/layout';
import { Card, CardBody, CardHeader, ContentHeader, Layout, } from 'src/core/components';
import { PasswordForm, DistributorProfileForm, DistributorOfficerProfileForm, } from '../components';
export var Settings = function () {
    return (_jsxs(Layout, __assign({ slim: true }, { children: [_jsx(ContentHeader, { heading: "Settings", px: 0, onBack: function () { return ({}); } }, void 0), _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [_jsxs(Card, { children: [_jsx(CardHeader, { heading: "Company Profile" }, void 0), _jsx(CardBody, { children: _jsx(DistributorProfileForm, {}, void 0) }, void 0)] }, void 0), _jsxs(Card, { children: [_jsx(CardHeader, { heading: "Personal Profile" }, void 0), _jsx(CardBody, { children: _jsx(DistributorOfficerProfileForm, {}, void 0) }, void 0)] }, void 0), _jsxs(Card, { children: [_jsx(CardHeader, { heading: "Update Password" }, void 0), _jsx(CardBody, { children: _jsx(PasswordForm, {}, void 0) }, void 0)] }, void 0)] }), void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Card, CardBody, CardHeader, TableMaker } from 'src/core/components';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
export var DistributorSettlementPayments = function (props) {
    var showHeader = props.showHeader, settlements = props.settlements;
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Date',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value, 'd MMM yyy, h:mm a');
                },
                accessor: 'created',
            },
            {
                Header: 'Reference',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell;
                    try {
                        return (_b = JSON.parse(cell.value || '')) === null || _b === void 0 ? void 0 : _b.reference;
                    }
                    catch (error) {
                        return '-';
                    }
                },
                accessor: 'description',
                id: 'reference',
            },
            {
                Header: 'Customer Payment',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value) || '-';
                },
                props: { isNumeric: true },
                accessor: 'value',
                id: 'value',
            },
        ];
    }, []);
    return (_jsx(Card, { children: (settlements === null || settlements === void 0 ? void 0 : settlements.length) ? (_jsxs(_Fragment, { children: [showHeader ? (_jsx(CardHeader, { border: 0, borderRadius: 0, heading: "Settlement Payments", borderTop: "1px solid", borderColor: "gray.100" }, void 0)) : null, _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: _jsx(TableMaker, { data: settlements, columns: columns, tableProps: { isFit: true } }, void 0) }), void 0)] }, void 0)) : null }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { formatUnixTime } from '@obtainly/utils';
import { useFilter } from '@obtainly/hooks';
import { PromotionStatus } from '@obtainly/models';
import { Card, CardBody, ContentHeader, ContentLoader, Exporter, Layout, PageTabs, } from 'src/core/components';
import { useAtom, distributorAtom, atomWithHash } from 'src/core/store';
import { PromotionForm, PromotionsTable, PromotionFilterMenu, } from '../components';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
var pageFilters = atomWithHash('promotions', null);
export var Promotions = function () {
    var _a = useAtom(pageFilters), filters = _a[0], setFilters = _a[1];
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var distributor = useAtom(distributorAtom)[0];
    var _c = useQueryModel('promotion.read_by_distributor', { distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), promotions = _c.data, isLoading = _c.isLoading, refetch = _c.refetch;
    var exportColumns = [
        {
            label: 'Title',
            accessor: 'title',
            selected: true,
        },
        {
            label: 'Description',
            accessor: 'description',
            selected: true,
        },
        {
            label: 'Status',
            accessor: 'status',
            selected: true,
        },
        {
            label: 'Date',
            accessor: 'created',
            modifier: function (value) { return String(formatUnixTime(value)); },
            selected: true,
        },
    ];
    var filteredPromotions = useFilter(promotions || [], filters, {
        start_date: function (promotion) {
            promotion.created >= Number(filters === null || filters === void 0 ? void 0 : filters.start_date);
        },
        end_date: function (promotion) {
            promotion.created <= Number(filters === null || filters === void 0 ? void 0 : filters.end_date);
        },
    });
    var activeTab = React.useMemo(function () {
        return (filters === null || filters === void 0 ? void 0 : filters.status) === PromotionStatus.Active
            ? PageTab.Active
            : (filters === null || filters === void 0 ? void 0 : filters.status) === PromotionStatus.Inactive
                ? PageTab.Inactive
                : PageTab.All;
    }, [filters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            setFilters(function (state) { return (__assign(__assign({}, state), { status: PromotionStatus.Active })); });
        }
        else if (index === PageTab.Inactive) {
            setFilters(function (state) { return (__assign(__assign({}, state), { status: PromotionStatus.Inactive })); });
        }
        else {
            setFilters(function (state) { return (__assign(__assign({}, state), { status: '' })); });
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Promotions' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Promotions", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(PromotionFilterMenu, { filters: filters, setFilters: setFilters }, void 0), _jsx(Exporter, { filename: "obtainly-promotions", columns: exportColumns, data: filteredPromotions }, void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: "New Promotion" }), void 0), _jsx(PromotionForm, { isOpen: createMode, action: "create", onCancel: function () { return setCreateMode(false); }, onSuccess: function () { return refetch(); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: __spreadArray([
                            { label: 'All' }
                        ], Object.keys(PromotionStatus).map(function (key) {
                            return { label: key };
                        }), true) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(PromotionsTable, { promotions: filteredPromotions || [], refetchPromotions: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

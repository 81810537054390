var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { Flex, VStack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import { Form, Formik } from 'formik';
import React from 'react';
import { DateRangeInput, FormGroup, FormikField, FormikRadioGroup, } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { PromotionStatus } from '@obtainly/models';
import { useAtom, distributorAtom } from 'src/core/store';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
var promotionFormSchema = Yup.object({
    title: Yup.string().required('Title status is required.'),
    description: Yup.string().required('Description is required.'),
    status: Yup.string().required('Promotion status is required.'),
    from_timestamp: Yup.string().required('Promotion start date is required.'),
    to_timestamp: Yup.string().required('Promotion end date is required.'),
    distributor_id: Yup.string().required('Distributor is required.'),
});
export var PromotionForm = function (props) {
    var action = props.action, promotion = props.promotion;
    var toast = useToast().toast;
    var distributor = useAtom(distributorAtom)[0];
    var buttonSize = useBreakpointValue(['sm', 'sm']);
    var createPromotion = useMutateModel('promotion.create');
    var updatePromotion = useMutateModel('promotion.update');
    var initialValues = React.useMemo(function () {
        return {
            title: (promotion === null || promotion === void 0 ? void 0 : promotion.title) || '',
            description: (promotion === null || promotion === void 0 ? void 0 : promotion.description) || '',
            status: (promotion === null || promotion === void 0 ? void 0 : promotion.status) || PromotionStatus.Inactive,
            from_timestamp: (promotion === null || promotion === void 0 ? void 0 : promotion.from_timestamp) || 0,
            to_timestamp: (promotion === null || promotion === void 0 ? void 0 : promotion.to_timestamp) || 0,
            distributor_id: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
        };
    }, [promotion, distributor]);
    var onSubmit = function (values, actions) {
        var mutableValues = __assign({}, values);
        if (action === 'create') {
            createPromotion.mutate(__assign({}, mutableValues), {
                onSuccess: function () {
                    props.onSuccess();
                    actions.resetForm();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updatePromotion.mutate(__assign(__assign({}, mutableValues), { _id: promotion === null || promotion === void 0 ? void 0 : promotion._id }), {
                onSuccess: function () {
                    props.onSuccess();
                    actions.resetForm();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: promotionFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        var ActionButtons = function () { return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Button, __assign({ size: buttonSize, variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsx(Button, __assign({ size: buttonSize, type: "submit", colorScheme: "primary", isLoading: createPromotion.isLoading || updatePromotion.isLoading, isDisabled: createPromotion.isLoading ||
                                        updatePromotion.isLoading ||
                                        !formik.dirty ||
                                        !formik.isValid, onClick: function () { return onSubmit(formik.values, formik); } }, { children: action === 'create' ? 'Add' : 'Update' }), void 0)] }), void 0)); };
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(ModalHeader, { children: action === 'create' ? 'New Promotion' : 'Update Promotion' }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, { children: [_jsxs(VStack, __assign({ spacing: 5, align: "stretch", mb: "16px" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: Object.entries(PromotionStatus).map(function (_a) {
                                                            var key = _a[0], value = _a[1];
                                                            return { label: key, value: value };
                                                        }) }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Title" }, { children: _jsx(FormikField, { size: "sm", name: "title", placeholder: "Title", type: "text" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Description" }, { children: _jsx(FormikField, { size: "sm", name: "description", placeholder: "Description", as: "textarea" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Duration" }, { children: _jsx(DateRangeInput, { start: formik.values.from_timestamp, end: formik.values.to_timestamp, onChange: function (start, end) {
                                                            formik.setFieldValue('from_timestamp', start);
                                                            formik.setFieldValue('to_timestamp', end);
                                                        }, allowFutureDate: true }, void 0) }), void 0)] }), void 0), _jsx(ModalFooter, { children: _jsx(ActionButtons, {}, void 0) }, void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text } from '@chakra-ui/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
export var RowExpansionHeader = function (_a) {
    var getToggleAllRowsExpandedProps = _a.getToggleAllRowsExpandedProps, isAllRowsExpanded = _a.isAllRowsExpanded, toggleAllRowsExpanded = _a.toggleAllRowsExpanded, title = _a.title;
    return (_jsxs(Flex, __assign({}, getToggleAllRowsExpandedProps(), { title: isAllRowsExpanded ? 'Collapse All Meta' : 'Expand All Meta', onClick: function () { return toggleAllRowsExpanded(!isAllRowsExpanded); }, align: "center", gridGap: 2, as: "span" }, { children: [_jsx(Text, __assign({ as: "span" }, { children: title !== null && title !== void 0 ? title : 'Extra' }), void 0), isAllRowsExpanded ? _jsx(FiEyeOff, {}, void 0) : _jsx(FiEye, {}, void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { useMutateModel } from '@obtainly/http';
import { capitalizeString, formatCurrency } from '@obtainly/utils';
import { ContentLoader, TableMaker } from 'src/core/components';
import { PriceItemForm } from './PriceItemForm';
import { useToast } from 'src/core/hooks';
import { useQueryClient } from 'react-query';
export var PriceItemsTable = function (props) {
    var price = props.price, priceItems = props.priceItems;
    var _a = useState(null), selectedPriceItem = _a[0], setSelectedPriceItem = _a[1];
    var columns = React.useMemo(function () {
        return [
            {
                Header: 'Product',
                accessor: 'product.name',
                Cell: function (_a) {
                    var cell = _a.cell, row = _a.row;
                    return (_jsxs(Flex, __assign({ align: "center", gridGap: 2 }, { children: [_jsx(Tooltip, __assign({ label: capitalizeString(row.original.product.status), placement: "top", hasArrow: true }, { children: _jsx(Box, __assign({ color: row.original.product.status === 'active'
                                        ? 'success.600'
                                        : 'critical.600', fontSize: "xx-small" }, { children: _jsx(RiCheckboxBlankCircleFill, {}, void 0) }), void 0) }), void 0), _jsx(Text, { children: cell.value }, void 0)] }), void 0));
                },
            },
            {
                Header: 'Value',
                accessor: 'value',
                props: { isNumeric: true },
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value, { showCurrency: true });
                },
            },
            {
                id: 'action',
                Header: 'Action',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(PriceItemActions, { priceItem: row.original, editPriceItem: setSelectedPriceItem }, void 0));
                },
            },
        ];
    }, []);
    return (_jsx(_Fragment, { children: !(priceItems === null || priceItems === void 0 ? void 0 : priceItems.length) ? (_jsx(ContentLoader, { errorSummary: "No product prices found", contentUnavailable: true }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(TableMaker, { tableProps: { isFit: true, mb: 20 }, columns: columns, data: priceItems }, void 0), _jsx(PriceItemForm, { isOpen: !!selectedPriceItem, action: "update", price: price, priceItem: selectedPriceItem, onCancel: function () { return setSelectedPriceItem(null); }, onSuccess: function () { return setSelectedPriceItem(null); } }, void 0)] }, void 0)) }, void 0));
};
var PriceItemActions = function (_a) {
    var priceItem = _a.priceItem, editPriceItem = _a.editPriceItem;
    var toast = useToast().toast;
    var queryClient = useQueryClient();
    var mutatePriceItem = useMutateModel('price_item.delete');
    var isLoading = mutatePriceItem.isLoading;
    var _b = useState(false), deleted = _b[0], setDeleted = _b[1];
    var handleDelete = function () {
        mutatePriceItem.mutate({ _id: priceItem._id }, {
            onSuccess: function () {
                setDeleted(true);
                queryClient
                    .refetchQueries("price_item_read_by_price_".concat(priceItem.price_id))
                    .finally(function () { return setDeleted(false); });
                toast({
                    description: 'Product price deleted',
                    status: 'success',
                });
            },
            onError: function (_a) {
                var message = _a.message;
                setDeleted(false);
                toast({
                    description: message,
                    status: 'error',
                });
            },
        });
    };
    return (_jsxs(Flex, __assign({ align: "center", justify: "end", gridGap: 2 }, { children: [_jsx(Button, __assign({ size: "sm", onClick: function () { return editPriceItem(priceItem); }, disabled: isLoading || deleted }, { children: "Edit" }), void 0), _jsx(Button, __assign({ size: "sm", colorScheme: "critical", onClick: handleDelete, disabled: isLoading || deleted, isLoading: isLoading }, { children: "Delete" }), void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { ContentLoader } from 'src/core/components';
import { usePermission } from 'src/core/hooks';
import { OrderItem } from './OrderItem';
import { OrderItemForm } from './OrderItemForm';
import { OrderContext } from '../pages/Order';
export var OrderItems = function () {
    var userCan = usePermission().userCan;
    var _a = React.useContext(OrderContext), order = _a.order, orderItems = _a.orderItems, refreshOrder = _a.refreshOrder;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    React.useEffect(function () {
        setCreateMode((orderItems === null || orderItems === void 0 ? void 0 : orderItems.length) === 0);
    }, [orderItems]);
    return (_jsxs(_Fragment, { children: [_jsx(ContentLoader, { contentUnavailable: !(orderItems === null || orderItems === void 0 ? void 0 : orderItems.length), errorSummary: !userCan('create_order_item')
                    ? "No items in this order. Go to 'All Products' to add items"
                    : null }, void 0), (orderItems === null || orderItems === void 0 ? void 0 : orderItems.length)
                ? orderItems
                    .slice()
                    .reverse()
                    .map(function (item) { return _jsx(OrderItem, { orderItem: item }, item._id); })
                : null, userCan('create_order_item') && (_jsx(_Fragment, { children: createMode ? (_jsx(Box, __assign({ mt: order && (order === null || order === void 0 ? void 0 : order.amount) > 0 ? 2 : 0, px: "20px", py: "15px", border: "1px solid", borderColor: "gray.100", borderRadius: "8px", boxShadow: "sm" }, { children: _jsx(OrderItemForm, { orderId: order === null || order === void 0 ? void 0 : order._id, action: "create", onSuccess: function () {
                            refreshOrder === null || refreshOrder === void 0 ? void 0 : refreshOrder();
                            setCreateMode(false);
                        }, onCancel: function () { return setCreateMode(false); } }, void 0) }), void 0)) : (_jsx(Button, __assign({ mt: 5, size: "sm", variant: "outline", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () { return setCreateMode(true); } }, { children: "Add Item" }), void 0)) }, void 0))] }, void 0));
};

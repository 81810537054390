import { OrderMetaTag, OrderTag, } from '@obtainly/models';
/**
 * Check if order can be processed based on available balance
 * @param order Type OrderModel
 * @returns 0 | 1
 */
export var processableOrder = function (order) {
    var _a, _b, _c;
    var distributor_id = order.distributor_id;
    var ledger = ((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.merchant) === null || _a === void 0 ? void 0 : _a.ledgers) === null || _b === void 0 ? void 0 : _b["".concat(distributor_id)]) === null || _c === void 0 ? void 0 : _c.balance) || 0;
    return ledger >= order.amount && order.tag === OrderTag.Pending ? 1 : 0;
};
export var getMerchantLocation = function (order) {
    var _a, _b;
    return (_b = (_a = order === null || order === void 0 ? void 0 : order.meta_obj) === null || _a === void 0 ? void 0 : _a[OrderMetaTag.MerchantLocation]) === null || _b === void 0 ? void 0 : _b.merchant_location;
};
export var getOrderAddress = function (order) {
    var _a;
    return (_a = getMerchantLocation(order)) === null || _a === void 0 ? void 0 : _a.address;
};

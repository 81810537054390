var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Badge, Flex } from '@chakra-ui/layout';
import { Link } from 'react-router-dom';
import { formatUnixTime } from '@obtainly/utils';
import { useRouter } from 'src/core/hooks';
import { ContentLoader, TableMaker } from 'src/core/components';
import { ComplaintActionButton } from './ComplaintActionButton';
export var ComplaintsTable = function (props) {
    var _a, _b;
    var router = useRouter();
    var columns = React.useMemo(function () {
        var columnData = [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatUnixTime(cell.value);
                },
            },
            {
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'closed' ? 'success' : 'critical' }, { children: cell.value }), void 0));
                },
                accessor: 'status',
            },
            {
                Header: 'Tag',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: ['shortages', 'damages'].includes(cell.value)
                            ? 'critical'
                            : 'gray' }, { children: cell.value }), void 0));
                },
                accessor: 'tag',
            },
            {
                Header: 'Customer',
                accessor: 'merchant.business_name',
            },
            { Header: 'Ref.', accessor: 'ref' },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Link, __assign({ to: router.getLink('complaint', { id: row.original._id }) }, { children: _jsx(Button, __assign({ size: "sm", variant: "outline" }, { children: "View" }), void 0) }), void 0), _jsx(ComplaintActionButton, { complaint: row.original, onUpdate: props.refetchComplaints }, void 0)] }), void 0));
                },
            },
        ];
        return columnData;
    }, [props.refetchComplaints, router]);
    return (_jsxs(_Fragment, { children: [!((_a = props.complaints) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No complaints found", contentUnavailable: true }, void 0)), !!((_b = props.complaints) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { route: { id: 'complaints' }, tableProps: { isFit: true, mb: 20 }, columns: columns, data: props.complaints }, void 0))] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Badge, Button, Code, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useRouter } from 'src/core/hooks';
import { lang } from 'src/core/config';
import { ContentLoader, TableMaker } from 'src/core/components';
import { DistributorOfficerForm } from '../components';
export var DistributorOfficersTable = function (props) {
    var _a, _b;
    var router = useRouter();
    var _c = React.useState(null), selectedOfficer = _c[0], setSelectedOfficer = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'active' ? 'orange' : 'critical' }, { children: cell.value }), void 0));
                },
            },
            { accessor: 'firstname', Header: 'First Name' },
            { accessor: 'lastname', Header: 'Last Name' },
            { accessor: 'email', Header: 'Email' },
            {
                accessor: 'role',
                Header: 'Role',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return _jsx(Code, { children: cell.value }, void 0);
                },
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () { return setSelectedOfficer(row.original); } }, { children: "Edit" }), void 0), _jsx(Link, __assign({ to: router.getLink('distributor-officer', {
                                    id: row.original._id,
                                }) }, { children: _jsx(Button, __assign({ size: "sm", variant: "outline", disabled: true }, { children: "View" }), void 0) }), void 0)] }), void 0));
                },
            },
        ];
    }, [router]);
    return (_jsxs(_Fragment, { children: [!((_a = props.distributorOfficers) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No officers found", contentUnavailable: true }, void 0)), !!((_b = props.distributorOfficers) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { columns: columns, data: props.distributorOfficers, tableProps: { isFit: true, mb: 20 } }, void 0)), _jsx(DistributorOfficerForm, { label: lang.salesOfficer, action: "update", isOpen: !!selectedOfficer, distributorOfficer: selectedOfficer, onCancel: function () { return setSelectedOfficer(null); }, onSuccess: function () { return setSelectedOfficer(null); } }, void 0)] }, void 0));
};

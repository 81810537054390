var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Flex, Grid, GridItem, SimpleGrid, Text } from '@chakra-ui/layout';
import { Card, CardBody, CardHeader, ImageBox } from 'src/core/components';
import { ComplaintMetaTag, ComplaintTag, } from '@obtainly/models';
import { distributorAtom, useAtom } from 'src/core/store';
import pluralize from 'pluralize';
import { useQueryModel } from '@obtainly/http';
export var ComplaintMetaDetails = function (_a) {
    var complaintTag = _a.complaintTag, complaintMetas = _a.complaintMetas;
    var distributor = useAtom(distributorAtom)[0];
    var products = useQueryModel('product.read_by_distributor', {
        distributor_id: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
    }).data;
    var productsDict = React.useMemo(function () {
        var dict = {};
        if (products === null || products === void 0 ? void 0 : products.length) {
            products.forEach(function (product) {
                dict["".concat(product._id)] = product;
            });
        }
        return dict;
    }, [products]);
    var note = React.useMemo(function () {
        var _meta = complaintMetas === null || complaintMetas === void 0 ? void 0 : complaintMetas.find(function (complaintMeta) { return complaintMeta.tag === ComplaintMetaTag.Note; });
        if (_meta && complaintTag === ComplaintTag.General) {
            return _meta.value;
        }
        else {
            return '';
        }
    }, [complaintTag, complaintMetas]);
    var renderQuantity = function (value) {
        var _a;
        var quantity = (_a = JSON.parse(value || '{}')) === null || _a === void 0 ? void 0 : _a.quantity;
        if (quantity) {
            return quantity + ' ' + pluralize('case', quantity);
        }
        else {
            return '-';
        }
    };
    return (_jsxs(Card, { children: [_jsx(CardHeader, { heading: "".concat(complaintTag === ComplaintTag.General ? 'Note' : 'Products'), textTransform: "capitalize", border: 0 }, void 0), _jsx(CardBody, __assign({ pt: 2 }, { children: _jsx(SimpleGrid, __assign({ columns: 1, mt: 3, spacing: 3, alignItems: "stretch" }, { children: note ? (_jsx(Text, { children: note }, void 0)) : (complaintMetas === null || complaintMetas === void 0 ? void 0 : complaintMetas.map(function (item, index) {
                        var _a;
                        return (_jsx(Box, __assign({ mt: 2, p: "6px", boxShadow: "sm", borderRadius: "8px", border: "1px solid", bg: "gray.50", borderColor: "gray.100", transition: "opacity 300ms" }, { children: _jsxs(Grid, __assign({ templateRows: "1fr", templateColumns: ['28px 7fr 3fr'], gridRowGap: [4, 0], gridColumnGap: 2, alignItems: "center" }, { children: [_jsx(GridItem, __assign({ rowSpan: [2, 1] }, { children: _jsx(Flex, __assign({ justifyContent: "center", alignItems: "center" }, { children: _jsx(ImageBox, { boxSize: "28px" }, void 0) }), void 0) }), void 0), _jsx(GridItem, { children: ((_a = productsDict[item.value]) === null || _a === void 0 ? void 0 : _a.name) || '-' }, void 0), _jsx(GridItem, __assign({ textAlign: "end", fontWeight: "semibold", pr: "10px" }, { children: renderQuantity(item.meta) }), void 0)] }), void 0) }), index));
                    })) }), void 0) }), void 0)] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Link } from 'react-router-dom';
import { useRouter } from 'src/core/hooks';
import { formatCurrency } from '@obtainly/utils';
import { ContentLoader, TableMaker } from 'src/core/components';
import { TargetForm } from './TargetForm';
export var TargetsTable = function (props) {
    var _a, _b;
    var router = useRouter();
    var _c = React.useState(), selected = _c[0], setSelected = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Customer',
                accessor: 'merchant.business_name',
                Cell: function (_a) {
                    var cell = _a.cell, row = _a.row;
                    return (_jsx(Link, __assign({ to: router.getLink('merchant', { id: row.original.merchant._id }) }, { children: cell.value }), void 0));
                },
            },
            {
                Header: 'Target',
                accessor: 'value',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
            },
            { Header: 'Month', accessor: 'month', props: { isNumeric: true } },
            { Header: 'Year', accessor: 'year', props: { isNumeric: true } },
            { Header: 'Tag', accessor: 'tag' },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () {
                            setSelected(row.original);
                        } }, { children: "Edit" }), void 0));
                },
            },
        ];
    }, [router]);
    return (_jsxs(_Fragment, { children: [!((_a = props.targets) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No targets found", contentUnavailable: true }, void 0)), !!((_b = props.targets) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { route: { id: 'targets' }, tableProps: { isFit: true, mb: 20 }, columns: columns, data: props.targets }, void 0)), _jsx(TargetForm, { action: "update", target: selected, isOpen: !!selected, onCancel: function () { return setSelected(null); }, onSuccess: function () {
                    props.refetchTargets();
                    setSelected(null);
                } }, void 0)] }, void 0));
};

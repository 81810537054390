var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Text } from '@chakra-ui/layout';
import { SimpleGrid } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField, FormGroup } from '@obtainly/ui/web-shared/FormField';
import { Role } from '@obtainly/bootstrap';
import { useToast } from 'src/core/hooks';
import { setSessionToken, useMutateModel } from '@obtainly/http';
import { useAtom, authAtom } from 'src/core/store';
import { PasswordValidator } from '@obtainly/ui/web-shared';
var signupFormSchema = Yup.object({
    email: Yup.string()
        .required('Email address is required.')
        .email('Email must be valid.'),
    firstname: Yup.string().required('First name is required.'),
    lastname: Yup.string().required('Last name is required.'),
    hash: Yup.string()
        .nullable()
        .min(8)
        .matches(/[a-z]/, 'Password must contain one lowercase letter (a-z)')
        .matches(/[A-Z]/, 'Password must contain one uppercase letter (A-Z)')
        .matches(/[0-9]/, 'Password must contain one number (0-9)')
        .label('Password'),
    hash_confirm: Yup.string()
        .required('Re-type password for confirmation')
        .oneOf([Yup.ref('hash'), null], 'Passwords do not match'),
    distributor_name: Yup.string().required('Company name is required.'),
    distributor_address: Yup.string().required('Company address is required.'),
});
export var SignupForm = function () {
    var toast = useToast().toast;
    var _a = useAtom(authAtom), setAuth = _a[1];
    var doSignup = useMutateModel('distributor_officer.signup_new');
    var initialValues = {
        email: '',
        firstname: '',
        lastname: '',
        role: Role.DistributorStaff,
        status: 'active',
        hash: '',
        hash_confirm: '',
        distributor_name: '',
        distributor_address: '',
        distributor_status: 'active',
    };
    var onSubmit = function (values, _a) {
        var resetForm = _a.resetForm;
        delete values.hash_confirm;
        doSignup.mutate(values, {
            onSuccess: function (data) {
                var token = data.token;
                setSessionToken(token);
                localStorage.setItem('token', JSON.stringify(token));
                setAuth(function (state) { return (__assign(__assign({}, state), { token: token })); });
                toast({
                    status: 'success',
                    description: 'Supplier created successfully',
                });
                resetForm();
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: signupFormSchema, onSubmit: onSubmit, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(FormGroup, __assign({ label: "Company name" }, { children: _jsx(FormikField, { name: "distributor_name", placeholder: "Acme Inc." }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Company address" }, { children: _jsx(FormikField, { name: "distributor_address", placeholder: "29 Coker Road, Yaba Valley" }, void 0) }), void 0), _jsx(Text, __assign({ mt: 4, mb: 2, fontSize: "sm", color: "gray.600", fontWeight: "semibold", textTransform: "uppercase", letterSpacing: "0.6px" }, { children: "Admin Details" }), void 0), _jsxs(SimpleGrid, __assign({ columns: [1, 2], spacing: "10px" }, { children: [_jsx(FormGroup, __assign({ label: "First name" }, { children: _jsx(FormikField, { name: "firstname", placeholder: "John" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Last name " }, { children: _jsx(FormikField, { name: "lastname", placeholder: "Doe" }, void 0) }), void 0)] }), void 0), _jsx(FormGroup, __assign({ label: "Email" }, { children: _jsx(FormikField, { name: "email", placeholder: "you@acme.com" }, void 0) }), void 0), _jsxs(FormGroup, __assign({ label: "Password" }, { children: [_jsx(FormikField, { type: "password", name: "hash", placeholder: "Type password" }, void 0), formik.errors.hash && formik.touched.hash ? (_jsx(PasswordValidator, { password: formik.values.hash, validations: {
                                    length: true,
                                    uppercaseCharacter: true,
                                    lowercaseCharacter: true,
                                    number: true,
                                } }, void 0)) : null] }), void 0), _jsx(FormGroup, __assign({ label: "Confirm Password" }, { children: _jsx(FormikField, { type: "password", name: "hash_confirm", placeholder: "Re-type password" }, void 0) }), void 0), _jsx(Button, __assign({ mt: 2, type: "submit", colorScheme: "primary", isDisabled: doSignup.isLoading || !formik.isValid, isLoading: doSignup.isLoading, loadingText: "Creating your account", isFullWidth: true }, { children: "Continue" }), void 0)] }), void 0)); } }), void 0) }, void 0));
};

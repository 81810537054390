var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormGroup, FormikField, FormikRadioGroup } from 'src/core/components';
import { VStack } from '@chakra-ui/layout';
import { useToast } from 'src/core/hooks';
import { useMutateModel } from '@obtainly/http';
import { Role } from '@obtainly/bootstrap';
var formSchema = Yup.object({
    firstname: Yup.string()
        .required('Customer first name is required.')
        .min(3, 'Customer first name must be at least 3 characters.'),
    lastname: Yup.string()
        .required('Customer last name is required.')
        .min(3, 'Customer last name must be at least 3 characters.'),
    phone: Yup.string()
        .required('Customer phone number is required.')
        .matches(/^[0][7-9][0-1][0-9]{8}$/, 'Customer phone number must be valid.'),
    email: Yup.string()
        .required('Customer email is required.')
        .email('Customer email must be valid.'),
});
export var MerchantOfficerForm = function (props) {
    var toast = useToast().toast;
    var action = props.action, merchantOfficer = props.merchantOfficer, merchantId = props.merchantId, title = props.title;
    var createMerchant = useMutateModel('merchant_officer.signup_for');
    var updateMerchant = useMutateModel('merchant_officer.update');
    var initialValues = {
        firstname: (merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.firstname) || '',
        lastname: (merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.lastname) || '',
        phone: (merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.phone) || '',
        email: (merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.email) || '',
        role: (merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.role) || Role.MerchantOfficer,
        status: (merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.status) || 'active',
    };
    var onSubmit = function (values) {
        var mutableValues = __assign({}, values);
        if (action === 'create') {
            mutableValues.merchant_id = merchantId;
            mutableValues.hash = 'password';
            createMerchant.mutate(__assign({}, mutableValues), {
                onSuccess: function () {
                    var _a;
                    toast({
                        status: 'success',
                        description: title + ' added.',
                    });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateMerchant.mutate(__assign(__assign({}, mutableValues), { _id: merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer._id, merchant_id: merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.merchant_id, user_id: merchantOfficer === null || merchantOfficer === void 0 ? void 0 : merchantOfficer.user_id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: title + ' saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: formSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " ", title] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'active', label: 'Active' },
                                                        { value: 'inactive', label: 'Inactive' },
                                                    ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "First name" }, { children: _jsx(FormikField, { name: "firstname", placeholder: "First name" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Last name" }, { children: _jsx(FormikField, { name: "lastname", placeholder: "Last name" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Phone" }, { children: _jsx(FormikField, { name: "phone", placeholder: "Phone number", isDisabled: action === 'update' }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Email" }, { children: _jsx(FormikField, { name: "email", placeholder: "Email", isDisabled: action === 'update' }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createMerchant.isLoading || updateMerchant.isLoading, isDisabled: createMerchant.isLoading ||
                                                updateMerchant.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " ", title] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
MerchantOfficerForm.defaultProps = {
    action: 'create',
    title: 'Staff',
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Box, SimpleGrid } from '@chakra-ui/layout';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField, FormGroup } from 'src/core/components';
import { VStack } from '@chakra-ui/layout';
import { useToast } from 'src/core/hooks';
import { useAtom, userAtom } from 'src/core/store';
import { MerchantAutocomplete } from 'src/merchant';
import { useMutateModel } from '@obtainly/http';
var targetFormSchema = Yup.object({
    day: Yup.string().required('Day is required.'),
    month: Yup.number()
        .min(1, 'Month must not be less than 1')
        .max(12, 'Month must not be more than 12')
        .required('Month is required.'),
    year: Yup.number()
        .min(2020, 'Year starts from 2020')
        .test('is-number', 'Date must be a number', function (value) {
        return !!String(value).match('[0-9]');
    })
        .required('Year is required.'),
    value: Yup.string().required('Value field is required.'),
    tag: Yup.string().required('Tag field is required'),
    merchant_id: Yup.string().required('Customer field is required'),
    distributor_id: Yup.string().required('Supplier field is required'),
});
export var TargetForm = function (props) {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var action = props.action, target = props.target;
    var createTarget = useMutateModel('target.create');
    var updateTarget = useMutateModel('target.update');
    var initialValues = React.useMemo(function () { return ({
        distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id,
        merchant_id: (target === null || target === void 0 ? void 0 : target.merchant_id) || '',
        year: (target === null || target === void 0 ? void 0 : target.year) || String(new Date().getFullYear()),
        month: (target === null || target === void 0 ? void 0 : target.month) || String(new Date().getMonth() + 1),
        day: (target === null || target === void 0 ? void 0 : target.day) || '0',
        tag: (target === null || target === void 0 ? void 0 : target.tag) || 'sales',
        value: (target === null || target === void 0 ? void 0 : target.value) || '0',
    }); }, [user, target]);
    var onSubmit = function (values) {
        if (action === 'create') {
            createTarget.mutate(values, {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Target created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateTarget.mutate(__assign(__assign({}, values), { _id: target === null || target === void 0 ? void 0 : target._id }), {
                onSuccess: function () {
                    var _a;
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                    toast({ status: 'success', description: 'Target saved' });
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: targetFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Target"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, { children: [action === 'update' && _jsx(Box, { mb: 4 }, void 0), _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [_jsx(FormGroup, __assign({ label: "Customer" }, { children: _jsx(MerchantAutocomplete, { size: "sm", value: formik.values.merchant_id, onChange: function (_a) {
                                                            var value = _a.value;
                                                            formik.setFieldValue('merchant_id', value);
                                                        }, disableSelectAll: true }, void 0) }), void 0), _jsxs(SimpleGrid, __assign({ columns: [1, 2], spacingX: "10px" }, { children: [_jsx(FormGroup, __assign({ label: "Month" }, { children: _jsx(FormikField, { size: "sm", name: "month", placeholder: "ex. 2 for February" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Year" }, { children: _jsx(FormikField, { size: "sm", name: "year", placeholder: "ex. 2021", type: "number" }, void 0) }), void 0)] }), void 0), _jsx(SimpleGrid, __assign({ columns: [1, 2], spacingX: "10px" }, { children: _jsx(FormGroup, __assign({ label: "Value" }, { children: _jsx(FormikField, { size: "sm", name: "value", type: "number" }, void 0) }), void 0) }), void 0)] }), void 0)] }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createTarget.isLoading || updateTarget.isLoading, isDisabled: createTarget.isLoading ||
                                                updateTarget.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Target"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
TargetForm.defaultProps = {
    action: 'create',
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { VStack } from '@chakra-ui/layout';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from 'react-query';
import { useMutateModel } from '@obtainly/http';
import { useToast } from 'src/core/hooks';
import { FormikField, FormGroup } from 'src/core/components';
import { ProductAutocomplete } from 'src/product/components/ProductAutocomplete';
var priceItemFormSchema = Yup.object({
    value: Yup.number().min(0).required('Product price value is required'),
    product_id: Yup.string().required('Product is required'),
});
export var PriceItemForm = function (props) {
    var toast = useToast().toast;
    var queryClient = useQueryClient();
    var action = props.action, price = props.price, priceItem = props.priceItem;
    var createPriceItem = useMutateModel('price_item.create');
    var updatePriceItem = useMutateModel('price_item.update');
    var initialValues = {
        value: (priceItem === null || priceItem === void 0 ? void 0 : priceItem.value) || 0,
        product_id: (priceItem === null || priceItem === void 0 ? void 0 : priceItem.product_id) || '',
        price_id: (priceItem === null || priceItem === void 0 ? void 0 : priceItem.price_id) || price._id || '',
    };
    var callbacks = {
        onSuccess: function () {
            var _a;
            queryClient.refetchQueries("price_item_read_by_price_".concat(price._id));
            toast({
                status: 'success',
                description: "Price ".concat(action === 'create' ? 'created' : 'updated'),
            });
            (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
        },
        onError: function (_a) {
            var message = _a.message;
            toast({ status: 'error', description: message });
        },
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createPriceItem.mutate(values, callbacks);
        }
        else {
            updatePriceItem.mutate(__assign(__assign({}, values), { _id: priceItem === null || priceItem === void 0 ? void 0 : priceItem._id }), callbacks);
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: priceItemFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var values = _a.values, formik = __rest(_a, ["values"]);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Product Price"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [_jsx(FormGroup, __assign({ label: "Product" }, { children: _jsx(ProductAutocomplete, { name: "product_id", value: values.product_id, onChange: function (_, product) {
                                                        return formik.setFieldValue('product_id', product === null || product === void 0 ? void 0 : product._id);
                                                    }, showInactive: true }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Price" }, { children: _jsx(FormikField, { size: "sm", name: "value", placeholder: "Product price." }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createPriceItem.isLoading || updatePriceItem.isLoading, isDisabled: createPriceItem.isLoading ||
                                                updatePriceItem.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Product Price"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
PriceItemForm.defaultProps = {
    action: 'create',
};

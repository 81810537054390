var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Text, VStack } from '@chakra-ui/layout';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { FormikField, FormGroup, FormikRadioGroup } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { useAtom, userAtom } from 'src/core/store';
import { MerchantAutocomplete } from 'src/merchant';
var creditFormSchema = Yup.object({
    ref: Yup.string().required('Credit reference is required'),
    status: Yup.string().required('Credit status is required'),
    value: Yup.number().required('Credit value is required'),
    meta: Yup.mixed().required('Credit meta is required'),
    merchant_id: Yup.string().required('Customer is required'),
});
export var CreditForm = function (props) {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var action = props.action, credit = props.credit;
    var createCredit = useMutateModel('credit.create');
    var updateCredit = useMutateModel('credit.update');
    var initialValues = {
        ref: (credit === null || credit === void 0 ? void 0 : credit.ref) || '',
        status: (credit === null || credit === void 0 ? void 0 : credit.status) || 'pending',
        value: (credit === null || credit === void 0 ? void 0 : credit.value) || '',
        meta: (credit === null || credit === void 0 ? void 0 : credit.meta) || '-',
        merchant_id: (credit === null || credit === void 0 ? void 0 : credit.merchant_id) || '',
        distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id,
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createCredit.mutate(values, {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Credit created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateCredit.mutate(__assign(__assign({}, values), { _id: credit === null || credit === void 0 ? void 0 : credit._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Credit saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: creditFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var values = _a.values, touched = _a.touched, errors = _a.errors, formik = __rest(_a, ["values", "touched", "errors"]);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Credit Note"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'pending', label: 'Pending' },
                                                        { value: 'applied', label: 'Applied' },
                                                    ] }, void 0) }), void 0)), _jsxs(FormGroup, __assign({ label: "Customer", isRequired: true }, { children: [_jsx(MerchantAutocomplete, { size: "sm", name: "merchant_id", value: values.merchant_id, onBlur: function () {
                                                            formik.setFieldTouched('merchant_id', true);
                                                        }, onChange: function (selected) {
                                                            formik.setFieldValue('merchant_id', selected.value);
                                                        }, isInvalid: touched.merchant_id && errors.merchant_id ? true : false, disableSelectAll: true }, void 0), _jsx(Text, __assign({ fontSize: "sm", color: "red.600", mt: 1 }, { children: _jsx(ErrorMessage, { name: "merchant_id", component: "span" }, void 0) }), void 0)] }), void 0), _jsx(FormGroup, __assign({ label: "Reference" }, { children: _jsx(FormikField, { size: "sm", name: "ref", placeholder: "Ex. CHRISTMAS PROMO 2021" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Value" }, { children: _jsx(FormikField, { size: "sm", type: "number", name: "value", prepend: "NGN" }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createCredit.isLoading || updateCredit.isLoading, isDisabled: createCredit.isLoading ||
                                                updateCredit.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Credit Note"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
CreditForm.defaultProps = {
    action: 'create',
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogContent, AlertDialogOverlay, AlertDialogHeader, } from '@chakra-ui/react';
import { useOrderStateMachine } from '../hooks/useOrderStateMachine';
import { FiChevronDown } from 'react-icons/fi';
export var OrderQuickActions = function (_a) {
    var _b;
    var order = _a.order, refetchOrders = _a.refetchOrders;
    var orderStates = useOrderStateMachine(order, refetchOrders);
    var _c = React.useState(), confirm = _c[0], setConfirm = _c[1];
    var cancelRef = React.useRef(null);
    var onConfirmClose = function () {
        setConfirm(null);
    };
    var onConfirmProceed = function () {
        var _a, _b;
        (_b = (_a = confirm === null || confirm === void 0 ? void 0 : confirm.triggerButtonProps) === null || _a === void 0 ? void 0 : _a.onClick) === null || _b === void 0 ? void 0 : _b.call(_a, confirm.event);
        setConfirm(null);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Menu, __assign({ placement: "bottom-end" }, { children: [_jsx(MenuButton, __assign({ px: 1, cursor: "default", borderRadius: "md", _focus: { bg: 'transparent' }, _expanded: { bg: 'transparent' } }, { children: _jsx(Button, __assign({ size: "xs", variant: "outline" }, { children: _jsxs(Flex, __assign({ alignItems: "center", gridGap: 1 }, { children: [_jsx(Flex, { children: "More" }, void 0), _jsx(FiChevronDown, {}, void 0)] }), void 0) }), void 0) }), void 0), _jsx(MenuList, __assign({ p: 2, mt: 2, gridGap: 2 }, { children: (order === null || order === void 0 ? void 0 : order.tag) && orderStates
                            ? orderStates[order.tag]().nextStates.map(function (state, index) { return (_jsx(OrderQuickAction, { state: state, confirmAction: setConfirm }, index)); })
                            : null }), void 0)] }), void 0), _jsx(AlertDialog, __assign({ isOpen: !!confirm, leastDestructiveRef: cancelRef, onClose: onConfirmClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsxs(AlertDialogHeader, { children: [confirm === null || confirm === void 0 ? void 0 : confirm.title, "?"] }, void 0), _jsx(AlertDialogBody, { children: confirm === null || confirm === void 0 ? void 0 : confirm.question }, void 0), _jsxs(AlertDialogFooter, { children: [_jsx(Button, __assign({ ref: cancelRef, size: "sm", onClick: onConfirmClose }, { children: "No" }), void 0), _jsxs(Button, __assign({ size: "sm", colorScheme: (_b = confirm === null || confirm === void 0 ? void 0 : confirm.triggerButtonProps) === null || _b === void 0 ? void 0 : _b.colorScheme, onClick: onConfirmProceed, ml: 3 }, { children: ["Yes, ", confirm === null || confirm === void 0 ? void 0 : confirm.title] }), void 0)] }, void 0)] }, void 0) }, void 0) }), void 0)] }, void 0));
};
var OrderQuickAction = function (props) {
    var _a, _b;
    var state = props.state, confirmAction = props.confirmAction;
    return (_jsx(_Fragment, { children: (state === null || state === void 0 ? void 0 : state.userCan) && (_jsx(MenuItem, __assign({ onClick: function (event) {
                var _a, _b;
                if (state.triggerConfirmation) {
                    confirmAction({
                        event: event,
                        title: state.title || 'Yes, Proceed',
                        question: state.triggerConfirmation,
                        triggerButtonProps: state.triggerButtonProps,
                    });
                }
                else {
                    (_b = (_a = state.triggerButtonProps) === null || _a === void 0 ? void 0 : _a.onClick) === null || _b === void 0 ? void 0 : _b.call(_a, event);
                }
            }, isDisabled: ((_a = state.triggerButtonProps) === null || _a === void 0 ? void 0 : _a.isLoading) ||
                ((_b = state.triggerButtonProps) === null || _b === void 0 ? void 0 : _b.isDisabled) }, { children: state === null || state === void 0 ? void 0 : state.title }), void 0)) }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, VStack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { PromotionTrigger, } from '@obtainly/models';
import { Form, Formik } from 'formik';
import { FormGroup, FormikField, FormikRadioGroup } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { ProductAutocomplete } from 'src/product/components/ProductAutocomplete';
var promotionProductFormSchema = Yup.object({
    trigger: Yup.string().required('Trigger is required.'),
    trigger_value: Yup.string()
        .when('trigger', {
        // allow decimals only when trigger is price
        is: PromotionTrigger.Price,
        then: Yup.string().matches(/^([0-9]{1,})([.][0-9]*)?$/g, 'Invalid number'),
        otherwise: Yup.string().matches(/^([0-9]{1,})$/g, 'Invalid number'),
    })
        .required('Trigger value is required.'),
    reward: Yup.string()
        .when('trigger', {
        // allow decimals only when trigger is price
        is: PromotionTrigger.Price,
        then: Yup.string().matches(/^([0-9]{1,})([.][0-9]*)?$/g, 'Invalid number'),
        otherwise: Yup.string().matches(/^([0-9]{1,})$/g, 'Invalid number'),
    })
        .required('Reward is required.'),
    promotion_id: Yup.string().required('Promotion is required.'),
    product_id: Yup.string().required('Product is required.'),
    reward_product_id: Yup.string().required('Reward product is required.'),
});
export var PromotionProductForm = function (props) {
    var action = props.action, promotion = props.promotion, promotionProduct = props.promotionProduct;
    var toast = useToast().toast;
    var buttonSize = useBreakpointValue(['sm', 'sm']);
    var createPromotionProduct = useMutateModel('promotion_product.create');
    var updatePromotionProduct = useMutateModel('promotion_product.update');
    var initialValues = React.useMemo(function () {
        return {
            trigger: (promotionProduct === null || promotionProduct === void 0 ? void 0 : promotionProduct.trigger) || '',
            trigger_value: (promotionProduct === null || promotionProduct === void 0 ? void 0 : promotionProduct.trigger_value) || 0,
            reward: (promotionProduct === null || promotionProduct === void 0 ? void 0 : promotionProduct.reward) || 0,
            promotion_id: (promotionProduct === null || promotionProduct === void 0 ? void 0 : promotionProduct.promotion_id) || (promotion === null || promotion === void 0 ? void 0 : promotion._id) || '',
            product_id: (promotionProduct === null || promotionProduct === void 0 ? void 0 : promotionProduct.product_id) || '',
            reward_product_id: (promotionProduct === null || promotionProduct === void 0 ? void 0 : promotionProduct.reward_product_id) || '',
        };
    }, [promotion, promotionProduct]);
    var onSubmit = function (values, actions) {
        var mutableValues = __assign({}, values);
        if (action === 'create') {
            createPromotionProduct.mutate(mutableValues, {
                onSuccess: function () {
                    props.onSuccess();
                    actions.resetForm();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updatePromotionProduct.mutate(__assign(__assign({}, mutableValues), { _id: promotionProduct === null || promotionProduct === void 0 ? void 0 : promotionProduct._id }), {
                onSuccess: function () {
                    props.onSuccess();
                    actions.resetForm();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: promotionProductFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        var ActionButtons = function () { return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Button, __assign({ size: buttonSize, variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsx(Button, __assign({ size: buttonSize, type: "submit", colorScheme: "primary", isLoading: createPromotionProduct.isLoading ||
                                        updatePromotionProduct.isLoading, isDisabled: createPromotionProduct.isLoading ||
                                        updatePromotionProduct.isLoading ||
                                        !formik.dirty ||
                                        !formik.isValid, onClick: function () { return onSubmit(formik.values, formik); } }, { children: action === 'create' ? 'Add' : 'Update' }), void 0)] }), void 0)); };
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(ModalHeader, { children: action === 'create' ? 'New Promotion' : 'Update Promotion' }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, { children: [_jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [_jsx(FormGroup, __assign({ label: "Promo Product" }, { children: _jsx(ProductAutocomplete, { value: formik.values.product_id, onChange: function (data) {
                                                            formik.setFieldValue('product_id', data.value);
                                                        }, disableSelectAll: true }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Promo Reward Product" }, { children: _jsx(ProductAutocomplete, { value: formik.values.reward_product_id, onChange: function (data) {
                                                            formik.setFieldValue('reward_product_id', data.value);
                                                        }, disableSelectAll: true }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Trigger" }, { children: _jsx(FormikRadioGroup, { name: "trigger", options: Object.entries(PromotionTrigger).map(function (_a) {
                                                            var key = _a[0], value = _a[1];
                                                            return { label: key, value: value };
                                                        }) }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Value" }, { children: _jsx(FormikField, { size: "sm", name: "trigger_value", placeholder: "Value", type: "text" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Reward" }, { children: _jsx(FormikField, { size: "sm", name: "reward", placeholder: "Reward", type: "text" }, void 0) }), void 0)] }), void 0), _jsx(ModalFooter, { children: _jsx(ActionButtons, {}, void 0) }, void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { useTheme } from '@chakra-ui/system';
import { FiPlus } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { Card, CardHeader, CardBody, ContentLoader } from 'src/core/components';
import { useAtom, distributorAtom } from 'src/core/store';
import { MerchantOfficerForm } from './MerchantOfficerForm';
import { MerchantOfficersTable } from './MerchantOfficersTable';
export var MerchantOfficers = function (_a) {
    var merchantId = _a.merchantId, title = _a.title;
    var sizes = useTheme().sizes;
    var distributor = useAtom(distributorAtom)[0];
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var _c = useQueryModel('merchant_officer.read_by_merchant', {
        merchant_id: merchantId,
    }, {
        enabled: !!merchantId,
    }), officers = _c.data, isLoading = _c.isLoading, refetch = _c.refetch;
    return (_jsxs(Card, __assign({ position: "sticky", top: sizes.layout.stickyContentTop }, { children: [_jsx(CardHeader, __assign({ heading: title }, { children: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) && (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", variant: "ghost", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () { return setCreateMode(true); } }, { children: "New" }), void 0), _jsx(MerchantOfficerForm, { title: title, merchantId: merchantId, isOpen: createMode, onCancel: function () { return setCreateMode(false); }, onSuccess: function () {
                                refetch();
                                setCreateMode(false);
                            } }, void 0)] }, void 0)) }), void 0), _jsx(CardBody, __assign({ pt: 0 }, { children: isLoading ? (_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: true }, void 0)) : (officers === null || officers === void 0 ? void 0 : officers.length) ? (_jsx(MerchantOfficersTable, { merchantOfficers: officers, refetchMerchantOfficers: refetch }, void 0)) : (_jsx(Text, __assign({ color: "gray.600", py: "20px", align: "center" }, { children: "No officer found" }), void 0)) }), void 0)] }), void 0));
};
MerchantOfficers.defaultProps = {
    title: 'Customer Staff',
};

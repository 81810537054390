var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { basicSearch } from '@obtainly/utils';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, SearchInput, PageTabs, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { useAtom, distributorAtom } from 'src/core/store';
import { LocationsTable } from '../components/LocationsTable';
import { LocationForm } from '../components/LocationForm';
import { LocationsFilterMenu } from '../components/LocationsFilterMenu';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
export var Locations = function () {
    var router = useRouter('locations');
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('location.read_by_distributor', { distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), locations = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredLocations = useFilter(locations || [], filters, {
        search: function (location) {
            return basicSearch(location === null || location === void 0 ? void 0 : location.name, filters.search) ||
                basicSearch(location === null || location === void 0 ? void 0 : location.state, filters.search) ||
                basicSearch(location === null || location === void 0 ? void 0 : location.lga, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'active'
                ? PageTab.Active
                : filters.status === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery({ status: 'active' });
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery({ status: 'inactive' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Locations' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Locations", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(LocationsFilterMenu, { filters: filters }, void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: "Add Location" }), void 0), _jsx(LocationForm, { isOpen: createMode, onSuccess: function () {
                                        refetch();
                                        setCreateMode(false);
                                    }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search customers", isDisabled: !(locations === null || locations === void 0 ? void 0 : locations.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(LocationsTable, { locations: filteredLocations || [], refetchLocations: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

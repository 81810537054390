var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, List, ListItem } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import React from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { navigationItems } from 'src/core/data';
import { usePermission, useRouter } from 'src/core/hooks';
var getCurrentPath = function (link, exact) {
    var _a;
    var path = exact ? link : (_a = link.split('/')) === null || _a === void 0 ? void 0 : _a[1];
    return path;
};
var NavigationSubItem = function (_a) {
    var _b;
    var item = _a.item;
    var router = useRouter();
    var userCan = usePermission().userCan;
    var isActive = React.useMemo(function () {
        var currentPath = getCurrentPath(location === null || location === void 0 ? void 0 : location.pathname, item.exact);
        return currentPath === item.path;
    }, [item]);
    return (_jsx(_Fragment, { children: userCan(item.permission) && (_jsx(_Fragment, { children: ((_b = item === null || item === void 0 ? void 0 : item.children) === null || _b === void 0 ? void 0 : _b.length) ? (_jsx(Menu, __assign({ placement: "right-start" }, { children: function (_a) {
                    var _b;
                    var isOpen = _a.isOpen;
                    return (_jsxs(React.Fragment, { children: [_jsx(MenuButton, __assign({ mt: 1, w: "full", cursor: "default", borderRadius: "md", bgColor: isOpen ? 'gray.100' : undefined, _hover: {
                                    bgColor: 'gray.100',
                                } }, { children: _jsxs(Flex, __assign({ as: MenuItem, alignItems: "center", justify: "space-between", gridGap: 1, fontWeight: "medium" }, { children: [_jsx(Flex, { children: item.name }, void 0), _jsx(FiChevronRight, {}, void 0)] }), void 0) }), void 0), _jsx(MenuList, __assign({ ml: -2, p: 2 }, { children: (_b = item.children) === null || _b === void 0 ? void 0 : _b.map(function (childItem) {
                                    var _a;
                                    return (_jsx(React.Fragment, { children: userCan(childItem.permission) ? (((_a = childItem.children) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(NavigationSubItem, { item: childItem }, void 0)) : (_jsx(MenuItem, __assign({ as: Link, to: function () {
                                                return router.getLink(childItem.route);
                                            }, borderRadius: "4px" }, { children: childItem.name }), void 0))) : null }, childItem.name));
                                }) }), void 0)] }, void 0));
                } }), void 0)) : (_jsx(ListItem, __assign({ color: isActive ? 'primary.600' : 'body' }, { children: _jsx(Link, __assign({ to: function () {
                        return router.getLink(item.route);
                    } }, { children: item.name }), void 0) }), void 0)) }, void 0)) }, void 0));
};
var NavigationItem = function (_a) {
    var _b;
    var item = _a.item;
    var router = useRouter();
    var userCan = usePermission().userCan;
    var isActive = React.useMemo(function () {
        var currentPath = getCurrentPath(location === null || location === void 0 ? void 0 : location.pathname, item.exact);
        return currentPath === item.path;
    }, [item]);
    return (_jsx(_Fragment, { children: userCan(item.permission) && (_jsx(_Fragment, { children: ((_b = item === null || item === void 0 ? void 0 : item.children) === null || _b === void 0 ? void 0 : _b.length) ? (_jsxs(Menu, __assign({ placement: "bottom-end" }, { children: [_jsx(MenuButton, __assign({ px: 1, as: ListItem, cursor: "default", borderRadius: "md", _focus: { bg: 'transparent' }, _expanded: { bg: 'transparent' } }, { children: _jsxs(Flex, __assign({ alignItems: "center", gridGap: 1 }, { children: [_jsx(Flex, { children: item.name }, void 0), _jsx(FiChevronDown, {}, void 0)] }), void 0) }), void 0), _jsx(MenuList, __assign({ p: 2, mt: 2 }, { children: item.children.map(function (childItem) {
                            var _a;
                            return (_jsx(React.Fragment, { children: userCan(childItem.permission) ? (((_a = childItem.children) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(NavigationSubItem, { item: childItem }, void 0)) : (_jsx(MenuItem, __assign({ as: Link, to: function () {
                                        return router.getLink(childItem.route);
                                    }, borderRadius: "4px", isDisabled: childItem.isDisabled }, { children: childItem.name }), void 0))) : null }, childItem.name));
                        }) }), void 0)] }), void 0)) : (_jsx(ListItem, __assign({ color: isActive ? 'primary.600' : 'body' }, { children: _jsx(Link, __assign({ to: function () {
                        return router.getLink(item.route);
                    } }, { children: item.name }), void 0) }), void 0)) }, void 0)) }, void 0));
};
export var Navigation = function () {
    return (_jsx(List, __assign({ gridGap: 4, display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "medium" }, { children: navigationItems.map(function (item) { return (_jsx(NavigationItem, { item: item }, item.name)); }) }), void 0));
};

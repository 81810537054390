var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { VStack } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Autocomplete, FormGroup, Importer, } from 'src/core/components';
import { useAtom, distributorAtom } from 'src/core/store';
export var MerchantTagBulkAssigner = function (_a) {
    var _b, _c, _d;
    var tag = _a.tag, props = __rest(_a, ["tag"]);
    var distributor = useAtom(distributorAtom)[0];
    var _e = React.useState(''), value = _e[0], setValue = _e[1];
    var _f = React.useState(false), uploadMode = _f[0], setUploadMode = _f[1];
    var importColumns = React.useMemo(function () {
        if (value !== '') {
            return [
                { id: 'merchant_id', label: 'Customer ID' },
                { id: 'tag', label: 'Tag', value: tag },
                { id: 'value', label: 'Value', value: value },
                {
                    id: 'distributor_id',
                    label: 'Distributor ID',
                    value: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
                },
            ];
        }
        return [];
    }, [distributor, tag, value]);
    return (_jsxs(_Fragment, { children: [_jsxs(Button, __assign({ size: "sm", variant: "outline", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () { return setUploadMode(true); } }, { children: ["Bulk Assign ", (_b = props === null || props === void 0 ? void 0 : props.label) !== null && _b !== void 0 ? _b : 'Tag'] }), void 0), _jsxs(Modal, __assign({ isOpen: uploadMode, onClose: function () { return setUploadMode(false); } }, { children: [_jsx(ModalOverlay, {}, void 0), _jsxs(ModalContent, { children: [_jsxs(ModalHeader, { children: ["Assign ", (_c = props === null || props === void 0 ? void 0 : props.label) !== null && _c !== void 0 ? _c : 'Tag'] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 3, alignItems: "stretch", minH: "40" }, { children: [_jsx(FormGroup, __assign({ label: (_d = props === null || props === void 0 ? void 0 : props.label) !== null && _d !== void 0 ? _d : 'Choose a tag' }, { children: _jsx(Autocomplete, { size: "sm", value: value, onChange: function (_a) {
                                                    var value = _a.value;
                                                    setValue(value);
                                                }, options: __spreadArray([
                                                    { label: 'Select an option', value: '' }
                                                ], props.options, true) }, void 0) }), void 0), !!value && (_jsx(FormGroup, __assign({ label: "Import customers" }, { children: _jsx(Importer, { label: "Customers", model: "merchant_tag", columns: importColumns, onCancel: props.onCancel, onSuccess: function () {
                                                    var _a;
                                                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                                                    setUploadMode(false);
                                                } }, void 0) }), void 0))] }), void 0) }, void 0)] }, void 0)] }), void 0)] }, void 0));
};

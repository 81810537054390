var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Badge } from '@chakra-ui/layout';
import { ContentLoader, TableMaker } from 'src/core/components';
import { CreditStatus } from '@obtainly/models';
import { formatCurrency } from '@obtainly/utils';
import { CreditForm } from './CreditForm';
export var CreditsTable = function (props) {
    var _a, _b;
    var _c = React.useState(), selected = _c[0], setSelected = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'pending' ? 'warning' : 'success' }, { children: cell.value }), void 0));
                },
                accessor: 'status',
            },
            { Header: 'Reference', accessor: 'ref' },
            { Header: 'Merchant', accessor: 'merchant.business_name' },
            {
                Header: 'Value (NGN)',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
                accessor: 'value',
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Button, __assign({ size: "sm", variant: "outline", isDisabled: row.original.status === CreditStatus.Applied, onClick: function () {
                            setSelected(row.original);
                        } }, { children: "Edit" }), void 0));
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [!((_a = props.credits) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No credit notes found", contentUnavailable: true }, void 0)), !!((_b = props.credits) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { route: { id: 'credits' }, tableProps: { isFit: true, mb: 20 }, columns: columns, data: props.credits }, void 0)), _jsx(CreditForm, { action: "update", credit: selected, isOpen: !!selected, onCancel: function () { return setSelected(null); }, onSuccess: function () {
                    props.refetchCredits();
                    setSelected(null);
                } }, void 0)] }, void 0));
};

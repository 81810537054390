import { Role } from '@obtainly/bootstrap';
import { Targets } from './pages/Targets';
export var targetPermissions = [
    { id: 'view_targets', roles: [Role.DistributorStaff] },
    { id: 'create_target', roles: [Role.DistributorStaff] },
    { id: 'update_target', roles: [Role.DistributorStaff] },
];
export var targetRoutes = [
    {
        id: 'targets',
        path: '/targets',
        component: Targets,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

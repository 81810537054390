var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { OrderMetaTag, OrderTag } from '@obtainly/models';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { useToast } from 'src/core/hooks';
import { useAtom, userAtom } from 'src/core/store';
import { parseOrderMeta } from '@obtainly/utils';
import { analytics, Event } from 'src/core/utils';
export function useOrderUpdateTag(order, refetchOrder) {
    var user = useAtom(userAtom)[0];
    var toast = useToast().toast;
    var _a = React.useState(false), triggerUpdate = _a[0], setTriggerUpdate = _a[1];
    var _b = React.useState(false), notifyChange = _b[0], setNotifyChange = _b[1];
    var _c = React.useState(false), proceed = _c[0], setProceed = _c[1];
    var _d = React.useState(), tag = _d[0], setTag = _d[1];
    var _e = useMutateModel('order.update_tag'), mutate = _e.mutate, isUpdating = _e.isLoading;
    var _f = useQueryModel('order.read', { _id: order === null || order === void 0 ? void 0 : order._id }, {
        enabled: triggerUpdate,
        queryKey: "order_updates_".concat(order === null || order === void 0 ? void 0 : order._id, "_").concat(triggerUpdate),
        cacheTime: 0,
    }), updatedOrders = _f.data, isLoadingOrder = _f.isLoading;
    var updateOrderTag = function (newTag) {
        var _a, _b;
        var meta = (order === null || order === void 0 ? void 0 : order.meta) || '';
        var metaObject = {};
        // trim user object
        var officer = null;
        Object.entries(user || {}).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (['string', 'number'].includes(typeof value)) {
                if (!officer)
                    officer = {};
                officer[key] = value;
            }
        });
        // set officer who placed the order
        if (newTag === OrderTag.Pending && officer) {
            metaObject = __assign(__assign({}, metaObject), (_a = {}, _a[OrderMetaTag.PlacedBy] = officer, _a));
        }
        // watchout for order cancellation and set who cancelled the order
        if (newTag === OrderTag.Cancelled && officer) {
            if (user) {
                metaObject = __assign(__assign({}, metaObject), (_b = {}, _b[OrderMetaTag.CancelledBy] = officer, _b));
            }
        }
        var newMetaString = parseOrderMeta(order, metaObject).metaString;
        meta = newMetaString;
        if (order) {
            mutate({
                _id: order._id || '',
                old_tag: order.tag,
                new_tag: newTag,
                meta: meta,
            }, {
                onSuccess: function () {
                    var isNewOrder = newTag === OrderTag.Pending;
                    analytics.track(isNewOrder ? Event.OrderPlacement : Event.OrderUpdate, { order_id: order === null || order === void 0 ? void 0 : order._id, old_tag: order.tag, new_tag: newTag });
                    refetchOrder();
                    toast({
                        description: isNewOrder
                            ? 'Order has been placed successfully'
                            : 'Order updated',
                        status: 'success',
                    });
                },
                onError: function (_a) {
                    var message = _a.message;
                    refetchOrder();
                    toast({ description: message, status: 'error' });
                },
            });
        }
    };
    var isLoading = isUpdating || isLoadingOrder;
    // Check for order changes
    React.useEffect(function () {
        if (triggerUpdate && (updatedOrders === null || updatedOrders === void 0 ? void 0 : updatedOrders.length)) {
            setTriggerUpdate(false);
            var updatedOrder = updatedOrders[0];
            if ((order === null || order === void 0 ? void 0 : order.modified_ms) !== updatedOrder.modified_ms) {
                setNotifyChange(true);
            }
            else {
                setProceed(true);
            }
        }
    }, [order, updatedOrders, triggerUpdate]);
    // Show toast notification
    React.useEffect(function () {
        if (notifyChange) {
            setNotifyChange(false);
            refetchOrder();
            toast({
                description: 'This order has been edited by another user, please review and try again',
                status: 'error',
                isClosable: true,
                duration: null,
            });
        }
    }, [notifyChange, toast, refetchOrder]);
    // Proceed to update order if there are no changes
    React.useEffect(function () {
        if (proceed) {
            setProceed(false);
            tag && updateOrderTag(tag);
            setTag(null);
        }
    }, [proceed]); // eslint-disable-line
    return {
        updateOrderTag: function (tag) {
            setTag(tag);
            setTriggerUpdate(true);
        },
        isLoading: isLoading,
    };
}

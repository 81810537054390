var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/layout';
import { IconButton } from '@chakra-ui/button';
import { FiEdit } from 'react-icons/fi';
import { formatUnixTime } from '@obtainly/utils';
import { Card, CardBody, CardHeader } from 'src/core/components';
import { PromotionStatusBadge } from './PromotionBadge';
import { PromotionForm } from './PromotionForm';
export var PromotionDetails = function (_a) {
    var promotion = _a.promotion, refetchPromotion = _a.refetchPromotion;
    var _b = React.useState(false), editMode = _b[0], setEditMode = _b[1];
    var toggleEditMode = function () {
        setEditMode(!editMode);
    };
    var details = React.useMemo(function () {
        return [
            {
                label: 'Promotion Type',
                value: _jsx(PromotionStatusBadge, { value: promotion === null || promotion === void 0 ? void 0 : promotion.status }, void 0),
            },
            { label: 'Title', value: promotion === null || promotion === void 0 ? void 0 : promotion.title },
            { label: 'Description', value: promotion === null || promotion === void 0 ? void 0 : promotion.description },
            {
                label: 'Start',
                value: formatUnixTime((promotion === null || promotion === void 0 ? void 0 : promotion.from_timestamp) || 0),
            },
            { label: 'End', value: formatUnixTime((promotion === null || promotion === void 0 ? void 0 : promotion.to_timestamp) || 0) },
            { label: 'Created', value: formatUnixTime((promotion === null || promotion === void 0 ? void 0 : promotion.created) || 0) },
        ];
    }, [promotion]);
    return (_jsxs(Card, { children: [_jsxs(CardHeader, __assign({ heading: "Promotion Details", textTransform: "capitalize", border: 0 }, { children: [_jsx(IconButton, { "aria-label": "Edit", icon: _jsx(FiEdit, {}, void 0), variant: "ghost", colorScheme: "primary", size: "sm", onClick: toggleEditMode }, void 0), _jsx(PromotionForm, { isOpen: editMode, action: "update", promotion: promotion, onCancel: toggleEditMode, onSuccess: function () {
                            refetchPromotion === null || refetchPromotion === void 0 ? void 0 : refetchPromotion();
                            toggleEditMode();
                        } }, void 0)] }), void 0), _jsx(CardBody, __assign({ pt: 2 }, { children: _jsx(SimpleGrid, __assign({ columns: 1, mt: 3, spacing: 3, alignItems: "stretch" }, { children: details.map(function (detail) { return (_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: detail.label }), void 0), typeof detail.value === 'string' ? (_jsx(Text, __assign({ textTransform: "capitalize" }, { children: detail.value }), void 0)) : (detail.value)] }, detail.label)); }) }), void 0) }), void 0)] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var useOrderExpansion = function (orders) {
    var expandedOrders = React.useMemo(function () {
        var expansion = [];
        orders === null || orders === void 0 ? void 0 : orders.forEach(function (order) {
            var _a;
            var meta_obj = order.meta_obj;
            var freeItems = JSON.parse(((_a = meta_obj === null || meta_obj === void 0 ? void 0 : meta_obj.free_items) === null || _a === void 0 ? void 0 : _a.value) || '{}');
            var orderItems = Object.values((order === null || order === void 0 ? void 0 : order.items) || {});
            expansion.push(__assign(__assign({}, order), { order_item_name: '', order_item_quantity: 0, order_item_amount: 0, is_first: true, is_free_item: false, is_order_item: false }));
            orderItems === null || orderItems === void 0 ? void 0 : orderItems.forEach(function (orderItem) {
                var _a, _b, _c;
                expansion.push(__assign(__assign({}, order), { order_item_name: ((_a = orderItem.product) === null || _a === void 0 ? void 0 : _a.name) || '', order_item_quantity: orderItem.quantity, order_item_amount: orderItem.amount, is_first: false, is_free_item: false, is_order_item: true }));
                if (freeItems) {
                    var free_item = freeItems === null || freeItems === void 0 ? void 0 : freeItems["".concat((_b = orderItem.product) === null || _b === void 0 ? void 0 : _b._id)];
                    if (free_item) {
                        expansion.push(__assign(__assign({}, order), { order_item_name: ((_c = free_item === null || free_item === void 0 ? void 0 : free_item.product) === null || _c === void 0 ? void 0 : _c.name) + ' (Promo)' || '', order_item_quantity: free_item === null || free_item === void 0 ? void 0 : free_item.quantity, order_item_amount: free_item === null || free_item === void 0 ? void 0 : free_item.amount, is_first: false, is_free_item: true, is_order_item: true }));
                    }
                }
            });
        });
        return expansion;
    }, [orders]);
    return { orders: expandedOrders };
};

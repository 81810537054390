var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import sortBy from 'lodash/sortBy';
import { useQueryModel } from '@obtainly/http';
import { Autocomplete } from 'src/core/components';
import { useAtom, distributorAtom } from 'src/core/store';
export var DistributorOfficerAutocomplete = function (props) {
    var value = props.value, onChange = props.onChange, role = props.role, rest = __rest(props, ["value", "onChange", "role"]);
    var distributor = useAtom(distributorAtom)[0];
    var distributors = useQueryModel('distributor_officer.read_by_distributor', { distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }).data;
    var distributorOfficerOptions = React.useMemo(function () {
        if (distributors === null || distributors === void 0 ? void 0 : distributors.length) {
            var options = [];
            if (!props.isMulti && !props.disableSelectAll) {
                options.push({ value: '*', label: 'All Officers' });
            }
            var filtered = distributors
                .filter(function (distributorOfficer) {
                return role ? distributorOfficer.role === role : true;
            })
                .map(function (distributorOfficer) { return ({
                value: distributorOfficer._id,
                label: "".concat(distributorOfficer.firstname, " ").concat(distributorOfficer.lastname),
            }); });
            return __spreadArray(__spreadArray([], options, true), sortBy(filtered, function (distributor) { return distributor.label; }), true);
        }
        return [];
    }, [distributors, props.isMulti, props.disableSelectAll, role]);
    return (_jsx(Autocomplete, __assign({ options: distributorOfficerOptions, value: value, placeholder: "Select officer", onChange: function (selected) { return onChange(selected); }, isActive: value && value.value !== '*' ? true : false, isDisabled: !(distributorOfficerOptions === null || distributorOfficerOptions === void 0 ? void 0 : distributorOfficerOptions.length) }, rest), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Select } from '@chakra-ui/react';
import { useRouter } from 'src/core/hooks';
import { FilterMenu } from 'src/core/components';
export var LocationsFilterMenu = function (props) {
    var navigate = useRouter('locations');
    var _a = React.useState(props.filters), filters = _a[0], setFilters = _a[1];
    var activeFilters = Object.keys(props.filters);
    React.useEffect(function () {
        setFilters(props.filters);
    }, [props.filters]);
    return (_jsx(FilterMenu, __assign({ activeCount: activeFilters === null || activeFilters === void 0 ? void 0 : activeFilters.length, refreshButtonProps: {
            onClick: function () { return navigate.switchQuery(filters); },
        } }, { children: _jsx(FilterMenu.Section, __assign({ label: "Status" }, { children: _jsxs(Select, __assign({ size: "sm", value: filters.status || '', onChange: function (_a) {
                    var target = _a.target;
                    return setFilters(__assign(__assign({}, filters), { status: target.value }));
                } }, { children: [_jsx("option", __assign({ value: "" }, { children: "All Locations" }), void 0), _jsx("option", __assign({ value: "active" }, { children: "Active" }), void 0), _jsx("option", __assign({ value: "inactive" }, { children: "Inactive" }), void 0)] }), void 0) }), void 0) }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, VStack } from '@chakra-ui/layout';
import { useHistory, useParams } from 'react-router';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, CardHeader, ContentHeader, ContentLoader, Layout, } from 'src/core/components';
import { PromotionProductsTable, PromotionDetails, PromotionProductForm, PromotionValuesTable, PromotionValueForm, } from '../components';
export var Promotion = function () {
    var history = useHistory();
    var _a = React.useState(false), createProduct = _a[0], setCreateProduct = _a[1];
    var toggleCreateProduct = function () { return setCreateProduct(!createProduct); };
    var _b = React.useState(false), createValue = _b[0], setCreateValue = _b[1];
    var toggleCreateValue = function () { return setCreateValue(!createValue); };
    var promotionId = useParams().id;
    var _c = useQueryModel('promotion.read', { _id: promotionId }, { enabled: !!promotionId }), promotions = _c.data, isLoading = _c.isLoading, refetch = _c.refetch;
    var promotion = React.useMemo(function () {
        return (promotions === null || promotions === void 0 ? void 0 : promotions[0]) || null;
    }, [promotions]);
    var _d = useQueryModel('promotion_product.read_by_promotion', { promotion_id: promotion === null || promotion === void 0 ? void 0 : promotion._id }, { enabled: !!(promotion === null || promotion === void 0 ? void 0 : promotion._id) }), promotionProducts = _d.data, isLoadingPromotionProducts = _d.isLoading, refetchPromotionProducts = _d.refetch;
    var _e = useQueryModel('promotion_value.read_by_promotion', { promotion_id: promotion === null || promotion === void 0 ? void 0 : promotion._id }, { enabled: !!(promotion === null || promotion === void 0 ? void 0 : promotion._id) }), promotionValues = _e.data, isLoadingPromotionValues = _e.isLoading, refetchPromotionValues = _e.refetch;
    return (_jsx(Layout, __assign({ meta: { title: 'Promotions' } }, { children: !promotion ? (_jsx(ContentLoader, { isLoading: isLoading, errorSummary: "Promotion not found" }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(ContentHeader, __assign({ heading: 'Promotion', onBack: function () { return history.goBack(); } }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", onClick: toggleCreateProduct }, { children: "Add Product" }), void 0), _jsx(Button, __assign({ size: "sm", colorScheme: "primary", onClick: toggleCreateValue }, { children: "Add Value" }), void 0), _jsx(PromotionProductForm, { isOpen: createProduct, action: "create", onCancel: toggleCreateProduct, onSuccess: function () {
                                    toggleCreateProduct();
                                    refetchPromotionProducts();
                                }, promotion: promotion }, void 0), _jsx(PromotionValueForm, { isOpen: createValue, action: "create", onCancel: toggleCreateValue, onSuccess: function () {
                                    toggleCreateValue();
                                    refetchPromotionValues();
                                }, promotion: promotion }, void 0)] }), void 0) }), void 0), _jsxs(Grid, __assign({ templateColumns: ['1fr', '1fr', '1fr 2fr'], gridGap: 5 }, { children: [_jsx(Box, { children: _jsx(PromotionDetails, { promotion: promotion, refetchPromotion: refetch }, void 0) }, void 0), _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch", overflowX: "auto" }, { children: [_jsxs(Card, __assign({ position: "sticky" }, { children: [_jsx(CardHeader, { heading: "Promotion Products" }, void 0), _jsxs(CardBody, __assign({ px: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoadingPromotionProducts, contentUnavailable: !(promotionProducts === null || promotionProducts === void 0 ? void 0 : promotionProducts.length), errorSummary: "There are no products for this promotion" }, void 0), (promotionProducts === null || promotionProducts === void 0 ? void 0 : promotionProducts.length) ? (_jsx(PromotionProductsTable, { promotion: promotion, promotionProducts: promotionProducts || [], refetchPromotionProducts: refetchPromotionProducts }, void 0)) : null] }), void 0)] }), void 0), _jsxs(Card, __assign({ position: "sticky" }, { children: [_jsx(CardHeader, { heading: "Promotion Values" }, void 0), _jsxs(CardBody, __assign({ px: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoadingPromotionValues, contentUnavailable: !(promotionValues === null || promotionValues === void 0 ? void 0 : promotionValues.length), errorSummary: "There are no values for this promotion" }, void 0), (promotionValues === null || promotionValues === void 0 ? void 0 : promotionValues.length) ? (_jsx(PromotionValuesTable, { promotion: promotion, promotionValues: promotionValues || [], refetchPromotionValues: refetchPromotionValues }, void 0)) : null] }), void 0)] }), void 0)] }), void 0)] }), void 0)] }, void 0)) }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TabPanel, TabPanels } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useQueryStringParse } from '@obtainly/hooks';
import { lang } from 'src/core/config';
import { useRouter } from 'src/core/hooks';
import { Card, PageTabs } from 'src/core/components';
import { DistributorMerchants } from 'src/distributor';
import { MerchantOrders, MerchantPaymentHistory, MerchantLocations, MerchantLedgerStatement, } from 'src/merchant';
import { MerchantOfficers } from './MerchantOfficers';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["Orders"] = 0] = "Orders";
    PageTab[PageTab["Payments"] = 1] = "Payments";
    PageTab[PageTab["Statement"] = 2] = "Statement";
    PageTab[PageTab["Locations"] = 3] = "Locations";
    PageTab[PageTab["Officers"] = 4] = "Officers";
    PageTab[PageTab["Staff"] = 5] = "Staff";
})(PageTab || (PageTab = {}));
export var MerchantPageContent = function (_a) {
    var merchant = _a.merchant;
    var router = useRouter(merchant._id);
    var filters = useQueryStringParse(['tab']);
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.tab === 'statement'
                ? PageTab.Statement
                : filters.tab === 'payments'
                    ? PageTab.Payments
                    : filters.tab === 'locations'
                        ? PageTab.Locations
                        : filters.tab === 'officers'
                            ? PageTab.Officers
                            : filters.tab === 'staff'
                                ? PageTab.Staff
                                : PageTab.Orders;
        }
        return PageTab.Orders;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Statement) {
            router.switchQuery({ tab: 'statement' });
        }
        else if (index === PageTab.Payments) {
            router.switchQuery({ tab: 'payments' });
        }
        else if (index === PageTab.Locations) {
            router.switchQuery({ tab: 'locations' });
        }
        else if (index === PageTab.Officers) {
            router.switchQuery({ tab: 'officers' });
        }
        else if (index === PageTab.Staff) {
            router.switchQuery({ tab: 'staff' });
        }
        else {
            router.go();
        }
    };
    return (_jsx(Card, { children: _jsx(PageTabs, __assign({ index: activeTab, onChange: handleTabChange, tabs: Object.entries(PageTab).map(function (_a, index) {
                var value = _a[1];
                switch (index) {
                    case PageTab.Officers:
                        return pluralize(lang.salesOfficer, 2);
                    case PageTab.Staff:
                        return 'Customer Staff';
                    default:
                        return value;
                }
            }) }, { children: _jsxs(TabPanels, __assign({ tabIndex: activeTab }, { children: [_jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(MerchantOrders, { merchantId: merchant === null || merchant === void 0 ? void 0 : merchant._id }, void 0) }), void 0), _jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(MerchantPaymentHistory, { merchantId: merchant === null || merchant === void 0 ? void 0 : merchant._id }, void 0) }), void 0), _jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(MerchantLedgerStatement, { merchantId: merchant === null || merchant === void 0 ? void 0 : merchant._id }, void 0) }), void 0), _jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(MerchantLocations, { merchantId: "".concat(merchant === null || merchant === void 0 ? void 0 : merchant._id) }, void 0) }), void 0), _jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(DistributorMerchants, { merchantId: "".concat(merchant === null || merchant === void 0 ? void 0 : merchant._id), title: pluralize(lang.salesOfficer) }, void 0) }), void 0), _jsx(TabPanel, __assign({ p: 0 }, { children: _jsx(MerchantOfficers, { merchantId: "".concat(merchant === null || merchant === void 0 ? void 0 : merchant._id) }, void 0) }), void 0)] }), void 0) }), void 0) }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, SearchInput, } from 'src/core/components';
import { Importer, Exporter, } from 'src/core/components';
import { useProductReadBy } from '@obtainly/models';
import { useAtom, userAtom, distributorAtom, configAtom } from 'src/core/store';
import { ProductsTable, ProductForm } from '../components';
import { PageTabs } from 'src/core/components/PageTabs';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { basicSearch, formatCurrency } from '@obtainly/utils';
import { useRouter } from 'src/core/hooks';
var exportColumns = [
    { label: 'ID', accessor: '_id', selected: false },
    { label: 'Status', accessor: 'status', selected: true },
    { label: 'SKU ID', accessor: 'sku_id', selected: true },
    { label: 'Product', accessor: 'name', selected: true },
    { label: 'Case Count', accessor: 'case_count', selected: true },
    { label: 'MPU', accessor: 'mpu', selected: true },
    {
        label: 'Price',
        accessor: 'price',
        selected: true,
        modifier: formatCurrency,
    },
    { label: 'Weight', accessor: 'weight', selected: true },
    { label: 'Size', accessor: 'size', selected: true },
];
var importColumns = function (distributorId, uploadId) {
    return [
        { id: 'sku_id', label: 'SKU ID' },
        { id: 'name', label: 'Name' },
        { id: 'price', label: 'Price' },
        { id: 'case_count', label: 'Case Count' },
        { id: 'mpu', label: 'MPU' },
        { id: 'weight', label: 'Weight' },
        { id: 'size', label: 'Size' },
        { id: 'upload_id', label: 'Upload ID', value: uploadId },
        { id: 'distributor_id', label: 'Distributor ID', value: distributorId },
        { id: 'status', label: 'Status', value: 'active' },
    ];
};
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
export var Products = function () {
    var router = useRouter('products');
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var defaultUploadId = useAtom(configAtom)[0].defaultUploadId;
    var _a = useProductReadBy({ distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id }), products = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredProducts = useFilter(products || [], filters, {
        search: function (product) {
            return basicSearch(product === null || product === void 0 ? void 0 : product.name, filters.search) ||
                basicSearch(product === null || product === void 0 ? void 0 : product.sku_id, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length) {
            return (filters === null || filters === void 0 ? void 0 : filters.status) === 'active'
                ? PageTab.Active
                : (filters === null || filters === void 0 ? void 0 : filters.status) === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery(__assign(__assign({}, filters), { status: 'active' }));
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery(__assign(__assign({}, filters), { status: 'inactive' }));
        }
        else if (filters.search) {
            router.switchQuery({ search: filters.search });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Products' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Products", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Exporter, { filename: "obtainly-products", data: filteredProducts, columns: exportColumns, isDisabled: isLoading || !(filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.length) }, void 0), _jsx(Importer, { model: "product", label: "Product", columns: (distributor === null || distributor === void 0 ? void 0 : distributor._id)
                                ? importColumns(distributor._id, defaultUploadId)
                                : [], onSuccess: function () { return refetch(); } }, void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: "Add Product" }), void 0), _jsx(ProductForm, { isOpen: createMode, onSuccess: function () {
                                        refetch();
                                        setCreateMode(false);
                                    }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search products", isDisabled: !(products === null || products === void 0 ? void 0 : products.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(ProductsTable, { products: filteredProducts || [], refetchProducts: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

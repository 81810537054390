var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { SimpleGrid, VStack } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import { Role } from '@obtainly/bootstrap';
import { useMutateModel } from '@obtainly/http';
import { Form, Formik } from 'formik';
import React from 'react';
import { useQueryClient } from 'react-query';
import { FormGroup, FormikField, FormikRadioGroup } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { useAtom, userAtom } from 'src/core/store';
import * as Yup from 'yup';
var distributorOfficerFormSchema = Yup.object({
    firstname: Yup.string()
        .required('Customer first name is required.')
        .min(3, 'Customer first name must be at least 3 characters.'),
    lastname: Yup.string()
        .required('Customer last name is required.')
        .min(3, 'Customer last name must be at least 3 characters.'),
    email: Yup.string()
        .required('Customer email is required.')
        .email('Customer email must be valid.'),
    role: Yup.string().required('Role is required.'),
    distributor_id: Yup.string().required('Distributor Id is required.'),
});
export var DistributorOfficerForm = function (props) {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var queryClient = useQueryClient();
    var action = props.action, distributorOfficer = props.distributorOfficer;
    var signupDistributorOfficer = useMutateModel('distributor_officer.signup');
    var updateDistributorOfficer = useMutateModel('distributor_officer.update');
    var initialValues = {
        firstname: (distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer.firstname) || '',
        lastname: (distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer.lastname) || '',
        email: (distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer.email) || '',
        role: (distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer.role) || Role.SalesOfficer,
        distributor_id: (distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer.distributor_id) || (user === null || user === void 0 ? void 0 : user.distributor_id) || '',
        status: (distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer.status) || 'active',
    };
    var refreshData = function () {
        var _a, _b;
        (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
        queryClient.refetchQueries("distributor_officer_read_by_distributor_".concat((_b = user === null || user === void 0 ? void 0 : user.distributor) === null || _b === void 0 ? void 0 : _b._id));
    };
    var onSubmit = function (values) {
        if (action === 'signup') {
            signupDistributorOfficer.mutate(__assign(__assign({}, values), { distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id, hash: 'football' }), {
                onSuccess: function () {
                    toast({
                        status: 'success',
                        description: 'Officer created',
                    });
                    refreshData();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateDistributorOfficer.mutate(__assign(__assign({}, values), { _id: distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer._id, user_id: distributorOfficer === null || distributorOfficer === void 0 ? void 0 : distributorOfficer.user_id }), {
                onSuccess: function () {
                    toast({
                        status: 'success',
                        description: 'Officer saved',
                    });
                    refreshData();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: distributorOfficerFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'signup' ? 'Add' : 'Update', " ", props.label] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'active', label: 'Active' },
                                                        { value: 'inactive', label: 'Inactive' },
                                                    ] }, void 0) }), void 0)), _jsxs(SimpleGrid, __assign({ columns: [1, 2], spacingX: "10px" }, { children: [_jsx(FormGroup, __assign({ label: "First name" }, { children: _jsx(FormikField, { size: "sm", name: "firstname", placeholder: "first name" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Last name" }, { children: _jsx(FormikField, { size: "sm", name: "lastname", placeholder: "last name" }, void 0) }), void 0)] }), void 0), _jsx(FormGroup, __assign({ label: "Email address" }, { children: _jsx(FormikField, { size: "sm", name: "email", placeholder: "email address", isDisabled: action === 'update' }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: signupDistributorOfficer.isLoading ||
                                                updateDistributorOfficer.isLoading, isDisabled: signupDistributorOfficer.isLoading ||
                                                updateDistributorOfficer.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'signup' ? 'Add' : 'Update', " ", props.label] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
DistributorOfficerForm.defaultProps = {
    action: 'signup',
    label: 'Officer',
};

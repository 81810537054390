var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { useMutateModel } from '@obtainly/http';
import { useToast } from 'src/core/hooks';
export var ComplaintActionButton = function (_a) {
    var complaint = _a.complaint, onUpdate = _a.onUpdate;
    var toast = useToast().toast;
    var _b = useMutateModel('complaint.update'), isLoading = _b.isLoading, mutate = _b.mutate;
    var toggleStatus = function () {
        var newStatus = complaint.status === 'closed' ? 'open' : 'closed';
        var _complaint = {
            _id: complaint._id,
            tag: complaint.tag,
            ref: complaint.ref,
            status: newStatus,
            distributor_id: complaint.distributor_id,
            merchant_id: complaint.merchant_id,
        };
        mutate(_complaint, {
            onSuccess: function () {
                toast({ status: 'success', description: 'Complaint updated' });
                onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate();
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Button, __assign({ size: "sm", variant: "outline", colorScheme: complaint.status === 'closed' ? 'success' : 'critical', disabled: isLoading, onClick: toggleStatus }, { children: complaint.status === 'closed' ? 'Re-open' : 'Close' }), void 0) }, void 0));
};

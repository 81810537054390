import { Role } from '@obtainly/bootstrap';
import { Merchant, Merchants } from './pages';
export var merchantPermissions = [
    { id: 'view_merchants', roles: [Role.DistributorStaff] },
];
export var merchantRoutes = [
    {
        id: 'merchants',
        path: '/customers',
        component: Merchants,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'merchant',
        path: '/customers/:id',
        component: Merchant,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

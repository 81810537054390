var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button } from '@chakra-ui/button';
import { Badge } from '@chakra-ui/layout';
import { ContentLoader, TableMaker } from 'src/core/components';
import { LocationForm } from './LocationForm';
export var LocationsTable = function (props) {
    var _a, _b;
    var _c = useState(null), selected = _c[0], setSelected = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'active' ? 'success' : 'orange' }, { children: cell.value }), void 0));
                },
            },
            { accessor: 'name', Header: 'Name' },
            { accessor: 'state', Header: 'State' },
            { accessor: 'lga', Header: 'LGA' },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () { return setSelected(row.original); } }, { children: "Edit" }), void 0));
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [!((_a = props.locations) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No locations found", contentUnavailable: true }, void 0)), !!((_b = props.locations) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { columns: columns, data: props.locations, tableProps: { isFit: true, mb: 20 } }, void 0)), _jsx(LocationForm, { isOpen: !!selected, action: "update", location: selected || undefined, onCancel: function () { return setSelected(null); }, onSuccess: function () {
                    var _a;
                    (_a = props === null || props === void 0 ? void 0 : props.refetchLocations) === null || _a === void 0 ? void 0 : _a.call(props);
                    setSelected(null);
                } }, void 0)] }, void 0));
};

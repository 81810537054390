var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { VStack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { Field, Form, Formik } from 'formik';
import { FormGroup, FormikField } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { Checkbox, SimpleGrid } from '@chakra-ui/react';
var promotionValueFormSchema = Yup.object({
    trigger_lower_value: Yup.number().required('Lower trigger value is required.'),
    trigger_upper_value: Yup.number().required('Upper trigger value is required.'),
    is_percentage: Yup.boolean().required(),
    reward: Yup.number().required('Reward is required.'),
    promotion_id: Yup.string().required('Promotion is required.'),
});
export var PromotionValueForm = function (_a) {
    var action = _a.action, promotion = _a.promotion, promotionValue = _a.promotionValue, props = __rest(_a, ["action", "promotion", "promotionValue"]);
    var toast = useToast().toast;
    var buttonSize = useBreakpointValue(['sm', 'sm']);
    var createPromotionValue = useMutateModel('promotion_value.create');
    var updatePromotionValue = useMutateModel('promotion_value.update');
    var initialValues = {
        trigger_lower_value: (promotionValue === null || promotionValue === void 0 ? void 0 : promotionValue.trigger_lower_value) || 0,
        trigger_upper_value: (promotionValue === null || promotionValue === void 0 ? void 0 : promotionValue.trigger_upper_value) || 0,
        reward: (promotionValue === null || promotionValue === void 0 ? void 0 : promotionValue.reward) || 0,
        promotion_id: (promotionValue === null || promotionValue === void 0 ? void 0 : promotionValue.promotion_id) || (promotion === null || promotion === void 0 ? void 0 : promotion._id) || '',
        is_percentage: (promotionValue === null || promotionValue === void 0 ? void 0 : promotionValue.is_percentage) || false,
    };
    var onSubmit = function (values, actions) {
        if (action === 'create') {
            createPromotionValue.mutate(values, {
                onSuccess: function () {
                    props.onSuccess();
                    actions.resetForm();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updatePromotionValue.mutate(__assign(__assign({}, values), { _id: promotionValue === null || promotionValue === void 0 ? void 0 : promotionValue._id }), {
                onSuccess: function () {
                    props.onSuccess();
                    actions.resetForm();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    var isLoading = createPromotionValue.isLoading || updatePromotionValue.isLoading;
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: promotionValueFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(ModalHeader, { children: action === 'create'
                                        ? 'New Promotion Value'
                                        : 'Update Promotion Value' }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [_jsxs(SimpleGrid, __assign({ columns: [1, 2], spacingX: "20px", mb: 0 }, { children: [_jsx(FormGroup, __assign({ label: "Lower trigger value" }, { children: _jsx(FormikField, { size: "sm", name: "trigger_lower_value", placeholder: "Lower trigger value", type: "text" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Upper trigger value" }, { children: _jsx(FormikField, { size: "sm", name: "trigger_upper_value", placeholder: "Upper trigger value", type: "text" }, void 0) }), void 0)] }), void 0), _jsxs(FormGroup, __assign({ label: "Reward" }, { children: [_jsx(FormikField, { size: "sm", name: "reward", placeholder: "Reward", type: "text" }, void 0), _jsx(Field, __assign({ as: Checkbox, name: "is_percentage", colorScheme: "primary", isChecked: formik.values.is_percentage }, { children: "Is percentage?" }), void 0)] }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: buttonSize, variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsx(Button, __assign({ size: buttonSize, type: "submit", colorScheme: "primary", isLoading: isLoading, isDisabled: isLoading || !formik.dirty || !formik.isValid, onClick: function () { return onSubmit(formik.values, formik); } }, { children: action === 'create' ? 'Add' : 'Update' }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};

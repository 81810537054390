var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { formatCurrency } from '@obtainly/utils';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, CardHeader, ContentLoader, TableMaker, } from 'src/core/components';
import { useAtom, distributorAtom } from 'src/core/store';
export var DistributorMerchantStatement = function (_a) {
    var _b = _a.showHeader, showHeader = _b === void 0 ? true : _b, setExportableData = _a.setExportableData, queryData = _a.queryData;
    var distributor = useAtom(distributorAtom)[0];
    var payload = React.useMemo(function () {
        return (queryData !== null && queryData !== void 0 ? queryData : {
            _id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
        });
    }, [distributor, queryData]);
    var _c = useQueryModel('merchant.distributor_statement', payload), data = _c.data, isLoading = _c.isLoading;
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Customer',
                accessor: 'merchant.business_name',
            },
            {
                Header: 'Opening Balance',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
                accessor: 'opening_balance',
            },
            {
                Header: 'Customer Payment',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
                accessor: 'payments.total',
            },
            {
                Header: 'Order Deduction',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(Math.abs(cell.value));
                },
                props: { isNumeric: true },
                accessor: 'processed.total',
            },
            {
                Header: 'Closing Balance',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return formatCurrency(cell.value);
                },
                props: { isNumeric: true },
                accessor: 'closing_balance',
            },
        ];
    }, []);
    React.useEffect(function () {
        if (setExportableData && (data === null || data === void 0 ? void 0 : data.length)) {
            setExportableData(data);
        }
    }, [data, setExportableData]);
    return (_jsxs(Card, { children: [_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !(data === null || data === void 0 ? void 0 : data.length), errorSummary: "No transactions found" }, void 0), !isLoading && (data === null || data === void 0 ? void 0 : data.length) ? (_jsxs(_Fragment, { children: [showHeader ? (_jsx(CardHeader, { border: 0, borderRadius: 0, heading: "Customer Statement", borderTop: "1px solid", borderColor: "gray.100" }, void 0)) : null, _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: _jsx(TableMaker, { data: data || [], columns: columns, tableProps: { isFit: true } }, void 0) }), void 0)] }, void 0)) : null] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Select } from '@chakra-ui/react';
import { useRouter } from 'src/core/hooks';
import { OrderTag, OrderType } from '@obtainly/models';
import { DateRangeInput, FilterMenu } from 'src/core/components';
export var OrdersFilterMenu = function (props) {
    var navigate = useRouter('orders');
    var _a = React.useState(props.filters), filters = _a[0], setFilters = _a[1];
    var activeFilters = Object.keys(props.filters);
    React.useEffect(function () {
        setFilters(props.filters);
    }, [props.filters]);
    return (_jsxs(FilterMenu, __assign({ activeCount: activeFilters === null || activeFilters === void 0 ? void 0 : activeFilters.length, refreshButtonProps: {
            onClick: function () { return navigate.switchQuery(filters); },
        } }, { children: [_jsx(FilterMenu.Section, __assign({ label: "Type" }, { children: _jsxs(Select, __assign({ size: "sm", value: filters.type || '', onChange: function (_a) {
                        var target = _a.target;
                        return setFilters(__assign(__assign({}, filters), { type: target.value }));
                    } }, { children: [_jsx("option", __assign({ value: "" }, { children: "----" }), void 0), _jsx("option", __assign({ value: OrderType.Delivery }, { children: "Delivery" }), void 0), _jsx("option", __assign({ value: OrderType.Pickup }, { children: "Pickup" }), void 0)] }), void 0) }), void 0), _jsx(FilterMenu.Section, __assign({ label: "Status" }, { children: _jsxs(Select, __assign({ size: "sm", value: filters.tag || '', onChange: function (_a) {
                        var target = _a.target;
                        return setFilters(__assign(__assign({}, filters), { tag: target.value }));
                    } }, { children: [_jsx("option", __assign({ value: "" }, { children: "----" }), void 0), _jsx("option", __assign({ value: OrderTag.Draft }, { children: "Draft" }), void 0), _jsx("option", __assign({ value: OrderTag.Pending }, { children: "Pending" }), void 0), _jsx("option", __assign({ value: OrderTag.Processing }, { children: "Processing" }), void 0), _jsx("option", __assign({ value: OrderTag.InTransit }, { children: "In Transit" }), void 0), _jsx("option", __assign({ value: OrderTag.Delivered }, { children: "Delivered" }), void 0), _jsx("option", __assign({ value: OrderTag.Cancelled }, { children: "Cancelled" }), void 0)] }), void 0) }), void 0), _jsx(FilterMenu.Section, __assign({ label: "Date Range" }, { children: _jsx(DateRangeInput, { start: filters.start_date, end: filters.end_date, onChange: function (start_date, end_date) {
                        setFilters(__assign(__assign({}, filters), { start_date: start_date, end_date: end_date }));
                    } }, void 0) }), void 0)] }), void 0));
};

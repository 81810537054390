import { Role } from '@obtainly/bootstrap';
import { Price, Prices } from './pages';
export var pricePermissions = [
    { id: 'view_prices', roles: [Role.DistributorStaff] },
    { id: 'create_price', roles: [Role.DistributorStaff] },
    { id: 'update_price', roles: [Role.DistributorStaff] },
];
export var priceRoutes = [
    {
        id: 'prices',
        path: '/prices',
        component: Prices,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'price',
        path: '/prices/:id',
        component: Price,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

export * from './components';
import { Role } from '@obtainly/bootstrap';
import { Location, Locations } from './pages';
export var locationPermissions = [
    { id: 'view_locations', roles: [Role.DistributorStaff] },
];
export var locationRoutes = [
    {
        id: 'locations',
        path: '/locations',
        component: Locations,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'location',
        path: '/locations/:id',
        component: Location,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton } from '@chakra-ui/button';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/layout';
import React from 'react';
import { FiX } from 'react-icons/fi';
import { ImageBox, QuantityInput } from 'src/core/components';
import { useProductReadBy } from '@obtainly/models';
import { useAtom, distributorAtom } from 'src/core/store';
var ProductListBuilder = function (props) {
    var onUpdate = props.onUpdate;
    var distributor = useAtom(distributorAtom)[0];
    var data = useProductReadBy({ distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }).data;
    var products = React.useMemo(function () {
        var _a;
        return ((_a = props.presetProducts) === null || _a === void 0 ? void 0 : _a.length) ? props.presetProducts : data;
    }, [data, props.presetProducts]);
    var _a = React.useState({}), productDict = _a[0], setProductDict = _a[1];
    React.useEffect(function () {
        var dict = {};
        products === null || products === void 0 ? void 0 : products.forEach(function (product) {
            if (product === null || product === void 0 ? void 0 : product._id) {
                dict[product._id] = { product: product, quantity: 0 };
            }
        });
        setProductDict(dict);
    }, [products]);
    var list = React.useMemo(function () {
        var all = Object.values(productDict);
        return all;
    }, [productDict]);
    var upsertProduct = React.useCallback(function (_a) {
        var product = _a.product, quantity = _a.quantity;
        if (product === null || product === void 0 ? void 0 : product._id) {
            setProductDict(function (prev) {
                var dict = __assign({}, prev);
                dict[product._id].quantity = quantity;
                var arr = Object.values(dict).filter(function (item) { return item.quantity > 0; });
                onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate(arr);
                return dict;
            });
        }
    }, []); // eslint-disable-line
    return (_jsx(_Fragment, { children: list.map(function (item, index) { return (_jsx(Box, __assign({ mt: 2, p: "6px", boxShadow: "sm", borderRadius: "8px", border: "1px solid", bg: item.quantity > 0 ? 'warning.50' : 'gray.50', borderColor: item.quantity > 0 ? 'warning.100' : 'gray.100', transition: "opacity 300ms" }, { children: _jsxs(Grid, __assign({ templateRows: "1fr", templateColumns: ['48px 5fr 3fr 36px'], gridRowGap: [4, 0], gridColumnGap: 2, alignItems: "center" }, { children: [_jsx(GridItem, __assign({ rowSpan: [2, 1] }, { children: _jsx(Flex, __assign({ justifyContent: "center", alignItems: "center" }, { children: _jsx(ImageBox, { boxSize: "48px" }, void 0) }), void 0) }), void 0), _jsx(GridItem, { children: item.product.name }, void 0), _jsx(GridItem, { children: _jsx(Flex, __assign({ justify: "end" }, { children: _jsx(QuantityInput, { value: item.quantity, onChange: function (val) { return upsertProduct(__assign(__assign({}, item), { quantity: val })); }, isValueEditable: true }, void 0) }), void 0) }, void 0), _jsx(GridItem, { children: _jsx(Box, __assign({ transition: "opacity 300ms" }, { children: item.quantity > 0 && (_jsx(IconButton, { size: "sm", boxShadow: "sm", variant: "ghost", bg: "white", border: "1px solid", borderColor: "critical.50", colorScheme: "critical", "aria-label": "Delete", icon: _jsx(FiX, {}, void 0), onClick: function () { return upsertProduct(__assign(__assign({}, item), { quantity: 0 })); } }, void 0)) }), void 0) }, void 0)] }), void 0) }), index)); }) }, void 0));
};
export default ProductListBuilder;

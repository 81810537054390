var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Grid } from '@chakra-ui/layout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { FormikField, FormGroup } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { authAtom, useAtom, userAtom } from 'src/core/store';
import { analytics, Event } from 'src/core/utils';
var profileFormSchema = Yup.object({
    name: Yup.string().required('Company name field is required.'),
});
export var DistributorProfileForm = function () {
    var _a, _b, _c;
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var _d = useAtom(authAtom), setAuth = _d[1];
    var doUpdate = useMutateModel('distributor.update');
    var initialValues = {
        _id: (_a = user === null || user === void 0 ? void 0 : user.distributor) === null || _a === void 0 ? void 0 : _a._id,
        name: (_b = user === null || user === void 0 ? void 0 : user.distributor) === null || _b === void 0 ? void 0 : _b.name,
        status: (_c = user === null || user === void 0 ? void 0 : user.distributor) === null || _c === void 0 ? void 0 : _c.status,
    };
    var onSubmit = function (values) {
        doUpdate.mutate(values, {
            onSuccess: function () {
                analytics.track(Event.ProfileUpdate);
                toast({ status: 'success', description: 'Distributor profile saved' });
                setAuth(function (state) { return (__assign(__assign({}, state), { refreshedAt: new Date() })); });
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: profileFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (formik) { return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(Grid, __assign({ gridGap: "10px", templateColumns: ['1fr'] }, { children: _jsx(FormGroup, __assign({ label: "Company name" }, { children: _jsx(FormikField, { name: "name" }, void 0) }), void 0) }), void 0), _jsx(Button, __assign({ mt: 2, type: "submit", colorScheme: "primary", isDisabled: doUpdate.isLoading || !formik.dirty || !formik.isValid, isLoading: doUpdate.isLoading }, { children: "Update Distributor" }), void 0)] }), void 0)); } }), void 0) }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormGroup } from 'src/core/components';
import { VStack } from '@chakra-ui/layout';
import { useToast } from 'src/core/hooks';
import { useMutateModel } from '@obtainly/http';
import { DistributorOfficerAutocomplete } from 'src/distributor';
import { Role } from '@obtainly/bootstrap';
var formSchema = Yup.object({
    officer: Yup.string().required('Officer is required.'),
    location: Yup.string().required('Location is required.'),
    merchant_id: Yup.string().required('Customer ID is required.'),
    distributor_id: Yup.string().required('Distributor ID is required.'),
});
export var DistributorMerchantForm = function (props) {
    var toast = useToast().toast;
    var action = props.action, distributorMerchant = props.distributorMerchant, merchantId = props.merchantId, distributorId = props.distributorId, title = props.title;
    var createMerchant = useMutateModel('distributor_merchant.create');
    var updateMerchant = useMutateModel('distributor_merchant.update');
    var initialValues = {
        location: (distributorMerchant === null || distributorMerchant === void 0 ? void 0 : distributorMerchant.location) || '*',
        officer: (distributorMerchant === null || distributorMerchant === void 0 ? void 0 : distributorMerchant.officer) || '',
        merchant_id: merchantId,
        distributor_id: distributorId,
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createMerchant.mutate(__assign({}, values), {
                onSuccess: function () {
                    var _a;
                    toast({
                        status: 'success',
                        description: title + ' added.',
                    });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateMerchant.mutate(__assign(__assign({}, values), { _id: distributorMerchant === null || distributorMerchant === void 0 ? void 0 : distributorMerchant._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: title + ' saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: formSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " ", title] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsx(VStack, __assign({ spacing: 5, align: "stretch" }, { children: _jsx(FormGroup, __assign({ label: "Sales Officer" }, { children: _jsx(DistributorOfficerAutocomplete, { value: formik.values.officer, onChange: function (_a) {
                                                    var value = _a.value;
                                                    return formik.setFieldValue('officer', value);
                                                }, role: Role.SalesOfficer }, void 0) }), void 0) }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createMerchant.isLoading || updateMerchant.isLoading, isDisabled: createMerchant.isLoading ||
                                                updateMerchant.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " ", title] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
DistributorMerchantForm.defaultProps = {
    action: 'create',
    title: 'Distributor Merchant',
};

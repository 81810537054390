var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { useMutateModel } from '@obtainly/http';
import { ContentLoader, TableMaker } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { PromotionProductForm } from './PromotionProductForm';
export var PromotionProductsTable = function (props) {
    var promotionProducts = props.promotionProducts;
    var toast = useToast().toast;
    var _a = React.useState(false), editMode = _a[0], setEditMode = _a[1];
    var _b = React.useState(null), promotionProduct = _b[0], setPromotionProduct = _b[1];
    var _c = useMutateModel('promotion_product.delete'), isDeleting = _c.isLoading, deletePromotion = _c.mutate;
    var columns = React.useMemo(function () {
        return [
            {
                Header: 'Product',
                accessor: 'product.name',
                Cell: function (_a) {
                    var value = _a.value, row = _a.row;
                    return (_jsxs(Flex, __assign({ flexDirection: "column" }, { children: [_jsx(Text, { children: value }, void 0), row.original.reward_product_id !== row.original.product_id && (_jsxs(Text, __assign({ fontSize: "sm", color: "gray.600", noOfLines: 1 }, { children: ["+ ", row.original.reward_product.name] }), void 0))] }), void 0));
                },
            },
            { Header: 'Trigger', accessor: 'trigger' },
            { Header: 'Value', accessor: 'trigger_value' },
            { Header: 'Reward', accessor: 'reward' },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () {
                                            setPromotionProduct(row.original);
                                            setEditMode(true);
                                        }, disabled: isDeleting }, { children: "Edit" }), void 0), !!promotionProduct && (_jsx(PromotionProductForm, { isOpen: editMode, action: "update", onCancel: function () {
                                            setPromotionProduct(null);
                                            setEditMode(false);
                                        }, onSuccess: function () {
                                            var _a;
                                            (_a = props.refetchPromotionProducts) === null || _a === void 0 ? void 0 : _a.call(props);
                                            setPromotionProduct(null);
                                            setEditMode(false);
                                        }, promotion: props.promotion, promotionProduct: promotionProduct }, void 0))] }, void 0), _jsx(Button, __assign({ colorScheme: "critical", size: "sm", onClick: function () {
                                    deletePromotion({
                                        _id: row.original._id,
                                    }, {
                                        onSuccess: function () {
                                            var _a;
                                            toast({
                                                status: 'success',
                                                description: 'Deleted successfully.',
                                            });
                                            (_a = props.refetchPromotionProducts) === null || _a === void 0 ? void 0 : _a.call(props);
                                        },
                                        onError: function (_a) {
                                            var message = _a.message;
                                            toast({
                                                status: 'error',
                                                description: message,
                                            });
                                        },
                                    });
                                }, disabled: isDeleting, isLoading: isDeleting }, { children: "Remove" }), void 0)] }), void 0));
                },
            },
        ];
    }, [editMode, props, promotionProduct, isDeleting, deletePromotion, toast]);
    return (_jsx(_Fragment, { children: !(promotionProducts === null || promotionProducts === void 0 ? void 0 : promotionProducts.length) ? (_jsx(ContentLoader, { errorSummary: "No promotion products found", contentUnavailable: true }, void 0)) : (_jsx(TableMaker, { tableProps: { isFit: true, mb: 20 }, columns: columns, data: promotionProducts }, void 0)) }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Flex, Box, Text, VStack, Grid, GridItem } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import { useParams } from 'react-router';
import { OrderTag } from '@obtainly/models';
import { Card, CardBody, ContentLoader, CreditInfoBox, OrderPromotions, } from '@obtainly/ui/web-shared';
import { ContentHeader, Layout } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { useOrder } from '../hooks/useOrder';
import { OrderPicker, OrderTagBadge, OrderInvoice, OrderForm, OrderDetails, OrderActions, } from '../components';
import { useOrderConfig } from '@obtainly/hooks';
import { distributorAtom, useAtom } from 'src/core/store';
export var OrderContext = React.createContext({});
export var Order = function () {
    var router = useRouter();
    var sizes = useTheme().sizes;
    var distributor = useAtom(distributorAtom)[0];
    var orderId = useParams().id;
    var orderHook = useOrder(orderId);
    var order = orderHook.order, orderModel = orderHook.orderModel, loadingOrder = orderHook.isLoading, isRefetching = orderHook.isRefetching;
    var _a = useOrderConfig(distributor === null || distributor === void 0 ? void 0 : distributor._id), loadingOrderConfig = _a.isLoading, isOrderActivated = _a.isOrderActivated;
    var isLoading = loadingOrder || loadingOrderConfig;
    var renderHeading = function (order) {
        return (_jsxs(Flex, __assign({ gridGap: 2, alignItems: "center" }, { children: [_jsx(Text, __assign({ textTransform: "capitalize" }, { children: "".concat(order.type, " #").concat(order.ref) }), void 0), ' ', _jsx(OrderTagBadge, { tag: order.tag, type: order.type }, void 0)] }), void 0));
    };
    return (_jsx(Layout, __assign({ meta: {
            title: (order === null || order === void 0 ? void 0 : order.tag) === OrderTag.Draft
                ? 'New Order'
                : "Order #".concat((order === null || order === void 0 ? void 0 : order.ref) || ''),
        } }, { children: _jsxs(OrderContext.Provider, __assign({ value: __assign(__assign({}, orderHook), { refreshOrder: orderModel === null || orderModel === void 0 ? void 0 : orderModel.refetch, isRefreshing: isRefetching }) }, { children: [_jsx(ContentHeader, __assign({ heading: order ? renderHeading(order) : 'New Order', onBack: router.goBack }, { children: _jsx(OrderActions, {}, void 0) }), void 0), _jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: !isOrderActivated && !order, errorTitle: "Service unavailable", errorMessage: "Orders cannot be placed at the moment" }, void 0), !isLoading && (isOrderActivated || order) ? (_jsxs(Grid, __assign({ templateColumns: ['1fr', '1fr', '2fr 1fr'], gridGap: 5 }, { children: [_jsx(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: !order ? (_jsx(OrderForm, {}, void 0)) : (_jsxs(_Fragment, { children: [_jsx(OrderDetails, {}, void 0), _jsx(OrderPicker, {}, void 0)] }, void 0)) }), void 0), _jsx(GridItem, { children: _jsxs(Box, __assign({ position: "sticky", top: sizes.layout.stickyContentTop }, { children: [!!order && (_jsx(Card, __assign({ mb: 4 }, { children: _jsx(CardBody, { children: _jsx(OrderPromotions, { showHeading: true, order: order }, void 0) }, void 0) }), void 0)), !!(order === null || order === void 0 ? void 0 : order.merchant_id) &&
                                        [OrderTag.Draft].includes(order.tag) &&
                                        !!(order === null || order === void 0 ? void 0 : order.distributor) && (_jsx(CreditInfoBox, { mb: 4, merchantId: order.merchant_id, distributor: order.distributor }, void 0)), _jsx(OrderInvoice, {}, void 0)] }), void 0) }, void 0)] }), void 0)) : null] }), void 0) }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { basicSearch } from '@obtainly/utils';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, SearchInput, Importer, Exporter, PageTabs, } from 'src/core/components';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { TargetsTable, TargetForm } from '../components';
import { useRouter } from 'src/core/hooks';
var exportColumns = [
    { label: 'Customer ID', accessor: 'merchant_id', selected: false },
    {
        label: 'Customer Name',
        accessor: 'merchant.business_name',
        selected: true,
    },
    { label: 'Year', accessor: 'year', selected: true },
    { label: 'Month', accessor: 'month', selected: true },
    { label: 'Day', accessor: 'day', selected: true },
    { label: 'Tag', accessor: 'tag', selected: true },
    { label: 'Value', accessor: 'value', selected: true },
];
var importColumns = function (distributorId) {
    return [
        { id: 'distributor_id', label: 'Distributor ID', value: distributorId },
        { id: 'merchant_id', label: 'Customer ID' },
        { id: 'year', label: 'Year' },
        { id: 'month', label: 'Month' },
        { id: 'day', label: 'Day', value: 0 },
        { id: 'tag', label: 'Tag', value: 'sales' },
        { id: 'value', label: 'Value' },
    ];
};
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["ThisMonth"] = 1] = "ThisMonth";
    PageTab[PageTab["LastMonth"] = 2] = "LastMonth";
})(PageTab || (PageTab = {}));
export var Targets = function () {
    var router = useRouter('targets');
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var thisMonth = new Date().getMonth() + 1;
    var lastMonth = new Date().getMonth();
    var _a = useQueryModel('target.read_by_distributor', { distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id }, { enabled: !!(user === null || user === void 0 ? void 0 : user.distributor_id) }), targets = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredTargets = useFilter(targets || [], filters, {
        search: function (target) {
            var _a;
            return basicSearch(target === null || target === void 0 ? void 0 : target.tag, filters.search) ||
                basicSearch((_a = target === null || target === void 0 ? void 0 : target.merchant) === null || _a === void 0 ? void 0 : _a.business_name, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length) {
            return (filters === null || filters === void 0 ? void 0 : filters.month) === String(thisMonth)
                ? PageTab.ThisMonth
                : (filters === null || filters === void 0 ? void 0 : filters.month) === String(lastMonth)
                    ? PageTab.LastMonth
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]); // eslint-disable-line
    var handleTabChange = function (index) {
        if (index === PageTab.ThisMonth) {
            router.switchQuery(__assign(__assign({}, filters), { month: thisMonth }));
        }
        else if (index === PageTab.LastMonth) {
            router.switchQuery(__assign(__assign({}, filters), { month: lastMonth }));
        }
        else if (filters.search) {
            router.switchQuery({ search: filters.search });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Targets' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Targets", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Exporter, { filename: "obtainly-targets", data: filteredTargets, columns: exportColumns, isDisabled: isLoading || !(filteredTargets === null || filteredTargets === void 0 ? void 0 : filteredTargets.length) }, void 0), _jsx(Importer, { model: "target", label: "Target", columns: (distributor === null || distributor === void 0 ? void 0 : distributor._id) ? importColumns(distributor._id) : [], onSuccess: function () { return refetch(); } }, void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: "Add Target" }), void 0), _jsx(TargetForm, { isOpen: createMode, onSuccess: function () {
                                        refetch();
                                        setCreateMode(false);
                                    }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [
                            { label: 'All' },
                            { label: 'This Month' },
                            { label: 'Last Month' },
                        ] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search targets", isDisabled: !(targets === null || targets === void 0 ? void 0 : targets.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(TargetsTable, { targets: filteredTargets || [], refetchTargets: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

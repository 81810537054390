var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { useMutateModel } from '@obtainly/http';
import { ContentLoader, TableMaker } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { PromotionValueForm } from './PromotionValueForm';
export var PromotionValuesTable = function (_a) {
    var promotionValues = _a.promotionValues, props = __rest(_a, ["promotionValues"]);
    var toast = useToast().toast;
    var _b = React.useState(null), promotionValue = _b[0], setPromotionValue = _b[1];
    var _c = useMutateModel('promotion_value.delete'), isDeleting = _c.isLoading, deletePromotion = _c.mutate;
    var columns = React.useMemo(function () {
        return [
            { Header: 'Lower trigger value', accessor: 'trigger_lower_value' },
            { Header: 'Upper trigger value', accessor: 'trigger_upper_value' },
            { Header: 'Reward', accessor: 'reward' },
            { Header: 'Percentage', accessor: 'is_percentage' },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(_Fragment, { children: _jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () {
                                        setPromotionValue(row.original);
                                    }, disabled: isDeleting }, { children: "Edit" }), void 0) }, void 0), _jsx(Button, __assign({ colorScheme: "critical", size: "sm", onClick: function () {
                                    deletePromotion({
                                        _id: row.original._id,
                                    }, {
                                        onSuccess: function () {
                                            var _a;
                                            toast({
                                                status: 'success',
                                                description: 'Deleted successfully.',
                                            });
                                            (_a = props.refetchPromotionValues) === null || _a === void 0 ? void 0 : _a.call(props);
                                        },
                                        onError: function (_a) {
                                            var message = _a.message;
                                            toast({
                                                status: 'error',
                                                description: message,
                                            });
                                        },
                                    });
                                }, disabled: isDeleting, isLoading: isDeleting }, { children: "Remove" }), void 0)] }), void 0));
                },
            },
        ];
    }, [props, isDeleting, deletePromotion, toast]);
    return (_jsxs(_Fragment, { children: [!(promotionValues === null || promotionValues === void 0 ? void 0 : promotionValues.length) ? (_jsx(ContentLoader, { errorSummary: "No promotion products found", contentUnavailable: true }, void 0)) : (_jsx(TableMaker, { tableProps: { isFit: true, mb: 20 }, columns: columns, data: promotionValues }, void 0)), !!promotionValue && (_jsx(PromotionValueForm, { isOpen: !!promotionValue, action: "update", onCancel: function () {
                    setPromotionValue(null);
                }, onSuccess: function () {
                    var _a;
                    (_a = props.refetchPromotionValues) === null || _a === void 0 ? void 0 : _a.call(props);
                    setPromotionValue(null);
                }, promotion: props.promotion, promotionValue: promotionValue }, void 0))] }, void 0));
};

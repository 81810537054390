var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import { Card, CardHeader, CardBody } from 'src/core/components';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
export var PriceDetails = function (_a) {
    var price = _a.price;
    var sizes = useTheme().sizes;
    var details = React.useMemo(function () {
        var output = [
            { label: 'Reference', value: price.ref },
            {
                label: 'Discount',
                value: price.is_percentage
                    ? price.discount + '%'
                    : formatCurrency(price.discount),
                color: price.discount > 0 ? 'success.600' : 'critical.600',
            },
            { label: 'Description', value: price.description },
            {
                label: 'Status',
                value: price.status,
            },
            {
                label: 'Date',
                value: formatUnixTime(price.created),
            },
        ];
        price.discount !== 0 &&
            output.splice(2, 0, {
                label: 'Is percentage based',
                value: price.is_percentage ? 'Yes' : 'No',
            });
        return output;
    }, [price]);
    return (_jsxs(Card, __assign({ position: "sticky", top: sizes.layout.stickyContentTop }, { children: [_jsx(CardHeader, { heading: "Price" }, void 0), _jsx(CardBody, __assign({ pt: 0 }, { children: _jsx(VStack, __assign({ mt: 3, spacing: 3, alignItems: "stretch" }, { children: details.map(function (detail) { return (_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: detail.label }), void 0), _jsx(Text, __assign({ color: detail.color }, { children: detail.value }), void 0)] }, detail.label)); }) }), void 0) }), void 0)] }), void 0));
};

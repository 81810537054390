import { Role } from '@obtainly/bootstrap';
import { DistributorLocations, DistributorOfficers, MerchantStatementReport, PaymentsReport, SettlementsReport, } from './pages';
export var distributorPermissions = [
    { id: 'view_payments', roles: [Role.DistributorStaff] },
    { id: 'view_statement', roles: [Role.DistributorStaff] },
    { id: 'view_settlements', roles: [Role.DistributorStaff] },
    { id: 'view_sales_officers', roles: [Role.DistributorStaff] },
    { id: 'view_distributor_locations', roles: [Role.DistributorStaff] },
];
export var distributorRoutes = [
    {
        id: 'distributor-payments',
        path: '/payments',
        component: PaymentsReport,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'distributor-statement',
        path: '/statement',
        component: MerchantStatementReport,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'distributor-settlements',
        path: '/settlements',
        component: SettlementsReport,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'distributor-officers',
        path: '/officers',
        component: DistributorOfficers,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'distributor-locations',
        path: '/distributor_locations',
        component: DistributorLocations,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { Flex, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { FiBox, FiEye, FiEyeOff } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { formatCurrency, formatTimeDuration, formatUnixTime, parseOrderMeta, } from '@obtainly/utils';
import { OrderMetaTag, OrderTag, OrderType } from '@obtainly/models';
import { ContentLoader, RowExpansionHeader, TableMaker, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { OrderTagBadge } from './OrderBadge';
import { OrderQuickActions } from './OrderQuickActions';
import { OrderRowSubComponent } from './OrderRowSubComponent';
import { RiTimeFill } from 'react-icons/ri';
export var OrdersTable = function (_a) {
    var _b, _c, _d;
    var expand = _a.expand, props = __rest(_a, ["expand"]);
    var router = useRouter();
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                id: 'merchant',
                Header: 'Customer',
                Cell: function (_a) {
                    var _b, _c, _d, _e;
                    var cell = _a.cell, row = _a.row;
                    return expand && !((_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.is_first) ? null : (_jsxs(Flex, __assign({ flexDir: "column", align: "flex-start", justify: "space-between", gridGap: 2 }, { children: [_jsxs(Box, __assign({ width: "300px", isTruncated: true }, { children: [_jsx(Link, __assign({ to: router.getLink('merchant', {
                                            id: row.original.merchant_id,
                                        }) }, { children: cell.value }), void 0), _jsx(Text, __assign({ fontSize: "sm", color: "gray.600", isTruncated: true }, { children: (_e = (_d = (_c = row.original.meta_obj) === null || _c === void 0 ? void 0 : _c[OrderMetaTag.MerchantLocation]) === null || _d === void 0 ? void 0 : _d.merchant_location) === null || _e === void 0 ? void 0 : _e.address }), void 0)] }), void 0), _jsxs(Box, { children: [_jsx(Link, __assign({ to: router.getLink('order', { id: row.original._id }) }, { children: _jsx(Button, __assign({ size: "xs", variant: "outline" }, { children: "View" }), void 0) }), void 0), [
                                        OrderTag.Pending,
                                        OrderTag.Processing,
                                        OrderTag.InTransit,
                                    ].includes(row.original.tag) ? (_jsx(OrderQuickActions, { order: row.original, refetchOrders: props.refetchOrders }, void 0)) : null] }, void 0)] }), void 0));
                },
                accessor: 'merchant.business_name',
            },
            {
                Header: 'Amount (NGN)',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell, row = _a.row;
                    return expand && !((_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.is_first) ? null : (_jsx(Flex, __assign({ align: "center", justify: "end", gridGap: 2 }, { children: _jsx(Text, { children: formatCurrency(cell.value) }, void 0) }), void 0));
                },
                props: { isNumeric: true },
                accessor: 'amount',
            },
            {
                id: 'sku_expander',
                Header: function (props) { return _jsx(RowExpansionHeader, __assign({}, props, { title: "SKUs" }), void 0); },
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Text, __assign({}, row.getToggleRowExpandedProps(), { textAlign: "center" }, { children: row.isExpanded ? _jsx(FiEyeOff, {}, void 0) : _jsx(FiEye, {}, void 0) }), void 0));
                },
            },
            {
                Header: 'Date',
                Cell: function (_a) {
                    var _b, _c, _d, _e;
                    var cell = _a.cell, row = _a.row;
                    var hide = !!expand && !((_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.is_first);
                    var date = (cell === null || cell === void 0 ? void 0 : cell.value) || ((_c = row === null || row === void 0 ? void 0 : row.original) === null || _c === void 0 ? void 0 : _c.modified) || ((_d = row === null || row === void 0 ? void 0 : row.original) === null || _d === void 0 ? void 0 : _d.created);
                    var prefix = (cell === null || cell === void 0 ? void 0 : cell.value)
                        ? 'Placed'
                        : ((_e = row === null || row === void 0 ? void 0 : row.original) === null || _e === void 0 ? void 0 : _e.modified)
                            ? 'Last modified'
                            : 'Created';
                    return (!hide && (_jsx(Tooltip, __assign({ label: "".concat(prefix, " on ").concat(formatUnixTime(date, 'dd MMM, yyyy - h:mm:ss a')) }, { children: formatUnixTime(date, 'dd MMM, yyyy') }), void 0)));
                },
                accessor: 'timestamp',
            },
            {
                Header: 'Status',
                Cell: function (_a) {
                    var _b, _c, _d;
                    var cell = _a.cell, row = _a.row;
                    var order = row === null || row === void 0 ? void 0 : row.original;
                    var hide = !!expand && !(order === null || order === void 0 ? void 0 : order.is_first);
                    return (!hide && (_jsxs(_Fragment, { children: [_jsx(Tooltip, __assign({ label: order.type }, { children: _jsxs(Flex, __assign({ align: "center", gridGap: 2 }, { children: [_jsx(OrderTagBadge, { tag: cell.value, type: order.type }, void 0), order.type === OrderType.Pickup ? _jsx(FiBox, {}, void 0) : null] }), void 0) }), void 0), [
                                OrderTag.Pending,
                                OrderTag.Processing,
                                OrderTag.InTransit,
                            ].includes(order.tag) && (order === null || order === void 0 ? void 0 : order.stale_status) ? (_jsx(Text, __assign({ fontSize: "sm", color: ((_b = order === null || order === void 0 ? void 0 : order.stale_status) === null || _b === void 0 ? void 0 : _b[order.tag].expired)
                                    ? 'critical.600'
                                    : 'gray.700', mt: 1, display: "flex", alignItems: "center", gridGap: 1 }, { children: ((_c = order === null || order === void 0 ? void 0 : order.stale_status) === null || _c === void 0 ? void 0 : _c[order.tag].duration) > 86400 && (_jsxs(_Fragment, { children: [_jsx(RiTimeFill, { size: 12 }, void 0), _jsx("span", { children: formatTimeDuration((_d = order === null || order === void 0 ? void 0 : order.stale_status) === null || _d === void 0 ? void 0 : _d[order.tag].duration) }, void 0)] }, void 0)) }), void 0)) : null] }, void 0)));
                },
                accessor: 'tag',
            },
            {
                Header: 'Ref.',
                accessor: 'ref',
                Cell: function (_a) {
                    var cell = _a.cell, row = _a.row;
                    var order = row === null || row === void 0 ? void 0 : row.original;
                    var hide = !(order === null || order === void 0 ? void 0 : order.is_first) && !!expand;
                    var metaObject = parseOrderMeta(order, {}).metaObject;
                    return !hide ? (_jsxs(_Fragment, { children: [_jsx(Text, { children: cell.value }, void 0), !!(metaObject === null || metaObject === void 0 ? void 0 : metaObject[OrderMetaTag.PlacedBy]) &&
                                order.tag === OrderTag.Pending && (_jsxs(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: ["Proposed by ", metaObject[OrderMetaTag.PlacedBy].firstname] }), void 0)), !!(metaObject === null || metaObject === void 0 ? void 0 : metaObject[OrderMetaTag.CancelledBy]) &&
                                order.tag === OrderTag.Cancelled && (_jsxs(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: ["Cancelled by", ' ', metaObject[OrderMetaTag.CancelledBy].firstname] }), void 0))] }, void 0)) : null;
                },
            },
        ];
    }, [router, expand, props.refetchOrders]);
    return (_jsxs(_Fragment, { children: [!((_b = props.orders) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(ContentLoader, { errorSummary: "No orders found", contentUnavailable: true }, void 0)), !!((_c = props.orders) === null || _c === void 0 ? void 0 : _c.length) && (_jsx(TableMaker, { data: props.orders, columns: columns, route: { id: (_d = props.route) === null || _d === void 0 ? void 0 : _d.id }, hiddenColumns: props.hiddenColumns, tableProps: { isFit: true, mb: 20 }, renderRowSubComponent: OrderRowSubComponent }, void 0))] }, void 0));
};

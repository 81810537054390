var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, Box, Grid, VStack } from '@chakra-ui/layout';
import { FiEdit2 } from 'react-icons/fi';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useQueryModel } from '@obtainly/http';
import { Layout, ContentHeader, ContentLoader } from 'src/core/components';
import { MerchantForm, MerchantPageContent, MerchantDetails, MerchantTags, } from '../components';
export var Merchant = function () {
    var history = useHistory();
    var merchantId = useParams().id;
    var _a = useQueryModel('merchant.read', { _id: merchantId }), merchants = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), updateMode = _b[0], setUpdateMode = _b[1];
    var merchant = React.useMemo(function () {
        return (merchants === null || merchants === void 0 ? void 0 : merchants[0]) || null;
    }, [merchants]);
    return (_jsx(Layout, __assign({ meta: { title: 'Customers' } }, { children: !merchant ? (_jsx(ContentLoader, { isLoading: isLoading, errorSummary: "Customer not found" }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(ContentHeader, __assign({ heading: (merchant === null || merchant === void 0 ? void 0 : merchant.business_name) || 'Customer', onBack: function () { return history.goBack(); } }, { children: _jsxs(Flex, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiEdit2, {}, void 0), onClick: function () {
                                    setUpdateMode(true);
                                } }, { children: "Edit" }), void 0), _jsx(MerchantForm, { action: "update", isOpen: updateMode, merchant: merchant, onSuccess: function () {
                                    refetch();
                                    setUpdateMode(false);
                                }, onCancel: function () { return setUpdateMode(false); } }, void 0)] }, void 0) }), void 0), _jsxs(Grid, __assign({ templateColumns: ['1fr', '1fr', '1fr 2fr'], gridGap: 5 }, { children: [_jsxs(VStack, __assign({ alignItems: "stretch" }, { children: [_jsx(Box, { children: _jsx(MerchantDetails, { merchant: merchant }, void 0) }, void 0), _jsx(Box, { children: _jsx(MerchantTags, { merchant: merchant }, void 0) }, void 0)] }), void 0), _jsx(VStack, __assign({ spacing: 5, alignItems: "stretch", overflowX: "auto" }, { children: _jsx(MerchantPageContent, { merchant: merchant }, void 0) }), void 0)] }), void 0)] }, void 0)) }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useQueryClient } from 'react-query';
import { Button } from '@chakra-ui/button';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { VStack } from '@chakra-ui/layout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { LedgerLogTag } from '@obtainly/models';
import { FormikField, FormGroup, Autocomplete } from 'src/core/components';
import { useToast } from 'src/core/hooks';
var ledgerFormSchema = Yup.object({
    description: Yup.string().required('Description is required.'),
    tag: Yup.string().required('Tag is required.'),
    value: Yup.number()
        .test('is-number', 'Value must be a number', function (value) {
        return !!String(value).match('[0-9]');
    })
        .required('Value is required.'),
});
export var LedgerLogForm = function (props) {
    var toast = useToast().toast;
    var queryClient = useQueryClient();
    var ledger = props.ledger;
    var createLedger = useMutateModel('ledger_log.deposit_to');
    var initialValues = {
        description: '',
        value: '',
        tag: '',
    };
    var onSubmit = function (values) {
        var requestValues = __assign(__assign({}, values), { owner: ledger.owner, head: ledger.head, subhead: ledger.subhead });
        createLedger.mutate(requestValues, {
            onSuccess: function () {
                toast({ status: 'success', description: 'Ledger created' });
                // refetch associated queries
                queryClient.refetchQueries("merchant_read_".concat(ledger.owner));
                queryClient.refetchQueries("ledger_read_by_owner_specific_".concat(ledger.owner, "_").concat(ledger.head, "_").concat(ledger.subhead));
                queryClient.refetchQueries("ledger_log_read_by_ledger_".concat(ledger._id));
                queryClient.refetchQueries("ledger_read_".concat(ledger._id));
                // close modal
                props.onCancel();
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: ledgerFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(ModalHeader, { children: "New Ledger Entry" }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [_jsx(FormGroup, __assign({ label: "Tag", mb: 0 }, { children: _jsx(Autocomplete, { size: "sm", value: formik.values.tag, onChange: function (_a) {
                                                        var value = _a.value;
                                                        return formik.setFieldValue('tag', value);
                                                    }, options: Object.entries(LedgerLogTag).map(function (_a) {
                                                        var key = _a[0], value = _a[1];
                                                        return ({ label: key, value: value });
                                                    }) }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Value" }, { children: _jsx(FormikField, { size: "sm", name: "value", type: "number" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Description" }, { children: _jsx(FormikField, { as: "textarea", name: "description" }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsx(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createLedger.isLoading, isDisabled: createLedger.isLoading || !formik.dirty || !formik.isValid }, { children: "Add Entry" }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikField, FormGroup, FormikRadioGroup } from 'src/core/components';
import { VStack } from '@chakra-ui/layout';
import { useToast } from 'src/core/hooks';
import { useMutateModel } from '@obtainly/http';
import { LocationAutocomplete } from 'src/location';
var merchantFormSchema = Yup.object({
    business_name: Yup.string()
        .required('Customer business name is required.')
        .min(3, 'Customer business name must be at least 3 characters.'),
    address: Yup.string()
        .required('Customer address is required.')
        .min(3, 'Customer address must be at least 3 characters.'),
    status: Yup.string().required('Status is required.'),
    merchant_id: Yup.string().required('Customer ID is required.'),
    distributor_id: Yup.string().required('Distributor ID is required.'),
    location_id: Yup.string().required('Location ID is required.'),
});
export var MerchantLocationForm = function (props) {
    var toast = useToast().toast;
    var action = props.action, location = props.location, merchantId = props.merchantId, distributorId = props.distributorId;
    var createMerchant = useMutateModel('merchant_location.create');
    var updateMerchant = useMutateModel('merchant_location.update');
    var initialValues = {
        business_name: (location === null || location === void 0 ? void 0 : location.business_name) || '',
        address: (location === null || location === void 0 ? void 0 : location.address) || '',
        merchant_id: merchantId,
        distributor_id: distributorId,
        location_id: (location === null || location === void 0 ? void 0 : location.location_id) || '',
        status: (location === null || location === void 0 ? void 0 : location.status) || 'active',
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createMerchant.mutate(__assign({}, values), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Merchant created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateMerchant.mutate(__assign(__assign({}, values), { _id: location === null || location === void 0 ? void 0 : location._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Merchant saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: merchantFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Location"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'active', label: 'Active' },
                                                        { value: 'inactive', label: 'Inactive' },
                                                    ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Business name" }, { children: _jsx(FormikField, { size: "sm", name: "business_name", placeholder: "Acme Inc." }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Address" }, { children: _jsx(FormikField, { as: "textarea", name: "address", placeholder: "29 Coker Road, Yaba Valley" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Location" }, { children: _jsx(LocationAutocomplete, { name: "location_id", value: formik.values.location_id, onChange: function (_a) {
                                                        var value = _a.value;
                                                        return formik.setFieldValue('location_id', value);
                                                    }, distributorId: distributorId, disableSelectAll: true }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createMerchant.isLoading || updateMerchant.isLoading, isDisabled: createMerchant.isLoading ||
                                                updateMerchant.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Location"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
MerchantLocationForm.defaultProps = {
    action: 'create',
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { useQueryModel } from '@obtainly/http';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, Importer, Exporter, PageTabs, } from 'src/core/components';
import { useAtom, userAtom, distributorAtom } from 'src/core/store';
import { CreditsTable, CreditForm, CreditsFilterMenu } from '../components';
import { useRouter } from 'src/core/hooks';
var exportColumns = [
    { label: 'ID', accessor: '_id', selected: false },
    { label: 'Status', accessor: 'status', selected: true },
    { label: 'Reference', accessor: 'ref', selected: true },
    {
        label: 'Value',
        accessor: 'value',
        selected: true,
        modifier: formatCurrency,
    },
    {
        label: 'Date',
        accessor: 'created',
        selected: true,
        modifier: function (timestamp) { return formatUnixTime(timestamp, 'd MMM yyy'); },
    },
];
var importColumns = function (distributorId) {
    return [
        { id: 'merchant_id', label: 'Customer ID' },
        { id: 'merchant_name', label: 'Business Name' },
        { id: 'ref', label: 'Reference' },
        { id: 'value', label: 'Value' },
        { id: 'meta', label: 'Meta', value: '-' },
        { id: 'status', label: 'Status', value: 'pending' },
        { id: 'distributor_id', label: 'Distributor ID', value: distributorId },
    ];
};
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Applied"] = 1] = "Applied";
    PageTab[PageTab["Pending"] = 2] = "Pending";
})(PageTab || (PageTab = {}));
export var Credits = function () {
    var router = useRouter('credits');
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('credit.read_by_distributor', {
        distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id,
    }), credits = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredCredits = useFilter(credits || [], filters, {
        merchant_id: function (credit) { return credit.merchant_id > filters.merchant_id; },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'pending'
                ? PageTab.Pending
                : filters.status === 'applied'
                    ? PageTab.Applied
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Pending) {
            router.switchQuery({ status: 'pending' });
        }
        else if (index === PageTab.Applied) {
            router.switchQuery({ status: 'applied' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Credit Notes' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Credit Notes", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(CreditsFilterMenu, { filters: filters }, void 0), _jsx(Exporter, { filename: "obtainly-credits", data: filteredCredits, columns: exportColumns, isDisabled: isLoading || !(filteredCredits === null || filteredCredits === void 0 ? void 0 : filteredCredits.length) }, void 0), _jsx(Importer, { model: "credit", label: "Credit Note", columns: (distributor === null || distributor === void 0 ? void 0 : distributor._id) ? importColumns(distributor._id) : [], onSuccess: function () { return refetch(); } }, void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: "Add Credit Note" }), void 0), _jsx(CreditForm, { isOpen: createMode, onSuccess: function () {
                                        refetch();
                                        setCreateMode(false);
                                    }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Applied' }, { label: 'Pending' }] }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(CreditsTable, { credits: filteredCredits || [], refetchCredits: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

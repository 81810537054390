var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { useQueryModel } from '@obtainly/http';
import { Autocomplete } from 'src/core/components';
export var GeolocationAutocomplete = function (props) {
    var activeState = props.activeState, value = props.value, onChange = props.onChange, rest = __rest(props, ["activeState", "value", "onChange"]);
    var states = useQueryModel('https://api.csv49.xyz$csv8.json', {}).data;
    var isLGA = useMemo(function () { return typeof activeState === 'string'; }, [activeState]);
    var locationOptions = React.useMemo(function () {
        var _a;
        if (states === null || states === void 0 ? void 0 : states.length) {
            var options = [];
            if (!props.isMulti && !props.disableSelectAll) {
                options.push({
                    value: '*',
                    label: "All ".concat(isLGA ? 'LGAs' : 'States'),
                });
            }
            else {
                options.push({
                    value: '',
                    label: "Select ".concat(isLGA ? 'LGA' : 'State'),
                });
            }
            if (isLGA) {
                var filtered = ((_a = states
                    .find(function (state) { return state.state === activeState; })) === null || _a === void 0 ? void 0 : _a.lgas.map(function (lga) { return ({
                    value: lga,
                    label: lga,
                }); })) || [];
                return __spreadArray(__spreadArray([], options, true), sortBy(filtered, function (location) { return location.label; }), true);
            }
            else {
                var filtered = states.map(function (item) { return ({
                    value: item.state,
                    label: item.state,
                }); });
                return __spreadArray(__spreadArray([], options, true), sortBy(filtered, function (location) { return location.label; }), true);
            }
        }
        return [];
    }, [isLGA, activeState, states, props.isMulti, props.disableSelectAll]);
    return (_jsx(Autocomplete, __assign({ options: locationOptions, value: value, placeholder: isLGA ? 'Select LGA' : 'Select State', onChange: function (selected) { return onChange(selected); }, isActive: value && value.value !== '*' ? true : false, isDisabled: !(locationOptions === null || locationOptions === void 0 ? void 0 : locationOptions.length) }, rest), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, Box, Text } from '@chakra-ui/layout';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutateModel, useQueryModel } from '@obtainly/http';
import { Autocomplete, FormGroup, FormikField } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { useAtom, distributorAtom } from 'src/core/store';
import { Progress } from '@chakra-ui/react';
var getSchema = function (value) {
    return Yup.object({
        tag: Yup.string().required('Tag is required'),
        value: value !== null && value !== void 0 ? value : Yup.string().required('Value is required'),
        merchant_id: Yup.string().required('Merchant ID is required'),
        distributor_id: Yup.string().required('Distributor ID is required'),
    });
};
export var MerchantTagForm = function (_a) {
    var tag = _a.tag, merchantId = _a.merchantId, options = _a.options, label = _a.label, helperText = _a.helperText, valueFieldType = _a.valueFieldType, valueFieldSchema = _a.valueFieldSchema, valueFieldPrepend = _a.valueFieldPrepend, valueFieldAppend = _a.valueFieldAppend, valueFieldTransform = _a.valueFieldTransform;
    var toast = useToast().toast;
    var distributor = useAtom(distributorAtom)[0];
    var _b = useState(), merchantTag = _b[0], setMerchantTag = _b[1];
    var _c = useQueryModel('merchant_tag.read_by_distributor_merchant_tag', { tag: tag, merchant_id: merchantId, distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, {
        enabled: !!tag && !!merchantId && !!(distributor === null || distributor === void 0 ? void 0 : distributor._id),
        queryHash: "merchant_tag".concat(tag, "_").concat(merchantId, "_").concat(distributor === null || distributor === void 0 ? void 0 : distributor._id),
    }), merchantTags = _c.data, loadingMerchantTags = _c.isLoading, refetchMerchantTags = _c.refetch;
    var _d = useMutateModel('merchant_tag.upsert'), isUpserting = _d.isLoading, upsertMerchantTag = __rest(_d, ["isLoading"]);
    useEffect(function () {
        setMerchantTag(merchantTags === null || merchantTags === void 0 ? void 0 : merchantTags[0]);
    }, [merchantTags]);
    var initialValues = useMemo(function () {
        var value = (merchantTag === null || merchantTag === void 0 ? void 0 : merchantTag.value) || '';
        // decode "value" after fetch
        if (valueFieldTransform) {
            value = valueFieldTransform.decode(value);
        }
        return {
            tag: tag,
            value: value,
            merchant_id: (merchantTag === null || merchantTag === void 0 ? void 0 : merchantTag.merchant_id) || merchantId,
            distributor_id: (merchantTag === null || merchantTag === void 0 ? void 0 : merchantTag.distributor_id) || (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
        };
    }, [merchantId, merchantTag, distributor, tag, valueFieldTransform]);
    var onSubmit = function (data) {
        // encode "value" befor updating
        if (valueFieldTransform) {
            data = __assign(__assign({}, data), { value: valueFieldTransform.encode(data.value) });
        }
        upsertMerchantTag.mutate(data, {
            onSuccess: function () {
                toast({
                    status: 'success',
                    description: "".concat(label !== null && label !== void 0 ? label : 'Tag', " updated"),
                });
                setMerchantTag(data);
                refetchMerchantTags();
            },
            onError: function (error) {
                toast({
                    status: 'error',
                    description: (error === null || error === void 0 ? void 0 : error.message) || 'An error occurred',
                });
            },
        });
    };
    return (_jsxs(Box, { children: [_jsxs(Box, __assign({ mb: 1 }, { children: [_jsx(Text, __assign({ fontWeight: "medium" }, { children: label }), void 0), helperText && (_jsx(Text, __assign({ fontSize: "sm", color: "gray.600" }, { children: helperText }), void 0))] }), void 0), loadingMerchantTags ? (_jsx(Progress, { h: "36px", borderRadius: "md", colorScheme: "gray", opacity: 0.2, isAnimated: true, isIndeterminate: true }, void 0)) : (_jsx(Formik, __assign({ initialValues: initialValues, validationSchema: getSchema(valueFieldSchema), onSubmit: onSubmit, enableReinitialize: true }, { children: function (_a) {
                    var formik = __rest(_a, []);
                    return (_jsxs(Form, { children: [_jsx(FormGroup, { children: (options === null || options === void 0 ? void 0 : options.length) ? (_jsx(Autocomplete, { size: "sm", value: formik.values.value, onChange: function (_a) {
                                        var value = _a.value;
                                        formik.setFieldValue('value', value);
                                    }, options: __spreadArray([{ label: '---', value: '' }], options, true) }, void 0)) : (_jsx(FormikField, { size: "sm", name: "value", type: valueFieldType, prepend: valueFieldPrepend, append: valueFieldAppend }, void 0)) }, void 0), formik.dirty && (_jsxs(Flex, __assign({ align: "center", gap: 3, justify: "end" }, { children: [_jsx(Button, __assign({ size: "sm", disabled: isUpserting, onClick: function () { return formik.resetForm(); } }, { children: "Cancel" }), void 0), _jsx(Button, __assign({ size: "sm", colorScheme: "primary", isLoading: isUpserting, disabled: isUpserting || !formik.isValid, onClick: function () { return formik.submitForm(); } }, { children: "Save" }), void 0)] }), void 0))] }, void 0));
                } }), void 0))] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { basicSearch } from '@obtainly/utils';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, SearchInput, Exporter, PageTabs, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { useAtom, distributorAtom } from 'src/core/store';
import { DistributorLocationsTable, DistributorLocationForm, DistributorLocationsFilterMenu, } from '../components';
import { lang } from 'src/core/config';
import pluralize from 'pluralize';
var exportableColumns = function () {
    return [
        {
            label: pluralize(lang.distributorLocation, 2) + ' ID',
            accessor: '_id',
            selected: false,
        },
        { label: 'Status', accessor: 'status', selected: true },
        { label: lang.distributorLocation, accessor: 'name', selected: true },
        { label: 'Address', accessor: 'address', selected: true },
        { label: 'State', accessor: 'location.state', selected: true },
        { label: 'LGA', accessor: 'location.lga', selected: true },
    ];
};
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
export var DistributorLocations = function () {
    var router = useRouter('distributor-locations');
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('distributor_location.read_by_distributor', { distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), distributorLocations = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredDistributorLocations = useFilter(distributorLocations || [], filters, {
        search: function (distributorLocation) {
            var _a, _b, _c;
            return basicSearch(distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.name, filters.search) ||
                basicSearch(distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.address, filters.search) ||
                basicSearch(((_a = distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.location) === null || _a === void 0 ? void 0 : _a.name) || '', filters.search) ||
                basicSearch(((_b = distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.location) === null || _b === void 0 ? void 0 : _b.state) || '', filters.search) ||
                basicSearch(((_c = distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.location) === null || _c === void 0 ? void 0 : _c.lga) || '', filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'active'
                ? PageTab.Active
                : filters.status === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery({ status: 'active' });
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery({ status: 'inactive' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: pluralize(lang.distributorLocation, 2) } }, { children: [_jsx(ContentHeader, __assign({ heading: pluralize(lang.distributorLocation, 2), border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(DistributorLocationsFilterMenu, { filters: filters }, void 0), _jsx(Exporter, { label: "Export", filename: "obtainly-".concat(String(pluralize(lang.distributorLocation, 2))
                                .toLowerCase()
                                .split(' ')
                                .join('-')), data: filteredDistributorLocations, columns: exportableColumns(), isDisabled: isLoading || !(filteredDistributorLocations === null || filteredDistributorLocations === void 0 ? void 0 : filteredDistributorLocations.length) }, void 0), _jsxs(_Fragment, { children: [_jsxs(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: ["Add ", lang.distributorLocation] }), void 0), _jsx(DistributorLocationForm, { label: lang.distributorLocation, isOpen: createMode, onSuccess: function () {
                                        refetch();
                                        setCreateMode(false);
                                    }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search ".concat(String(pluralize(lang.distributorLocation, distributorLocations === null || distributorLocations === void 0 ? void 0 : distributorLocations.length)).toLowerCase()), isDisabled: !(distributorLocations === null || distributorLocations === void 0 ? void 0 : distributorLocations.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(DistributorLocationsTable, { distributorLocations: filteredDistributorLocations || [] }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

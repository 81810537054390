var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { OrderMetaTag, OrderTag, OrderType } from '@obtainly/models';
import { useQueryStringParse, useFilter } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
import { useRouter } from 'src/core/hooks';
import { useAtom, userAtom } from 'src/core/store';
import { Card, CardBody, ContentHeader, ContentLoader, Exporter, Layout, PageTabs, } from 'src/core/components';
import { OrdersTable, OrdersFilterMenu } from '../components';
import { useOrderExpansion } from '../hooks/useOrderExpansion';
var firstRowOnly = function (value, payload) {
    return (payload === null || payload === void 0 ? void 0 : payload.is_first) ? value : '';
};
var exportColumns = [
    {
        label: 'ID',
        accessor: '_id',
        selected: false,
        modifier: firstRowOnly,
    },
    {
        label: 'Status',
        accessor: 'tag',
        selected: true,
        modifier: firstRowOnly,
    },
    {
        label: 'Ref.',
        accessor: 'ref',
        selected: true,
        modifier: firstRowOnly,
    },
    {
        label: 'Customer',
        accessor: 'merchant.business_name',
        selected: true,
        modifier: firstRowOnly,
    },
    {
        label: 'Customer Phone',
        accessor: 'merchant.phone',
        selected: false,
        modifier: firstRowOnly,
    },
    {
        label: 'Shipping Address',
        accessor: "meta_obj.".concat(OrderMetaTag.MerchantLocation, ".merchant_location.address"),
        selected: true,
        modifier: firstRowOnly,
    },
    {
        label: 'Order Amount',
        accessor: 'amount',
        selected: true,
        modifier: function (value, payload) {
            return (payload === null || payload === void 0 ? void 0 : payload.is_first) ? formatCurrency(value) : '';
        },
    },
    {
        label: 'Date',
        accessor: 'timestamp',
        selected: true,
        modifier: function (timestamp, payload) {
            return (payload === null || payload === void 0 ? void 0 : payload.is_first)
                ? String(formatUnixTime(timestamp || payload.modified || payload.created, 'd MMM yyy'))
                : '';
        },
    },
    {
        label: 'SKU',
        accessor: 'order_item_name',
        selected: true,
    },
    {
        label: 'SKU Quantity',
        accessor: 'order_item_quantity',
        selected: true,
        modifier: function (value, payload) {
            return (payload === null || payload === void 0 ? void 0 : payload.is_first) ? '' : formatCurrency(value);
        },
    },
    {
        label: 'SKU Amount',
        accessor: 'order_item_amount',
        selected: true,
        modifier: function (value, payload) {
            return (payload === null || payload === void 0 ? void 0 : payload.is_first) ? '' : formatCurrency(value);
        },
    },
];
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Pending"] = 1] = "Pending";
    PageTab[PageTab["Processing"] = 2] = "Processing";
    PageTab[PageTab["InTransit"] = 3] = "InTransit";
    PageTab[PageTab["Delivered"] = 4] = "Delivered";
    PageTab[PageTab["Cancelled"] = 5] = "Cancelled";
    PageTab[PageTab["Pickups"] = 6] = "Pickups";
    PageTab[PageTab["Draft"] = 7] = "Draft";
})(PageTab || (PageTab = {}));
export var Orders = function () {
    var router = useRouter('orders');
    var user = useAtom(userAtom)[0];
    var _a = useQueryModel('order.read_by_distributor', {
        distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id,
    }), orders = _a.data, isLoading = _a.isLoading, refetchOrders = _a.refetch;
    var filters = useQueryStringParse([], ['page']);
    var filteredOrders = useFilter(orders || [], filters, {
        start_date: function (order) { return (order === null || order === void 0 ? void 0 : order.timestamp) >= Number(filters.start_date); },
        end_date: function (order) { return (order === null || order === void 0 ? void 0 : order.timestamp) <= Number(filters.end_date); },
    });
    var expandedOrders = useOrderExpansion(filteredOrders).orders;
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.tag === OrderTag.Pending
                ? PageTab.Pending
                : filters.tag === OrderTag.Processing
                    ? PageTab.Processing
                    : filters.tag === OrderTag.InTransit
                        ? PageTab.InTransit
                        : filters.tag === OrderTag.Delivered
                            ? PageTab.Delivered
                            : filters.tag === OrderTag.Cancelled
                                ? PageTab.Cancelled
                                : filters.type === OrderType.Pickup
                                    ? PageTab.Pickups
                                    : filters.tag === OrderTag.Draft
                                        ? PageTab.Draft
                                        : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Pending) {
            router.switchQuery({ tag: OrderTag.Pending });
        }
        else if (index === PageTab.Processing) {
            router.switchQuery({ tag: OrderTag.Processing });
        }
        else if (index === PageTab.InTransit) {
            router.switchQuery({ tag: OrderTag.InTransit });
        }
        else if (index === PageTab.Delivered) {
            router.switchQuery({ tag: OrderTag.Delivered });
        }
        else if (index === PageTab.Cancelled) {
            router.switchQuery({ tag: OrderTag.Cancelled });
        }
        else if (index === PageTab.Pickups) {
            router.switchQuery({ type: OrderType.Pickup });
        }
        else if (index === PageTab.Draft) {
            router.switchQuery({ tag: OrderTag.Draft });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Orders' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Orders", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(OrdersFilterMenu, { filters: filters }, void 0), _jsx(Exporter, { filename: "obtainly-orders", data: expandedOrders, columns: exportColumns, isDisabled: isLoading || !(expandedOrders === null || expandedOrders === void 0 ? void 0 : expandedOrders.length) }, void 0), _jsx(_Fragment, { children: _jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () { return router.go('order', { id: 'create' }, null); } }, { children: "New Order" }), void 0) }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: Object.values(PageTab) }, void 0), _jsx(CardBody, __assign({ p: 0, pt: 2 }, { children: isLoading ? (_jsx(ContentLoader, { isLoading: true }, void 0)) : (_jsx(OrdersTable, { orders: filteredOrders || [], route: { id: 'orders' }, refetchOrders: refetchOrders }, void 0)) }), void 0)] }, void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Badge, Flex, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { FiBox } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { MerchantTag, OrderType } from '@obtainly/models';
import { formatCurrency } from '@obtainly/utils';
import { ContentLoader, TableMaker } from 'src/core/components';
import { useAtom, distributorAtom } from 'src/core/store';
import { useRouter } from 'src/core/hooks';
export var MerchantsTable = function (props) {
    var _a, _b;
    var router = useRouter();
    var distributor = useAtom(distributorAtom)[0];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'paid' ? 'success' : 'orange' }, { children: cell.value }), void 0));
                },
            },
            {
                accessor: 'business_name',
                Header: 'Customer',
                Cell: function (_a) {
                    var _b, _c, _d, _e;
                    var cell = _a.cell, row = _a.row;
                    return (_jsx(_Fragment, { children: _jsxs(Flex, __assign({ align: "center", gridGap: 2 }, { children: [_jsx(Text, __assign({ textTransform: "capitalize" }, { children: cell.value }), void 0), ((_e = (_d = (_c = (_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.merchant_tags) === null || _c === void 0 ? void 0 : _c["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)]) === null || _d === void 0 ? void 0 : _d[MerchantTag.ALLOWED_ORDER_TYPE]) === null || _e === void 0 ? void 0 : _e.value) === OrderType.Pickup && (_jsx(Tooltip, __assign({ label: "Pickup" }, { children: _jsx(Badge, __assign({ p: "1" }, { children: _jsx(FiBox, {}, void 0) }), void 0) }), void 0))] }), void 0) }, void 0));
                },
            },
            { accessor: 'firstname', Header: 'First Name' },
            { accessor: 'lastname', Header: 'Last Name' },
            { accessor: 'phone', Header: 'Phone' },
            {
                Header: 'Total Payment',
                Cell: function (_a) {
                    var _b, _c, _d, _e;
                    var row = _a.row;
                    return (_e = formatCurrency((_d = (_c = (_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.ledgers) === null || _c === void 0 ? void 0 : _c["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)]) === null || _d === void 0 ? void 0 : _d.balance)) !== null && _e !== void 0 ? _e : '-';
                },
                props: {
                    isNumeric: true,
                },
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Link, __assign({ to: router.getLink('merchant', { id: row.original._id }) }, { children: _jsx(Button, __assign({ size: "sm", variant: "outline" }, { children: "View" }), void 0) }), void 0));
                },
            },
        ];
    }, [distributor, router]);
    return (_jsxs(_Fragment, { children: [!((_a = props.merchants) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No customers found", contentUnavailable: true }, void 0)), !!((_b = props.merchants) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { columns: columns, data: props.merchants, tableProps: { isFit: true, mb: 20 } }, void 0))] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, VStack } from '@chakra-ui/layout';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger, } from '@chakra-ui/popover';
import { FiMoreVertical, FiPlus } from 'react-icons/fi';
import { useQueryModel } from '@obtainly/http';
import { MerchantTag, OrderType, } from '@obtainly/models';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { basicSearch } from '@obtainly/utils';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, SearchInput, Importer, Exporter, PageTabs, } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { useAtom, distributorAtom, configAtom } from 'src/core/store';
import { MerchantsTable, MerchantForm, MerchantsFilterMenu, MerchantTagBulkAssigner, } from '../components';
import { orderBy } from 'lodash';
var exportableColumns = function (isCredit) {
    if (isCredit === void 0) { isCredit = false; }
    return isCredit
        ? [
            { label: 'Customer ID', accessor: '_id', selected: false },
            { label: 'Business Name', accessor: 'business_name', selected: false },
            { label: 'Phone', accessor: 'phone', selected: false },
            { label: 'Credit Reference', accessor: '', selected: true },
            { label: 'Credit Value', accessor: '', selected: true },
        ]
        : [
            { label: 'Customer ID', accessor: '_id', selected: false },
            { label: 'Status', accessor: 'status', selected: true },
            { label: 'Business Name', accessor: 'business_name', selected: true },
            { label: 'First Name', accessor: 'firstname', selected: true },
            { label: 'Last Name', accessor: 'lastname', selected: true },
            { label: 'Phone', accessor: 'phone', selected: true },
        ];
};
var importColumns = function (distributorId, locationId, storeTypeId) {
    return [
        { id: 'business_name', label: 'Business Name' },
        { id: 'firstname', label: 'First Name' },
        { id: 'lastname', label: 'Last Name' },
        { id: 'phone', label: 'Phone Number' },
        { id: 'email', label: 'Email Address' },
        { id: 'address', label: 'Address' },
        { id: 'hash', label: 'Password', value: 'password' },
        { id: 'status', label: 'Status', value: 'active' },
        { id: 'location_id', label: 'Location ID', value: locationId },
        { id: 'store_type_id', label: 'Store Type ID', value: storeTypeId },
        { id: 'distributor_id', label: 'Distributor ID', value: distributorId },
    ];
};
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
export var Merchants = function () {
    var router = useRouter('merchants');
    var distributor = useAtom(distributorAtom)[0];
    var _a = useAtom(configAtom)[0], defaultLocationId = _a.defaultLocationId, defaultStoreTypeId = _a.defaultStoreTypeId;
    var _b = useQueryModel('merchant.read_by_distributor', { distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }, { enabled: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), merchants = _b.data, isLoading = _b.isLoading, refetch = _b.refetch;
    var prices = useQueryModel('price.read_all').data;
    var priceOptions = React.useMemo(function () {
        return prices
            ? prices.map(function (price) { return ({
                label: price.ref,
                value: price._id,
            }); })
            : [];
    }, [prices]);
    var _c = React.useState(false), createMode = _c[0], setCreateMode = _c[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredMerchants = useFilter(orderBy(merchants || [], 'business_name', 'asc'), filters, {
        order_type: function (merchant) {
            var _a, _b, _c;
            return basicSearch((_c = (_b = (_a = merchant === null || merchant === void 0 ? void 0 : merchant.merchant_tags) === null || _a === void 0 ? void 0 : _a["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)]) === null || _b === void 0 ? void 0 : _b[MerchantTag.ALLOWED_ORDER_TYPE]) === null || _c === void 0 ? void 0 : _c.value, filters.order_type);
        },
        price_class: function (merchant) {
            var _a, _b, _c;
            return basicSearch((_c = (_b = (_a = merchant === null || merchant === void 0 ? void 0 : merchant.merchant_tags) === null || _a === void 0 ? void 0 : _a["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)]) === null || _b === void 0 ? void 0 : _b[MerchantTag.PRICE_CLASS]) === null || _c === void 0 ? void 0 : _c.value, filters.price_class);
        },
        search: function (merchant) {
            return basicSearch(merchant === null || merchant === void 0 ? void 0 : merchant.business_name, filters.search) ||
                basicSearch(merchant === null || merchant === void 0 ? void 0 : merchant.firstname, filters.search) ||
                basicSearch(merchant === null || merchant === void 0 ? void 0 : merchant.lastname, filters.search) ||
                basicSearch(merchant === null || merchant === void 0 ? void 0 : merchant.phone, filters.search);
        },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'active'
                ? PageTab.Active
                : filters.status === 'inactive'
                    ? PageTab.Inactive
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Active) {
            router.switchQuery({ status: 'active' });
        }
        else if (index === PageTab.Inactive) {
            router.switchQuery({ status: 'inactive' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Customers' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Customers", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(MerchantsFilterMenu, { filters: filters }, void 0), _jsxs(Popover, __assign({ placement: "bottom-end" }, { children: [_jsx(PopoverTrigger, { children: _jsx(Button, __assign({ leftIcon: _jsx(FiMoreVertical, {}, void 0), variant: "outline", size: "sm" }, { children: "More" }), void 0) }, void 0), _jsx(PopoverContent, __assign({ _focus: {
                                        border: 'none',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        borderColor: 'gray.200',
                                    } }, { children: _jsx(PopoverBody, __assign({ p: "6" }, { children: _jsxs(VStack, __assign({ spacing: 3, alignItems: "stretch", minH: "40" }, { children: [_jsx(Exporter, { label: "Export Credit Notes", filename: "obtainly-customers", data: filteredMerchants, columns: exportableColumns(true), isDisabled: isLoading || !(filteredMerchants === null || filteredMerchants === void 0 ? void 0 : filteredMerchants.length) }, void 0), _jsx(Exporter, { label: "Export", filename: "obtainly-merchants", data: filteredMerchants, columns: exportableColumns(), isDisabled: isLoading || !(filteredMerchants === null || filteredMerchants === void 0 ? void 0 : filteredMerchants.length) }, void 0), _jsx(Importer, { model: "merchant", label: "Customer", columns: (distributor === null || distributor === void 0 ? void 0 : distributor._id)
                                                        ? importColumns(distributor._id, defaultLocationId, defaultStoreTypeId)
                                                        : [], onSuccess: function () { return refetch(); } }, void 0), _jsx(MerchantTagBulkAssigner, { tag: MerchantTag.PRICE_CLASS, label: "Price Class", options: priceOptions, onSuccess: refetch }, void 0), _jsx(MerchantTagBulkAssigner, { tag: MerchantTag.ALLOWED_ORDER_TYPE, label: "Order Type", options: Object.entries(OrderType).map(function (_a) {
                                                        var key = _a[0], value = _a[1];
                                                        return {
                                                            label: key,
                                                            value: value,
                                                        };
                                                    }), onSuccess: refetch }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: "Add Customer" }), void 0), _jsx(MerchantForm, { isOpen: createMode, onSuccess: function () {
                                        refetch();
                                        setCreateMode(false);
                                    }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsx(CardBody, { children: _jsx(SearchInput, { value: filters.search || '', onChange: function (value) {
                                return router.switchQuery(__assign(__assign({}, filters), { search: value }));
                            }, placeholder: "Search customers", isDisabled: !(merchants === null || merchants === void 0 ? void 0 : merchants.length) }, void 0) }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && _jsx(MerchantsTable, { merchants: filteredMerchants || [] }, void 0)] }), void 0)] }, void 0)] }), void 0));
};

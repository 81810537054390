import { Role } from '@obtainly/bootstrap';
import { Feeds } from './pages/Feeds';
export var feedPermissions = [
    { id: 'view_feeds', roles: [Role.DistributorStaff] },
    { id: 'create_feed', roles: [Role.DistributorStaff] },
    { id: 'update_feed', roles: [Role.DistributorStaff] },
];
export var feedRoutes = [
    {
        id: 'feeds',
        path: '/feeds',
        component: Feeds,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

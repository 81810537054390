import { Role } from '@obtainly/bootstrap';
import { Complaint, Complaints, NewComplaint } from './pages';
export var complaintPermissions = [
    {
        id: 'view_complaints',
        roles: [Role.DistributorStaff, Role.Merchant, Role.MerchantOfficer],
    },
    {
        id: 'create_complaint',
        roles: [Role.DistributorStaff, Role.Merchant, Role.MerchantOfficer],
    },
    { id: 'update_complaint', roles: [Role.DistributorStaff] },
];
export var complaintRoutes = [
    {
        id: 'complaints',
        path: '/complaints',
        component: Complaints,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'complaint',
        path: '/complaints/:id',
        component: Complaint,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'complaint-new',
        path: '/complaint/new',
        component: NewComplaint,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

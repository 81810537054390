var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { Link } from 'react-router-dom';
import { useRouter } from 'src/core/hooks';
import { useMutateModel } from '@obtainly/http';
import { OrderMetaTag, OrderType } from '@obtainly/models';
import { formatCurrency } from '@obtainly/utils';
import { Card, CardBody } from 'src/core/components';
import { usePermission, useToast } from 'src/core/hooks';
import { OrderContext } from '../pages/Order';
import { OrderTypeBadge, OrderTagBadge } from './OrderBadge';
export var OrderDetails = function () {
    var _a, _b;
    var router = useRouter();
    var toast = useToast().toast;
    var userCan = usePermission().userCan;
    var order = React.useContext(OrderContext).order;
    var _c = React.useState(false), isSavingNote = _c[0], setIsSavingNote = _c[1];
    var _d = React.useState(false), editNote = _d[0], setEditNote = _d[1];
    var _e = React.useState((_b = (_a = order === null || order === void 0 ? void 0 : order.meta_obj) === null || _a === void 0 ? void 0 : _a.NOTE) === null || _b === void 0 ? void 0 : _b.value), note = _e[0], setNote = _e[1];
    var _f = React.useState(), editedNote = _f[0], setEditedNote = _f[1];
    var details = React.useMemo(function () {
        var _a, _b, _c, _d;
        var output = [
            {
                label: 'Status',
                value: (_jsx(OrderTagBadge, { tag: order === null || order === void 0 ? void 0 : order.tag, type: order === null || order === void 0 ? void 0 : order.type }, void 0)),
            },
            {
                label: 'Order Type',
                value: _jsx(OrderTypeBadge, { type: order === null || order === void 0 ? void 0 : order.type }, void 0),
            },
            {
                label: 'Customer',
                value: (_jsx(Link, __assign({ to: router.getLink('merchant', { id: order === null || order === void 0 ? void 0 : order.merchant_id }) }, { children: _jsx(Text, __assign({ fontWeight: "medium" }, { children: (_a = order === null || order === void 0 ? void 0 : order.merchant) === null || _a === void 0 ? void 0 : _a.business_name }), void 0) }), void 0)),
            },
            {
                label: 'Shipping Address',
                value: (_jsx(Text, { children: ((_d = (_c = (_b = order === null || order === void 0 ? void 0 : order.meta_obj) === null || _b === void 0 ? void 0 : _b[OrderMetaTag.MerchantLocation]) === null || _c === void 0 ? void 0 : _c.merchant_location) === null || _d === void 0 ? void 0 : _d.address) || '-' }, void 0)),
            },
            {
                label: 'Amount',
                value: "".concat(formatCurrency((order === null || order === void 0 ? void 0 : order.amount) || 0, { showCurrency: true })),
            },
        ];
        if ((order === null || order === void 0 ? void 0 : order.type) === OrderType.Pickup) {
            return output.filter(function (_, i) { return i !== 3; });
        }
        else {
            return output;
        }
    }, [order, router]);
    // Update order note meta
    var createOrderNote = useMutateModel('order_meta.create');
    var updateOrderNote = useMutateModel('order_meta.update');
    var handleSubmit = function () {
        var _a;
        setNote(editedNote);
        setIsSavingNote(true);
        if ((_a = order === null || order === void 0 ? void 0 : order.meta_obj) === null || _a === void 0 ? void 0 : _a.NOTE) {
            updateOrderNote.mutate({
                _id: order.meta_obj.NOTE._id,
                tag: OrderMetaTag.Note,
                value: editedNote || '',
                meta: '',
                order_id: order === null || order === void 0 ? void 0 : order._id,
            }, {
                onSuccess: function () {
                    setIsSavingNote(false);
                    setEditNote(false);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ description: message, status: 'error' });
                    setIsSavingNote(false);
                },
            });
        }
        else {
            createOrderNote.mutate({
                tag: OrderMetaTag.Note,
                value: editedNote || '',
                meta: '',
                order_id: order === null || order === void 0 ? void 0 : order._id,
            }, {
                onSuccess: function () {
                    setIsSavingNote(false);
                    setEditNote(false);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ description: message, status: 'error' });
                    setIsSavingNote(false);
                },
            });
        }
    };
    return (_jsx(Card, { children: _jsx(CardBody, __assign({ pt: 2 }, { children: _jsxs(SimpleGrid, __assign({ columns: 2, mt: 3, spacing: 3, alignItems: "stretch" }, { children: [details.map(function (detail) {
                        return (detail === null || detail === void 0 ? void 0 : detail.value) ? (_jsxs(GridItem, { children: [_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: detail.label }), void 0), typeof detail.value === 'string' ? (_jsx(Text, __assign({ textTransform: "capitalize" }, { children: detail.value }), void 0)) : (detail.value)] }, detail.label)) : null;
                    }), _jsxs(GridItem, __assign({ colSpan: 2 }, { children: [!!note && (_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: "Order Note" }), void 0)), editNote ? (_jsxs(_Fragment, { children: [_jsx(Textarea, { value: editedNote, onChange: function (_a) {
                                            var target = _a.target;
                                            return setEditedNote(target.value);
                                        }, placeholder: "Enter order note here", rows: 2 }, void 0), _jsxs(Flex, __assign({ mt: 4, gridGap: 2 }, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", onClick: handleSubmit, isLoading: isSavingNote }, { children: "Save" }), void 0), _jsx(Button, __assign({ size: "sm", colorScheme: "gray", onClick: function () {
                                                    setEditNote(false);
                                                    setEditedNote('');
                                                }, isDisabled: isSavingNote }, { children: "Cancel" }), void 0)] }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Text, __assign({ textTransform: "capitalize" }, { children: note }), void 0), userCan('create_order_note') && (_jsx(Text, __assign({ fontSize: "sm", color: "gray.600", fontWeight: "medium", cursor: "pointer", onClick: function () {
                                            setEditNote(true);
                                            setEditedNote(note);
                                        } }, { children: note ? 'Edit' : 'Add order note' }), void 0))] }, void 0))] }), void 0)] }), void 0) }), void 0) }, void 0));
};

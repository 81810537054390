var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { Card, CardBody, ContentHeader, Layout, MonthYearPicker, } from 'src/core/components';
import { Exporter } from 'src/core/components';
import { formatCurrency } from '@obtainly/utils';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { DistributorMerchantStatement } from '../components/DisitributorMerchantStatement';
import { useAtom, distributorAtom } from 'src/core/store';
var exportColumns = [
    { label: 'ID', accessor: '_id', selected: false },
    { label: 'Customer', accessor: 'merchant.business_name', selected: true },
    {
        label: 'Opening Balance',
        accessor: 'opening_balance',
        selected: true,
        modifier: formatCurrency,
    },
    {
        label: 'Customer Payment',
        accessor: 'payments.total',
        selected: true,
        modifier: formatCurrency,
    },
    {
        label: 'Order Dedcuction',
        accessor: 'processed.total',
        selected: true,
        modifier: function (value) { return formatCurrency(Math.abs(value)); },
    },
    {
        label: 'Closing Balance',
        accessor: 'closing_balance',
        selected: true,
        modifier: formatCurrency,
    },
];
export var MerchantStatementReport = function () {
    var distributor = useAtom(distributorAtom)[0];
    var _a = React.useState([]), exportableData = _a[0], setExportableData = _a[1];
    var _b = React.useState(Math.floor(new Date().getTime() / 1000.0)), selectedDate = _b[0], setSelectedDate = _b[1];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(selectedDate);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return {
            _id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            month: month,
            year: year,
        };
    }, [distributor === null || distributor === void 0 ? void 0 : distributor._id, selectedDate]);
    return (_jsxs(Layout, __assign({ meta: { title: 'Customer Statement' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Customer Statement", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(MonthYearPicker, { value: selectedDate, onChange: setSelectedDate, isFullWidth: false }, void 0), _jsx(Exporter, { filename: "obtainly-customer-statement-".concat(getUnixTime(new Date())), data: exportableData, columns: exportColumns, isDisabled: !exportableData.length }, void 0)] }), void 0) }), void 0), _jsx(Card, { children: _jsx(CardBody, __assign({ p: 0 }, { children: _jsx(DistributorMerchantStatement, { queryData: payload, showHeader: false, setExportableData: setExportableData }, void 0) }), void 0) }, void 0)] }), void 0));
};

import { Role } from '@obtainly/bootstrap';
import { Promotion, Promotions } from './pages';
export var promotionPermissions = [
    {
        id: 'view_promotions',
        roles: [Role.DistributorStaff, Role.Merchant, Role.MerchantOfficer],
    },
    {
        id: 'create_promotion',
        roles: [Role.DistributorStaff, Role.Merchant, Role.MerchantOfficer],
    },
    { id: 'update_promotion', roles: [Role.DistributorStaff] },
];
export var promotionRoutes = [
    {
        id: 'promotions',
        path: '/promotions',
        component: Promotions,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'promotion',
        path: '/promotions/:id',
        component: Promotion,
        requiresAuth: true,
        exact: true,
    },
];
export * from './components';

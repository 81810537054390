var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { usePriceReadByDistributor } from '@obtainly/models';
import { PriceForm, PricesFilterMenu, PricesTable } from 'src/price';
import { Card, CardBody, ContentHeader, ContentLoader, Layout, PageTabs, } from 'src/core/components';
import { useAtom, userAtom } from 'src/core/store';
import { useRouter } from 'src/core/hooks';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Active"] = 1] = "Active";
    PageTab[PageTab["Inactive"] = 2] = "Inactive";
})(PageTab || (PageTab = {}));
export var Prices = function () {
    var router = useRouter('prices');
    var user = useAtom(userAtom)[0];
    var _a = usePriceReadByDistributor({ distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id }), prices = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(false), createMode = _b[0], setCreateMode = _b[1];
    var filters = useQueryStringParse([], ['page']);
    var filteredPrices = useFilter(prices || [], filters);
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'inactive'
                ? PageTab.Inactive
                : filters.status === 'active'
                    ? PageTab.Active
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Inactive) {
            router.switchQuery({ status: 'inactive' });
        }
        else if (index === PageTab.Active) {
            router.switchQuery({ status: 'active' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Prices' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Prices" }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(PricesFilterMenu, { filters: filters }, void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                        setCreateMode(true);
                                    } }, { children: "Add Price Class" }), void 0), _jsx(PriceForm, { isOpen: createMode, onSuccess: function () {
                                        refetch();
                                        setCreateMode(false);
                                    }, onCancel: function () { return setCreateMode(false); } }, void 0)] }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Active' }, { label: 'Inactive' }] }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(PricesTable, { prices: filteredPrices || [], refetchPrices: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

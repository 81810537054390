import pluralize from 'pluralize';
import { lang } from '../config';
export var navigationItems = [
    {
        name: 'Orders',
        route: 'orders',
        path: 'orders',
        permission: 'view_orders',
    },
    {
        name: 'Payments',
        route: '',
        path: '',
        permission: 'view_reports',
        children: [
            {
                name: 'Payments',
                route: 'distributor-payments',
                path: 'payments',
                permission: 'view_payments',
            },
            // {
            //   name: 'Settlements',
            //   route: 'distributor-settlements',
            //   path: 'settlements',
            //   permission: 'view_settlements',
            // },
        ],
    },
    {
        name: 'Reports',
        route: '',
        path: '',
        permission: 'view_settings',
        children: [
            {
                name: 'Complaints',
                route: 'complaints',
                path: 'complaints',
                permission: 'view_complaints',
            },
            {
                name: 'Sales Performance vs. Target by Customer',
                route: '',
                path: '',
                permission: 'view_settings',
                isDisabled: true,
            },
            {
                name: 'Order Life cycle Report',
                route: '',
                path: '',
                permission: 'view_settings',
                isDisabled: true,
            },
        ],
    },
    {
        name: 'Admin',
        route: '',
        path: '',
        permission: 'view_settings',
        children: [
            {
                name: 'Customer List',
                route: 'merchants',
                path: 'customers',
                permission: 'view_merchants',
            },
            {
                name: pluralize(lang.salesOfficer, 2),
                route: 'distributor-officers',
                path: 'officers',
                permission: 'view_sales_officers',
            },
            {
                name: 'Products',
                route: 'products',
                path: 'products',
                permission: 'view_products',
            },
            {
                name: 'Promotions',
                route: '',
                path: '',
                permission: 'view_promotions',
                children: [
                    {
                        name: 'Communication',
                        route: 'feeds',
                        path: 'feeds',
                        permission: 'view_feeds',
                    },
                    {
                        name: 'View Promotions',
                        route: 'promotions',
                        path: 'promotions',
                        permission: 'view_promotions',
                    },
                ],
            },
            {
                name: 'Sales Targets',
                route: 'targets',
                path: 'targets',
                permission: 'view_targets',
            },
            {
                name: 'Order Lead Time',
                route: '',
                path: '',
                permission: '',
            },
            {
                name: 'Customer Price Groups',
                route: 'prices',
                path: 'prices',
                permission: 'view_prices',
            },
            {
                name: 'Credit Notes',
                route: 'credits',
                path: 'credits',
                permission: 'view_credits',
            },
            {
                name: 'Location',
                route: '',
                path: '',
                permission: 'view_locations',
                children: [
                    {
                        name: 'Shipping Locations',
                        route: 'distributor-locations',
                        path: 'distributor_locations',
                        permission: 'view_distributor_locations',
                    },
                    {
                        name: 'Locations',
                        route: 'locations',
                        path: 'locations',
                        permission: 'view_locations',
                    },
                ],
            },
            {
                name: 'Settings',
                route: 'settings',
                path: 'settings',
                permission: 'view_settings',
            },
        ],
    },
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { Link } from 'react-router-dom';
import { formatUnixTime } from '@obtainly/utils';
import { ContentLoader, TableMaker } from 'src/core/components';
import { useRouter } from 'src/core/hooks';
import { PromotionForm } from './PromotionForm';
import { PromotionStatusBadge } from './PromotionBadge';
export var PromotionsTable = function (_a) {
    var _b, _c;
    var refetchPromotions = _a.refetchPromotions, props = __rest(_a, ["refetchPromotions"]);
    var router = useRouter();
    var _d = React.useState(false), editMode = _d[0], setEditMode = _d[1];
    var _e = React.useState(null), selectedPromotion = _e[0], setSelectedPromotion = _e[1];
    var columns = React.useMemo(function () {
        var columnData = [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: 'Start',
                accessor: 'from_timestamp',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Tooltip, __assign({ label: formatUnixTime(cell.value, 'h:mm a') }, { children: _jsx(_Fragment, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0) }), void 0));
                },
            },
            {
                Header: 'End',
                accessor: 'to_timestamp',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Tooltip, __assign({ label: formatUnixTime(cell.value, 'h:mm a') }, { children: _jsx(_Fragment, { children: formatUnixTime(cell.value, 'dd MMM, yyyy') }, void 0) }), void 0));
                },
            },
            {
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return _jsx(PromotionStatusBadge, { value: cell.value }, void 0);
                },
                accessor: 'status',
            },
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Link, __assign({ to: router.getLink('promotion', { id: row.original._id }) }, { children: _jsx(Button, __assign({ size: "sm", variant: "outline" }, { children: "View" }), void 0) }), void 0), _jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () {
                                            setSelectedPromotion(row.original);
                                            setEditMode(true);
                                        } }, { children: "Edit" }), void 0), !!selectedPromotion && (_jsx(PromotionForm, { isOpen: editMode, action: "update", onCancel: function () {
                                            setSelectedPromotion(null);
                                            setEditMode(false);
                                        }, onSuccess: function () {
                                            refetchPromotions();
                                            setSelectedPromotion(null);
                                            setEditMode(false);
                                        }, promotion: selectedPromotion }, void 0))] }, void 0)] }), void 0));
                },
            },
        ];
        return columnData;
    }, [editMode, selectedPromotion, refetchPromotions, router]);
    return (_jsxs(_Fragment, { children: [!((_b = props.promotions) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(ContentLoader, { errorSummary: "No promotions found", contentUnavailable: true }, void 0)), !!((_c = props.promotions) === null || _c === void 0 ? void 0 : _c.length) && (_jsx(TableMaker, { route: { id: 'promotions' }, tableProps: { isFit: true, mb: 20 }, columns: columns, data: props.promotions }, void 0))] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Tooltip, Box, SimpleGrid, Checkbox } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu';
import { FiDownload } from 'react-icons/fi';
import xlsx from 'xlsx';
import { exportToCsv } from '@obtainly/utils';
import { useExporter } from './useExporter';
export var Exporter = function (props) {
    var label = props.label, filename = props.filename, tableRef = props.tableRef, data = props.data, columns = props.columns, isDisabled = props.isDisabled;
    if (!data && !tableRef)
        throw new Error('Export data or table ref required');
    var exportable = useExporter(data, columns);
    var exportToExcel = function () {
        var ws = (exportable === null || exportable === void 0 ? void 0 : exportable.data)
            ? xlsx.utils.json_to_sheet(exportable.data)
            : xlsx.utils.table_to_sheet(tableRef.current);
        // const wb: xlsx.WorkBook = xlsx.utils.book_new()
        // xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')
        // xlsx.writeFile(csv, `${filename}.xlsx`)
        var csv = xlsx.utils.sheet_to_csv(ws);
        exportToCsv(csv, filename);
    };
    var buttonProps = {
        size: 'sm',
        variant: 'outline',
        leftIcon: _jsx(FiDownload, { style: { position: 'relative', top: '1px' } }, void 0),
        isDisabled: isDisabled || (!tableRef && !(exportable === null || exportable === void 0 ? void 0 : exportable.data)),
        display: ['none', 'block'],
    };
    return (_jsx(_Fragment, { children: (columns === null || columns === void 0 ? void 0 : columns.length) ? (_jsxs(Menu, { children: [_jsx(Tooltip, __assign({ label: "Export to CSV" }, { children: _jsx(MenuButton, __assign({ as: Button }, buttonProps, { children: label || 'Export' }), void 0) }), void 0), _jsx(MenuList, __assign({ zIndex: 90 }, { children: _jsxs(Box, __assign({ my: "15px", mx: "20px" }, { children: [_jsx(SimpleGrid, __assign({ columns: 2, spacing: 2 }, { children: columns.map(function (column, index) { return (_jsx(Checkbox, __assign({ isChecked: column.selected, onChange: function () {
                                        exportable.toggleColumn(column.label);
                                    } }, { children: column.label }), index)); }) }), void 0), _jsx(Button, __assign({}, buttonProps, { bg: "primary.50", colorScheme: "primary", mt: 5, ml: 0, onClick: exportToExcel, isFullWidth: true }, { children: "Export Selected Columns" }), void 0)] }), void 0) }), void 0)] }, void 0)) : (_jsx(Tooltip, __assign({ label: "Download as Excel" }, { children: _jsx(Button, __assign({}, buttonProps, { onClick: exportToExcel }, { children: label || 'Export' }), void 0) }), void 0)) }, void 0));
};

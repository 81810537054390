import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useQueryModel } from '@obtainly/http';
import { LedgerHead } from '@obtainly/models';
import { useAtom, distributorAtom } from 'src/core/store';
import { LedgerLogs } from 'src/ledger';
export var MerchantLedgerStatement = function (props) {
    var merchantId = props.merchantId;
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('ledger.read_by_owner_specific', {
        owner: merchantId,
        head: 'statements',
        subhead: "statements_".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id),
    }, { enabled: !!merchantId && !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) }), ledgers = _a.data, isLoading = _a.isLoading;
    var ledger = React.useMemo(function () {
        return ((ledgers === null || ledgers === void 0 ? void 0 : ledgers[0]) ||
            {
                head: LedgerHead.Statements,
                subhead: "statements_".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id),
                owner: merchantId || '',
            });
    }, [ledgers, merchantId, distributor === null || distributor === void 0 ? void 0 : distributor._id]);
    return (_jsx(LedgerLogs, { ledger: ledger, heading: "Statement", isLoading: isLoading }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OrderTag, OrderType } from '@obtainly/models';
import { usePermission } from 'src/core/hooks';
import { useOrderUpdateTag } from './useOrderUpdateTag';
export function useOrderStateMachine(order, refetchOrder) {
    var _a;
    var userCan = usePermission().userCan;
    var _b = useOrderUpdateTag(order, refetchOrder), updateOrderTag = _b.updateOrderTag, isLoading = _b.isLoading;
    var sharedButtonProps = { isLoading: isLoading, isDisabled: isLoading };
    var defaultOrderStates = (_a = {},
        _a[OrderTag.Draft] = function () {
            return {
                nextStates: [this[OrderTag.Pending](), this[OrderTag.Cancelled]()],
            };
        },
        _a[OrderTag.Pending] = function () {
            return {
                title: 'Place Order',
                triggerButtonProps: __assign({ colorScheme: 'success', onClick: function () { return updateOrderTag(OrderTag.Pending); } }, sharedButtonProps),
                userCan: order && (order === null || order === void 0 ? void 0 : order.amount) && userCan('create_order') ? true : false,
                nextStates: [this[OrderTag.Processing](), this[OrderTag.Cancelled]()],
            };
        },
        _a[OrderTag.Processing] = function () {
            return {
                title: 'Approve Order',
                triggerButtonProps: __assign({ colorScheme: 'success', onClick: function () { return updateOrderTag(OrderTag.Processing); } }, sharedButtonProps),
                triggerConfirmation: 'Are you sure you want to approve this order?',
                userCan: userCan('process_order'),
                nextStates: [
                    this[OrderTag.InTransit](),
                    this[OrderTag.Modify](),
                    this[OrderTag.Cancelled](),
                ],
            };
        },
        _a[OrderTag.Modify] = function () {
            return {
                title: 'Modify Order',
                triggerButtonProps: __assign({ colorScheme: 'warning', onClick: function () { return updateOrderTag(OrderTag.Pending); } }, sharedButtonProps),
                userCan: userCan('ship_order'),
                nextStates: [],
            };
        },
        _a[OrderTag.InTransit] = function () {
            return {
                title: (order === null || order === void 0 ? void 0 : order.type) === OrderType.Pickup ? 'Ready for pickup' : 'Ship Order',
                triggerButtonProps: __assign({ colorScheme: 'success', onClick: function () { return updateOrderTag(OrderTag.InTransit); } }, sharedButtonProps),
                userCan: userCan('ship_order'),
                nextStates: [this[OrderTag.Delivered](), this[OrderTag.Cancelled]()],
            };
        },
        _a[OrderTag.Delivered] = function () {
            return {
                title: 'Confirm Delivery',
                triggerButtonProps: __assign({ colorScheme: 'success', onClick: function () { return updateOrderTag(OrderTag.Delivered); } }, sharedButtonProps),
                userCan: userCan('complete_order'),
                nextStates: [],
            };
        },
        _a[OrderTag.Cancelled] = function () {
            return {
                title: 'Cancel',
                triggerButtonProps: __assign({ colorScheme: 'critical', onClick: function () { return updateOrderTag(OrderTag.Cancelled); } }, sharedButtonProps),
                triggerConfirmation: 'Are you sure you want to cancel this order?',
                userCan: userCan('cancel_order'),
                nextStates: [],
            };
        },
        _a);
    var orderStatesByType = {
        delivery: __assign({}, defaultOrderStates),
        pickup: __assign({}, defaultOrderStates),
    }; // eslint-disable-line
    return (order === null || order === void 0 ? void 0 : order.type) ? orderStatesByType[order.type] : null;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { VStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from 'react-query';
import { useMutateModel } from '@obtainly/http';
import { FormikField, FormGroup, FormikRadioGroup, FormikFieldErrorMessage, } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { distributorAtom, useAtom } from 'src/core/store';
import { LocationAutocomplete } from 'src/location';
import { lang } from 'src/core/config';
var distributorLocationFormSchema = Yup.object({
    status: Yup.string().required('Status is required.'),
    name: Yup.string()
        .required("".concat(lang.distributorLocation, " name is required."))
        .min(3, "".concat(lang.distributorLocation, " name must be at least 3 characters.")),
    address: Yup.string()
        .required('Shipping address is required.')
        .min(3, 'Shipping address must be at least 3 characters.'),
    location_id: Yup.string().required('Location is required.'),
    distributor_id: Yup.string().required('Distributor Id is required.'),
});
export var DistributorLocationForm = function (props) {
    var toast = useToast().toast;
    var distributor = useAtom(distributorAtom)[0];
    var queryClient = useQueryClient();
    var action = props.action, distributorLocation = props.distributorLocation;
    var createDistributorLocation = useMutateModel('distributor_location.create');
    var updateDistributorLocation = useMutateModel('distributor_location.update');
    var initialValues = {
        status: (distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.status) || 'active',
        name: (distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.name) || '',
        address: (distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.address) || '',
        location_id: (distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.location_id) || '',
        distributor_id: (distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation.distributor_id) || (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
    };
    var refreshData = function () {
        var _a;
        (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
        queryClient.refetchQueries("distributor_location_read_by_distributor_".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id));
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createDistributorLocation.mutate(__assign({}, values), {
                onSuccess: function () {
                    toast({
                        status: 'success',
                        description: "".concat(lang.distributorLocation, " created"),
                    });
                    refreshData();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateDistributorLocation.mutate(__assign(__assign({}, values), { _id: distributorLocation === null || distributorLocation === void 0 ? void 0 : distributorLocation._id }), {
                onSuccess: function () {
                    toast({
                        status: 'success',
                        description: "".concat(lang.distributorLocation, " saved"),
                    });
                    refreshData();
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: distributorLocationFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " ", props.label] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'active', label: 'Active' },
                                                        { value: 'inactive', label: 'Inactive' },
                                                    ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: lang.distributorLocation + ' name' }, { children: _jsx(FormikField, { size: "sm", name: "name", placeholder: "name" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Shipping address" }, { children: _jsx(FormikField, { as: "textarea", size: "sm", name: "address", placeholder: "shipping address" }, void 0) }), void 0), _jsxs(FormGroup, __assign({ label: "Location" }, { children: [_jsx(LocationAutocomplete, { value: formik.values.location_id, onChange: function (_a) {
                                                            var value = _a.value;
                                                            formik.setFieldValue('location_id', value);
                                                        }, distributorId: distributor === null || distributor === void 0 ? void 0 : distributor._id, disableSelectAll: true }, void 0), _jsx(FormikFieldErrorMessage, { name: "location_id" }, void 0)] }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createDistributorLocation.isLoading ||
                                                updateDistributorLocation.isLoading, isDisabled: createDistributorLocation.isLoading ||
                                                updateDistributorLocation.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " ", props.label] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
DistributorLocationForm.defaultProps = {
    action: 'create',
    label: lang.distributorLocation,
};

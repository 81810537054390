var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/button';
import { VStack } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/modal';
import { useMutateModel } from '@obtainly/http';
import { Form, Formik } from 'formik';
import { FormGroup, FormikField, FormikFieldErrorMessage, FormikRadioGroup, GeolocationAutocomplete, } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { distributorAtom, useAtom, userAtom } from 'src/core/store';
import * as Yup from 'yup';
var locationFormSchema = Yup.object({
    name: Yup.string()
        .required('Location name is required.')
        .min(3, 'Location name must be at least 3 characters.'),
    state: Yup.string().required('State is required.'),
    lga: Yup.string().required('LGA is required.'),
    distributor_id: Yup.string().required('Distributor id is required.'),
});
export var LocationForm = function (props) {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var distributor = useAtom(distributorAtom)[0];
    var action = props.action, location = props.location;
    var createLocation = useMutateModel('location.create');
    var updateLocation = useMutateModel('location.update');
    var initialValues = {
        name: (location === null || location === void 0 ? void 0 : location.name) || '',
        state: (location === null || location === void 0 ? void 0 : location.state) || '',
        lga: (location === null || location === void 0 ? void 0 : location.lga) || '',
        status: (location === null || location === void 0 ? void 0 : location.status) || 'active',
        distributor_id: (location === null || location === void 0 ? void 0 : location.distributor_id) || (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '',
    };
    var onSubmit = function (values) {
        if (action === 'create') {
            createLocation.mutate(__assign(__assign({}, values), { distributor_id: user === null || user === void 0 ? void 0 : user.distributor_id, hash: 'football' }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Location created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateLocation.mutate(__assign(__assign({}, values), { _id: location === null || location === void 0 ? void 0 : location._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Location saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: locationFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var _b;
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Location"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsx(ModalBody, { children: _jsxs(VStack, __assign({ spacing: 5, alignItems: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                        { value: 'active', label: 'Active' },
                                                        { value: 'inactive', label: 'Inactive' },
                                                    ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Name of location" }, { children: _jsx(FormikField, { size: "sm", name: "name", placeholder: "Kilimanjaro" }, void 0) }), void 0), _jsxs(FormGroup, __assign({ label: "State" }, { children: [_jsx(GeolocationAutocomplete, { value: formik.values.state, onChange: function (_a) {
                                                            var value = _a.value;
                                                            formik.setFieldValue('lga', '', true);
                                                            formik.setFieldValue('state', value);
                                                        }, disableSelectAll: true }, void 0), _jsx(FormikFieldErrorMessage, { name: "state" }, void 0)] }), void 0), _jsxs(FormGroup, __assign({ label: "LGA" }, { children: [_jsx(GeolocationAutocomplete, { isDisabled: !formik.values.state, activeState: formik.values.state, value: (_b = formik.values.lga) !== null && _b !== void 0 ? _b : { label: 'XYZ', value: '' }, onChange: function (_a) {
                                                            var value = _a.value;
                                                            return formik.setFieldValue('lga', value);
                                                        }, disableSelectAll: true }, void 0), _jsx(FormikFieldErrorMessage, { name: "lga" }, void 0)] }), void 0)] }), void 0) }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createLocation.isLoading || updateLocation.isLoading, isDisabled: createLocation.isLoading ||
                                                updateLocation.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Location"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
LocationForm.defaultProps = {
    action: 'create',
};

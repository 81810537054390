var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Badge } from '@chakra-ui/layout';
import { ContentLoader, TableMaker } from 'src/core/components';
import { MerchantOfficerForm } from './MerchantOfficerForm';
export var MerchantOfficersTable = function (_a) {
    var merchantOfficers = _a.merchantOfficers, refetchMerchantOfficers = _a.refetchMerchantOfficers;
    var _b = React.useState(), selectedOfficer = _b[0], setSelectedOfficer = _b[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'active' ? 'success' : 'orange' }, { children: cell.value }), void 0));
                },
            },
            { accessor: 'firstname', Header: 'First Name' },
            { accessor: 'lastname', Header: 'Last Name' },
            { accessor: 'phone', Header: 'Phone' },
            { accessor: 'email', Header: 'Email' },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ textTransform: "lowercase" }, { children: cell.value }), void 0));
                },
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () { return setSelectedOfficer(row.original); } }, { children: "Edit" }), void 0));
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [!(merchantOfficers === null || merchantOfficers === void 0 ? void 0 : merchantOfficers.length) && (_jsx(ContentLoader, { errorSummary: "No customers found", contentUnavailable: true }, void 0)), !!(merchantOfficers === null || merchantOfficers === void 0 ? void 0 : merchantOfficers.length) && (_jsx(TableMaker, { columns: columns, data: merchantOfficers, tableProps: { isFit: true, mb: 20 } }, void 0)), _jsx(MerchantOfficerForm, { action: "update", merchantId: (selectedOfficer === null || selectedOfficer === void 0 ? void 0 : selectedOfficer.merchant_id) || '', merchantOfficer: selectedOfficer, isOpen: !!selectedOfficer, onCancel: function () { return setSelectedOfficer(undefined); }, onSuccess: function () {
                    refetchMerchantOfficers();
                    setSelectedOfficer(undefined);
                } }, void 0)] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useQueryModel } from '@obtainly/http';
import { MerchantTag, OrderType, } from '@obtainly/models';
import { Card, CardBody, CardHeader } from '@obtainly/ui/web-shared';
import { distributorAtom, useAtom } from 'src/core/store';
import { MerchantTagForm } from './MerchantTagForm';
export var MerchantTags = function (_a) {
    var merchant = _a.merchant;
    var distributor = useAtom(distributorAtom)[0];
    //changed distributor id to distributor._id
    var prices = useQueryModel('price.read_by_distributor', { distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id }).data;
    var priceClassOptions = useMemo(function () {
        if (prices === null || prices === void 0 ? void 0 : prices.length) {
            return prices.map(function (price) {
                return {
                    label: price.ref,
                    value: "".concat(price._id),
                };
            });
        }
        return [];
    }, [prices]);
    var allowedOrderTypeOptions = [
        {
            label: 'Delivery',
            value: OrderType.Delivery,
        },
        {
            label: 'Pickup',
            value: OrderType.Pickup,
        },
        {
            label: 'Both',
            value: OrderType.Both,
        },
    ];
    if (!merchant._id)
        return null;
    return (_jsx(_Fragment, { children: _jsxs(Card, { children: [_jsx(CardHeader, { heading: "Customer Tags" }, void 0), _jsx(CardBody, { children: _jsxs(VStack, __assign({ spacing: 3, alignItems: "stretch" }, { children: [_jsx(MerchantTagForm, { label: "Allowed Order type", tag: MerchantTag.ALLOWED_ORDER_TYPE, merchantId: merchant._id, options: allowedOrderTypeOptions }, void 0), _jsx(MerchantTagForm, { label: "Price class", tag: MerchantTag.PRICE_CLASS, merchantId: merchant._id, options: priceClassOptions }, void 0)] }), void 0) }, void 0)] }, void 0) }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, VStack } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import { formatCurrency } from '@obtainly/utils';
import React from 'react';
import { Card, CardBody, CardHeader } from 'src/core/components';
import { distributorAtom, useAtom } from 'src/core/store';
export var MerchantDetails = function (_a) {
    var merchant = _a.merchant;
    var sizes = useTheme().sizes;
    var distributor = useAtom(distributorAtom)[0];
    var details = React.useMemo(function () {
        var _a;
        var ledgerTag = Object.entries((merchant === null || merchant === void 0 ? void 0 : merchant.ledger_tags) || {}).filter(function (_a) {
            var _b;
            var key = _a[0];
            var tag_id = ((_b = String(key)) === null || _b === void 0 ? void 0 : _b.split('|'))[0];
            return tag_id === 'virtual_account';
        })[0][1];
        var virtualAccount = JSON.parse((ledgerTag === null || ledgerTag === void 0 ? void 0 : ledgerTag.value) || '{}');
        var ledgers = merchant === null || merchant === void 0 ? void 0 : merchant.ledgers;
        var mainLedger = ledgers["".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)];
        var statementLedger = ledgers["statements_".concat(distributor === null || distributor === void 0 ? void 0 : distributor._id)];
        return [
            { label: 'Customer phone', value: merchant.phone },
            {
                label: 'Customer name',
                value: "".concat(merchant.firstname, " ").concat(merchant.lastname),
            },
            { label: 'Business name', value: merchant.business_name },
            {
                label: 'Business address',
                value: merchant.address,
            },
            {
                label: 'Business email',
                value: merchant.email,
            },
            {
                label: 'Bank Name',
                value: ((_a = virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.bank) === null || _a === void 0 ? void 0 : _a.name) || '-',
            },
            {
                label: 'Bank Account Name',
                value: (virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_name) || '-',
            },
            {
                label: 'Bank Account Number',
                value: (virtualAccount === null || virtualAccount === void 0 ? void 0 : virtualAccount.account_number) || '-',
            },
            {
                label: 'Total Payment',
                value: formatCurrency(mainLedger === null || mainLedger === void 0 ? void 0 : mainLedger.balance, { showCurrency: true }),
                color: (mainLedger === null || mainLedger === void 0 ? void 0 : mainLedger.balance) > 0 ? 'success.600' : 'critical.600',
            },
            {
                label: 'Statment Balance',
                value: formatCurrency((statementLedger === null || statementLedger === void 0 ? void 0 : statementLedger.balance) || 0, {
                    showCurrency: true,
                }),
                color: (statementLedger === null || statementLedger === void 0 ? void 0 : statementLedger.balance) > 0 ? 'success.600' : 'critical.600',
            },
        ];
    }, [distributor, merchant]);
    return (_jsxs(Card, __assign({ position: "sticky", top: sizes.layout.stickyContentTop }, { children: [_jsx(CardHeader, { heading: "Customer Details" }, void 0), _jsx(CardBody, __assign({ pt: 0 }, { children: _jsx(VStack, __assign({ mt: 3, spacing: 3, alignItems: "stretch" }, { children: details.map(function (detail) { return (_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: detail.label }), void 0), _jsx(Text, __assign({ color: detail.color }, { children: detail.value }), void 0)] }, detail.label)); }) }), void 0) }), void 0)] }), void 0));
};

import { Login, Signup, Settings } from './pages';
export var userRoutes = [
    {
        id: 'settings',
        path: '/settings',
        component: Settings,
        requiresAuth: true,
        exact: true,
    },
    {
        id: 'login',
        path: '/login',
        component: Login,
        requiresAuth: false,
        exact: true,
    },
    {
        id: 'signup',
        path: '/hidden-signup',
        component: Signup,
        requiresAuth: false,
        exact: true,
    },
];
export * from './components';

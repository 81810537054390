var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex, Grid, GridItem } from '@chakra-ui/layout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { FormikField, FormGroup } from 'src/core/components';
import { useToast } from 'src/core/hooks';
var formSchema = Yup.object({
    name: Yup.string().required(' '),
    quantity: Yup.number().positive(' ').required(' '),
    rate: Yup.number().positive(' ').required(' '),
});
export var OrderItemForm = function (props) {
    var toast = useToast().toast;
    var orderId = props.orderId, action = props.action, orderItem = props.orderItem;
    var upsertOrderItem = useMutateModel('order_item.upsert');
    var removeOrderItem = useMutateModel('order_item.remove');
    var initialValues = {
        name: (orderItem === null || orderItem === void 0 ? void 0 : orderItem.name) || '',
        quantity: (orderItem === null || orderItem === void 0 ? void 0 : orderItem.quantity) || 0,
        rate: orderItem ? (orderItem === null || orderItem === void 0 ? void 0 : orderItem.amount) / (orderItem === null || orderItem === void 0 ? void 0 : orderItem.quantity) : 0,
    };
    var handleSubmit = function (values) {
        upsertOrderItem.mutate({
            _id: orderItem === null || orderItem === void 0 ? void 0 : orderItem._id,
            order_id: orderId,
            name: values.name,
            quantity: values.quantity,
            amount: values.quantity * values.rate,
            product_id: 'null',
        }, {
            onSuccess: function () {
                var _a;
                toast({ status: 'success', description: 'Order updated' });
                (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    };
    var handleRemove = function () {
        removeOrderItem.mutate({
            _id: orderItem === null || orderItem === void 0 ? void 0 : orderItem._id,
        }, {
            onSuccess: function () {
                var _a;
                toast({ status: 'success', description: 'Order updated' });
                (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
            },
            onError: function (_a) {
                var message = _a.message;
                toast({ status: 'error', description: message });
            },
        });
    };
    var isLoading = upsertOrderItem.isLoading || removeOrderItem.isLoading;
    return (_jsx(Formik, __assign({ initialValues: initialValues, validationSchema: formSchema, onSubmit: handleSubmit }, { children: function (_a) {
            var formik = __rest(_a, []);
            return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(Grid, __assign({ templateColumns: ['1fr 2fr', '4fr 1fr 2fr'], gridColumnGap: "10px", gridRowGap: "5px" }, { children: [_jsx(GridItem, __assign({ colSpan: [2, 1] }, { children: _jsx(FormGroup, __assign({ label: "Item" }, { children: _jsx(FormikField, { size: "sm", name: "name", placeholder: "Add item", isDisabled: action === 'update' }, void 0) }), void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Quantity" }, { children: _jsx(FormikField, { size: "sm", name: "quantity", type: "number" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Unit Price" }, { children: _jsx(FormikField, { size: "sm", type: "number", prepend: "NGN", name: "rate" }, void 0) }), void 0)] }), void 0), _jsxs(Flex, __assign({ justifyContent: "space-between", gridGap: "10px" }, { children: [action === 'update' ? (_jsx(Flex, { children: _jsx(Button, __assign({ size: "sm", variant: "ghost", colorScheme: "critical", onClick: handleRemove, isLoading: isLoading, isDisabled: isLoading }, { children: "Remove" }), void 0) }, void 0)) : (_jsx(Flex, {}, void 0)), _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", isDisabled: isLoading, onClick: function () { var _a; return (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props); } }, { children: "Cancel" }), void 0), _jsx(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: isLoading, isDisabled: isLoading || !formik.dirty || !formik.isValid }, { children: action === 'create' ? 'Add Item' : 'Save' }), void 0)] }), void 0)] }), void 0)] }), void 0));
        } }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Badge, Text } from '@chakra-ui/layout';
import { ContentLoader, ImageBox, TableMaker } from 'src/core/components';
import { asset, formatUnixTime } from '@obtainly/utils';
import { FeedForm } from './FeedForm';
import { formatDistanceToNow, fromUnixTime, getUnixTime } from 'date-fns';
import { Tooltip } from '@chakra-ui/tooltip';
export var FeedsTable = function (props) {
    var _a, _b;
    var _c = React.useState(), selected = _c[0], setSelected = _c[1];
    var columns = React.useMemo(function () {
        return [
            { Header: '#', Cell: function (_a) {
                    var row = _a.row;
                    return Number(row.id) + 1;
                } },
            {
                Header: '---',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(ImageBox, { src: cell.value ? asset(cell.value) : undefined }, void 0));
                },
                accessor: 'upload.path',
            },
            {
                Header: 'Status',
                Cell: function (_a) {
                    var cell = _a.cell;
                    return (_jsx(Badge, __assign({ colorScheme: cell.value === 'active' ? 'success' : 'critical' }, { children: cell.value }), void 0));
                },
                accessor: 'status',
            },
            { Header: 'Title', accessor: 'title' },
            { Header: 'Description', accessor: 'description' },
            { Header: 'Tag', accessor: 'tag' },
            {
                Header: 'Expires',
                accessor: 'ttl',
                Cell: function (_a) {
                    var _b;
                    var cell = _a.cell, row = _a.row;
                    return (_jsx(ExpiryDateText, { ttl: cell === null || cell === void 0 ? void 0 : cell.value, created: (_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.created }, void 0));
                },
            },
            {
                Header: 'Actions',
                Cell: function (_a) {
                    var row = _a.row;
                    return (_jsx(Button, __assign({ size: "sm", variant: "outline", onClick: function () {
                            setSelected(row.original);
                        } }, { children: "Edit" }), void 0));
                },
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [!((_a = props.feeds) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(ContentLoader, { errorSummary: "No feeds found", contentUnavailable: true }, void 0)), !!((_b = props.feeds) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(TableMaker, { route: { id: 'feeds' }, tableProps: { isFit: true, mb: 20 }, columns: columns, data: props.feeds }, void 0)), _jsx(FeedForm, { action: "update", feed: selected, isOpen: !!selected, onCancel: function () { return setSelected(null); }, onSuccess: function () {
                    props.refetchFeeds();
                    setSelected(null);
                } }, void 0)] }, void 0));
};
var ExpiryDateText = function (_a) {
    var ttl = _a.ttl, created = _a.created;
    var _now = getUnixTime(new Date());
    var _exp = ttl + created;
    var isValid = _now < _exp || ttl === 0;
    return (_jsx(Tooltip, __assign({ label: "".concat(isValid ? formatDistanceToNow(fromUnixTime(_exp)) : 'Expired') }, { children: _jsx(Text, __assign({ color: isValid ? 'success.600' : 'critical.600' }, { children: ttl === 0 ? 'Never' : formatUnixTime(_exp, 'dd MMM yyyy') }), void 0) }), void 0));
};

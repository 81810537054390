var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/modal';
import { VStack } from '@chakra-ui/layout';
import { getUnixTime, endOfToday } from 'date-fns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutateModel } from '@obtainly/http';
import { FeedTag } from '@obtainly/models';
import { FormikField, FormGroup, FormikRadioGroup, DateInput, ImageUploader, } from 'src/core/components';
import { useToast } from 'src/core/hooks';
import { useAtom, userAtom, configAtom } from 'src/core/store';
var DurationType;
(function (DurationType) {
    DurationType["Definite"] = "definite";
    DurationType["Indefinite"] = "indefinite";
})(DurationType || (DurationType = {}));
var feedFormSchema = Yup.object({
    title: Yup.string()
        .required('Communication title is required.')
        .min(3, 'Communication title must be at least 3 characters.'),
    description: Yup.string()
        .required('Communication description is required.')
        .min(10, 'Communication description must be at least 10 characters.'),
    status: Yup.string().required('Communication status is required.'),
    tag: Yup.string().required('Communication tag is required.'),
    ttl: Yup.number().when('duration', {
        is: DurationType.Definite,
        then: Yup.number()
            .notOneOf([0], 'Select expiry date.')
            .required('Communication validity period is required.'),
    }),
    distributor_id: Yup.string().required('Distributor ID field is required.'),
});
export var FeedForm = function (props) {
    var toast = useToast().toast;
    var user = useAtom(userAtom)[0];
    var defaultUploadId = useAtom(configAtom)[0].defaultUploadId;
    var action = props.action, feed = props.feed;
    var dateRef = (feed === null || feed === void 0 ? void 0 : feed.created) || getUnixTime(new Date());
    var createFeed = useMutateModel('feed.create');
    var updateFeed = useMutateModel('feed.update');
    var uploadFile = useMutateModel('upload.upload');
    var initialValues = {
        title: (feed === null || feed === void 0 ? void 0 : feed.title) || '',
        description: (feed === null || feed === void 0 ? void 0 : feed.description) || '',
        ttl: (feed === null || feed === void 0 ? void 0 : feed.ttl) || 0,
        tag: (feed === null || feed === void 0 ? void 0 : feed.tag) || FeedTag.Promotion,
        status: (feed === null || feed === void 0 ? void 0 : feed.status) || 'active',
        upload_id: (feed === null || feed === void 0 ? void 0 : feed.upload_id) || defaultUploadId,
        distributor_id: (user === null || user === void 0 ? void 0 : user.distributor_id) || '',
        duration: (feed === null || feed === void 0 ? void 0 : feed.ttl)
            ? DurationType.Definite
            : (feed === null || feed === void 0 ? void 0 : feed.ttl) === 0
                ? DurationType.Indefinite
                : '',
    };
    var onSubmit = function (values) {
        var mutableValues = Object.assign({}, values);
        mutableValues === null || mutableValues === void 0 ? true : delete mutableValues.cases;
        mutableValues === null || mutableValues === void 0 ? true : delete mutableValues.duration;
        if (values.duration === DurationType.Indefinite) {
            mutableValues.ttl = 0;
        }
        if (action === 'create') {
            createFeed.mutate(mutableValues, {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Feed created' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
        else {
            updateFeed.mutate(__assign(__assign({}, mutableValues), { _id: feed === null || feed === void 0 ? void 0 : feed._id }), {
                onSuccess: function () {
                    var _a;
                    toast({ status: 'success', description: 'Feed saved' });
                    (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
                },
                onError: function (_a) {
                    var message = _a.message;
                    toast({ status: 'error', description: message });
                },
            });
        }
    };
    var onUpload = function (file, values) {
        var mutableValues = Object.assign({}, values);
        mutableValues === null || mutableValues === void 0 ? true : delete mutableValues.cases;
        mutableValues === null || mutableValues === void 0 ? true : delete mutableValues.duration;
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('overwrite', 'true');
            formData.append('tag', 'feed');
            formData.append('title', "".concat((feed === null || feed === void 0 ? void 0 : feed._id) || mutableValues.title));
            uploadFile.mutate(formData, {
                onSuccess: function (data) {
                    updateFeed.mutate(__assign(__assign({}, mutableValues), { upload_id: data, _id: feed === null || feed === void 0 ? void 0 : feed._id }), {
                        onSuccess: function () {
                            toast({ status: 'success', description: 'Image uploaded' });
                            resolve();
                        },
                        onError: function (_a) {
                            var message = _a.message;
                            toast({ status: 'error', description: message });
                            reject();
                        },
                    });
                },
                onError: function (error) {
                    toast({ status: 'error', description: error.message });
                    reject(error.message);
                },
            });
        });
    };
    return (_jsxs(Modal, __assign({ isOpen: props.isOpen, onClose: props.onCancel }, { children: [_jsx(ModalOverlay, {}, void 0), _jsx(ModalContent, { children: _jsx(Formik, __assign({ initialValues: initialValues, validationSchema: feedFormSchema, onSubmit: onSubmit, enableReinitialize: true, validateOnMount: true }, { children: function (_a) {
                        var _b, _c, _d;
                        var formik = __rest(_a, []);
                        return (_jsxs(Form, __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs(ModalHeader, { children: [action === 'create' ? 'Add' : 'Update', " Communication"] }, void 0), _jsx(ModalCloseButton, {}, void 0), _jsxs(ModalBody, { children: [action === 'update' && (_jsx(Box, __assign({ mb: 4 }, { children: _jsx(ImageUploader, { uploadPath: (_b = feed === null || feed === void 0 ? void 0 : feed.upload) === null || _b === void 0 ? void 0 : _b.path, onUpload: function (_a) {
                                                    var file = _a.file;
                                                    return onUpload(file, __assign(__assign({}, formik.values), { upload_id: file._id }));
                                                } }, void 0) }), void 0)), _jsxs(VStack, __assign({ spacing: 5, align: "stretch" }, { children: [action === 'update' && (_jsx(FormGroup, __assign({ label: "Status" }, { children: _jsx(FormikRadioGroup, { name: "status", options: [
                                                            { value: 'active', label: 'Active' },
                                                            { value: 'inactive', label: 'Inactive' },
                                                        ] }, void 0) }), void 0)), _jsx(FormGroup, __assign({ label: "Title" }, { children: _jsx(FormikField, { size: "sm", name: "title", placeholder: "Communication Title" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Description" }, { children: _jsx(FormikField, { size: "sm", as: "textarea", name: "description", placeholder: "Communication description" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Tag" }, { children: _jsx(FormikField, { as: "autocomplete", size: "sm", name: "tag", onChange: function (_a) {
                                                            var value = _a.value;
                                                            formik.setFieldValue('tag', value);
                                                        }, options: Object.entries(FeedTag).map(function (_a) {
                                                            var key = _a[0], value = _a[1];
                                                            return { label: key, value: value };
                                                        }), placeholder: "eg. PROMO" }, void 0) }), void 0), _jsx(FormGroup, __assign({ label: "Duration" }, { children: _jsx(FormikRadioGroup, { name: "duration", options: Object.entries(DurationType).map(function (_a) {
                                                            var key = _a[0], value = _a[1];
                                                            return {
                                                                value: value,
                                                                label: key,
                                                            };
                                                        }) }, void 0) }), void 0), formik.values.duration === DurationType.Definite && (_jsxs(FormGroup, __assign({ label: "Expiry Date" }, { children: [_jsx(DateInput, { value: formik.values.ttl === 0
                                                                ? 0
                                                                : formik.values.ttl + dateRef, onChange: function (date) {
                                                                var ttl = date === dateRef
                                                                    ? getUnixTime(endOfToday()) - date
                                                                    : date - dateRef;
                                                                formik.setFieldValue('ttl', ttl);
                                                            }, allowFutureDate: true }, void 0), !!((_c = formik === null || formik === void 0 ? void 0 : formik.errors) === null || _c === void 0 ? void 0 : _c.ttl) && (_jsx(Text, __assign({ fontSize: "sm", color: "red.600", mt: 1 }, { children: (_d = formik === null || formik === void 0 ? void 0 : formik.errors) === null || _d === void 0 ? void 0 : _d.ttl }), void 0))] }), void 0))] }), void 0)] }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ size: "sm", variant: "outline", mr: 3, onClick: props.onCancel }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ size: "sm", type: "submit", colorScheme: "primary", isLoading: createFeed.isLoading || updateFeed.isLoading, isDisabled: createFeed.isLoading ||
                                                updateFeed.isLoading ||
                                                !formik.dirty ||
                                                !formik.isValid }, { children: [action === 'create' ? 'Add' : 'Update', " Communication"] }), void 0)] }, void 0)] }), void 0));
                    } }), void 0) }, void 0)] }), void 0));
};
FeedForm.defaultProps = {
    action: 'create',
};

import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useOrderItem } from '../hooks';
import { OrderContext } from '../pages/Order';
import { OrderItemContainer } from './OrderItemContainer';
export var OrderProduct = function (props) {
    var _a = React.useContext(OrderContext), order = _a.order, refreshOrder = _a.refreshOrder;
    var product = props.product, orderItem = props.orderItem;
    var _b = useOrderItem({
        order: order,
        product: product,
        orderItem: orderItem,
        refreshOrder: refreshOrder,
    }), quantity = _b.quantity, setQuantity = _b.setQuantity, rate = _b.rate, setRate = _b.setRate, updateItem = _b.updateItem, isUpdating = _b.isUpdating, isRemoving = _b.isRemoving;
    return (_jsx(OrderItemContainer, { type: "product", product: product, orderItemLogic: {
            rate: rate,
            setRate: setRate,
            quantity: quantity,
            setQuantity: setQuantity,
            isUpdating: isUpdating,
            isRemoving: isRemoving,
            updateItem: updateItem,
        }, freeItem: orderItem === null || orderItem === void 0 ? void 0 : orderItem.free_item, bg: quantity > 0 ? 'gray.50' : 'white', borderColor: quantity > 0 ? 'gray.100' : 'gray.100' }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Text, VStack, HStack, Divider } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { useTheme } from '@chakra-ui/system';
import { useQueryModel } from '@obtainly/http';
import { Card, CardHeader, CardBody, ContentLoader } from 'src/core/components';
import { useAtom, distributorAtom } from 'src/core/store';
import { MerchantLocationForm } from './';
import { FiPlus } from 'react-icons/fi';
import { Flex } from '@chakra-ui/react';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
export var MerchantLocations = function (_a) {
    var merchantId = _a.merchantId;
    var sizes = useTheme().sizes;
    var distributor = useAtom(distributorAtom)[0];
    var _b = React.useState(false), editMode = _b[0], setEditMode = _b[1];
    var _c = React.useState(), activeLocation = _c[0], setActiveLocation = _c[1];
    var _d = useQueryModel('merchant_location.read_by_distributor_merchant', {
        merchant_id: merchantId,
        distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
    }, {
        enabled: !!merchantId && !!(distributor === null || distributor === void 0 ? void 0 : distributor._id),
    }), locations = _d.data, isLoading = _d.isLoading, refetch = _d.refetch;
    return (_jsxs(Card, __assign({ position: "sticky", top: sizes.layout.stickyContentTop }, { children: [_jsx(CardHeader, __assign({ heading: "Ship To Locations" }, { children: !!(distributor === null || distributor === void 0 ? void 0 : distributor._id) && (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ size: "sm", variant: "ghost", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () { return setEditMode(true); } }, { children: "New" }), void 0), _jsx(MerchantLocationForm, { merchantId: merchantId, distributorId: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '', isOpen: editMode, onCancel: function () { return setEditMode(false); }, onSuccess: function () {
                                refetch();
                                setEditMode(false);
                            } }, void 0)] }, void 0)) }), void 0), _jsx(CardBody, __assign({ pt: 0 }, { children: isLoading ? (_jsx(ContentLoader, { isLoading: isLoading, contentUnavailable: true }, void 0)) : (locations === null || locations === void 0 ? void 0 : locations.length) ? (_jsxs(VStack, __assign({ mt: 3, spacing: 3, alignItems: "stretch", divider: _jsx(Divider, {}, void 0) }, { children: [locations.map(function (location) { return (_jsxs(HStack, __assign({ justify: "space-between" }, { children: [_jsxs(Flex, { children: [_jsx(Box, __assign({ color: location.status === 'active'
                                                ? 'success.600'
                                                : 'critical.600', fontSize: "10px", pt: "4px", pr: "6px" }, { children: _jsx(RiCheckboxBlankCircleFill, {}, void 0) }), void 0), _jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", fontWeight: "medium", color: "gray.600" }, { children: location.business_name }), void 0), _jsx(Text, { children: location.address }, void 0)] }, void 0)] }, void 0), _jsx(Box, { children: _jsx(Button, __assign({ size: "sm", variant: "ghost", colorScheme: "primary", onClick: function () { return setActiveLocation(location); } }, { children: "Edit" }), void 0) }, void 0)] }), location._id)); }), _jsx(MerchantLocationForm, { action: "update", location: activeLocation, merchantId: merchantId, distributorId: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || '', isOpen: !!activeLocation, onCancel: function () { return setActiveLocation(undefined); }, onSuccess: function () {
                                refetch();
                                setActiveLocation(undefined);
                            } }, void 0)] }), void 0)) : (_jsx(Text, __assign({ color: "gray.600", py: "20px", align: "center" }, { children: "No location found" }), void 0)) }), void 0)] }), void 0));
};

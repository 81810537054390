var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { FiPlus } from 'react-icons/fi';
import { useFilter, useQueryStringParse } from '@obtainly/hooks';
import { useQueryModel } from '@obtainly/http';
import { formatUnixTime } from '@obtainly/utils';
import { useAtom, distributorAtom } from 'src/core/store';
import { useRouter } from 'src/core/hooks';
import { Card, CardBody, ContentHeader, ContentLoader, Exporter, Layout, PageTabs, } from 'src/core/components';
import { ComplaintsTable, ComplaintFilterMenu } from '../components';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["All"] = 0] = "All";
    PageTab[PageTab["Open"] = 1] = "Open";
    PageTab[PageTab["Closed"] = 2] = "Closed";
})(PageTab || (PageTab = {}));
export var Complaints = function () {
    var router = useRouter('complaints');
    var distributor = useAtom(distributorAtom)[0];
    var _a = useQueryModel('complaint.read_by_distributor', {
        distributor_id: (distributor === null || distributor === void 0 ? void 0 : distributor._id) || null,
    }), complaints = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var exportColumns = [
        {
            label: 'Ref.',
            accessor: 'ref',
            selected: true,
        },
        {
            label: 'Customer',
            accessor: 'merchant.business_name',
            selected: true,
        },
        {
            label: 'Tag',
            accessor: 'tag',
            selected: true,
        },
        {
            label: 'Status',
            accessor: 'status',
            selected: true,
        },
        {
            label: 'Date',
            accessor: 'created',
            modifier: function (value) { return String(formatUnixTime(value)); },
            selected: true,
        },
    ];
    var filters = useQueryStringParse([], ['page']);
    var filteredComplaints = useFilter(complaints || [], filters, {
        start_date: function (complaint) {
            return complaint.created >= Number(filters.start_date);
        },
        end_date: function (complaint) { return complaint.created <= Number(filters.end_date); },
    });
    var activeFilters = Object.keys(filters);
    var activeTab = React.useMemo(function () {
        if (activeFilters.length === 1) {
            return filters.status === 'open'
                ? PageTab.Open
                : filters.status === 'closed'
                    ? PageTab.Closed
                    : PageTab.All;
        }
        return PageTab.All;
    }, [filters, activeFilters]);
    var handleTabChange = function (index) {
        if (index === PageTab.Open) {
            router.switchQuery({ status: 'open' });
        }
        else if (index === PageTab.Closed) {
            router.switchQuery({ status: 'closed' });
        }
        else {
            router.go();
        }
    };
    return (_jsxs(Layout, __assign({ meta: { title: 'Complaints' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Complaints", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(ComplaintFilterMenu, { filters: filters }, void 0), _jsx(Exporter, { filename: "obtainly-complaints", columns: exportColumns, data: filteredComplaints }, void 0), _jsx(_Fragment, { children: _jsx(Button, __assign({ size: "sm", colorScheme: "primary", leftIcon: _jsx(FiPlus, {}, void 0), onClick: function () {
                                    router.go('complaint-new');
                                } }, { children: "New Complaint" }), void 0) }, void 0)] }), void 0) }), void 0), _jsxs(Card, { children: [_jsx(PageTabs, { index: activeTab, onChange: handleTabChange, tabs: [{ label: 'All' }, { label: 'Open' }, { label: 'Closed' }] }, void 0), _jsxs(CardBody, __assign({ p: 0, pt: 2 }, { children: [_jsx(ContentLoader, { isLoading: isLoading }, void 0), !isLoading && (_jsx(ComplaintsTable, { complaints: filteredComplaints || [], refetchComplaints: refetch }, void 0))] }), void 0)] }, void 0)] }), void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { Flex } from '@chakra-ui/layout';
import { useAtom, distributorAtom } from 'src/core/store';
import { Card, CardBody, ContentHeader, Layout, MonthYearPicker, PageTabs, } from 'src/core/components';
import { Exporter } from 'src/core/components';
import { DistributorDailySettlements } from '../components/DistributorDailySettlements';
import { DistributorCustomerPayments } from '../components/DistributorCustomerPayments';
import { DistributorSettlementPayments } from '../components';
import { useQueryModel } from '@obtainly/http';
import { ContentLoader } from '@obtainly/ui/web-shared';
import { formatCurrency, formatUnixTime } from '@obtainly/utils';
var PageTab;
(function (PageTab) {
    PageTab[PageTab["Daily"] = 0] = "Daily";
    PageTab[PageTab["Customer"] = 1] = "Customer";
    PageTab[PageTab["Settlement"] = 2] = "Settlement";
})(PageTab || (PageTab = {}));
export var SettlementsReport = function () {
    var _a, _b;
    var _c, _d, _e, _f, _g, _h, _j;
    var distributor = useAtom(distributorAtom)[0];
    var _k = useState(PageTab.Daily), activeTab = _k[0], setActiveTab = _k[1];
    var exportableColumns = (_a = {},
        _a[PageTab.Daily] = [
            { label: 'ID', accessor: '_id', selected: false },
            {
                label: 'Date',
                accessor: 'timestamp',
                selected: true,
                modifier: function (value) { return formatUnixTime(value, 'd MMM yyy'); },
            },
            {
                label: 'Opening Unsettled',
                accessor: 'balance',
                selected: true,
                modifier: function (value, payload) {
                    return String(formatCurrency(value - payload.value) || '0.00');
                },
            },
            {
                label: 'Customer Payment',
                accessor: 'payments.total',
                selected: true,
                modifier: formatCurrency || '-',
            },
            {
                label: 'Settlement',
                accessor: 'processed.total',
                modifier: function (value) { return formatCurrency(Math.abs(value)) || '-'; },
            },
            {
                label: 'Closing Unsettled',
                accessor: 'balance',
                modifier: formatCurrency,
            },
        ],
        _a[PageTab.Customer] = [
            { label: 'ID', accessor: '_id', selected: false },
            {
                label: 'Date',
                accessor: 'created',
                selected: true,
                modifier: function (value) { return formatUnixTime(value, 'd MMM yyy'); },
            },
            {
                label: 'Customer',
                accessor: 'description',
                selected: true,
                modifier: function (value) {
                    var _a;
                    try {
                        return (_a = JSON.parse(value || '')) === null || _a === void 0 ? void 0 : _a.merchant.business_name;
                    }
                    catch (error) {
                        return '-';
                    }
                },
            },
            {
                label: 'Payment',
                accessor: 'value',
                selected: true,
                modifier: formatCurrency || '-',
            },
        ],
        _a[PageTab.Settlement] = [
            { label: 'ID', accessor: '_id', selected: false },
            {
                label: 'Date',
                accessor: 'created',
                selected: true,
                modifier: function (value) { return formatUnixTime(value, 'd MMM yyy'); },
            },
            {
                label: 'Reference',
                accessor: 'description',
                selected: true,
                modifier: function (value) {
                    var _a;
                    try {
                        return (_a = JSON.parse(value || '')) === null || _a === void 0 ? void 0 : _a.reference;
                    }
                    catch (error) {
                        return '-';
                    }
                },
            },
            {
                label: 'Value',
                accessor: 'value',
                selected: true,
                modifier: formatCurrency || '-',
            },
        ],
        _a);
    var _l = React.useState(Math.floor(new Date().getTime() / 1000.0)), selectedDate = _l[0], setSelectedDate = _l[1];
    var payload = React.useMemo(function () {
        var date = fromUnixTime(selectedDate);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return {
            _id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            distributor_id: distributor === null || distributor === void 0 ? void 0 : distributor._id,
            month: month,
            year: year,
        };
    }, [distributor === null || distributor === void 0 ? void 0 : distributor._id, selectedDate]);
    var _m = useQueryModel('distributor.statement', payload, {
        enabled: !!payload._id,
    }), data = _m.data, isLoading = _m.isLoading;
    var settlements = (_b = {},
        _b[PageTab.Daily] = ((_d = (_c = data === null || data === void 0 ? void 0 : data.all) === null || _c === void 0 ? void 0 : _c.list_by_day) === null || _d === void 0 ? void 0 : _d.filter(function (_a) {
            var value = _a.value;
            return value !== 0;
        })) || [],
        _b[PageTab.Customer] = ((_f = (_e = data === null || data === void 0 ? void 0 : data.all) === null || _e === void 0 ? void 0 : _e.list) === null || _f === void 0 ? void 0 : _f.filter(function (_a) {
            var tag = _a.tag;
            return tag === 'pay-in';
        })) || [],
        _b[PageTab.Settlement] = ((_h = (_g = data === null || data === void 0 ? void 0 : data.all) === null || _g === void 0 ? void 0 : _g.list) === null || _h === void 0 ? void 0 : _h.filter(function (_a) {
            var tag = _a.tag;
            return tag === 'pay-out';
        })) || [],
        _b);
    return (_jsxs(Layout, __assign({ meta: { title: 'Daily Settlements' } }, { children: [_jsx(ContentHeader, __assign({ heading: "Daily Settlements", border: 0 }, { children: _jsxs(Flex, __assign({ gridGap: 2 }, { children: [_jsx(MonthYearPicker, { value: selectedDate, onChange: setSelectedDate, isFullWidth: false }, void 0), activeTab === PageTab.Daily && (_jsx(Exporter, { filename: "obtainly-daily-settlement-".concat(getUnixTime(new Date())), data: settlements[PageTab.Daily], columns: exportableColumns[PageTab.Daily], isDisabled: !settlements[PageTab.Daily].length }, void 0)), activeTab === PageTab.Customer && (_jsx(Exporter, { filename: "obtainly-daily-settlement-".concat(getUnixTime(new Date())), data: settlements[PageTab.Customer], columns: exportableColumns[PageTab.Customer], isDisabled: !settlements[PageTab.Customer].length }, void 0)), activeTab === PageTab.Settlement && (_jsx(Exporter, { filename: "obtainly-daily-settlement-".concat(getUnixTime(new Date())), data: settlements[PageTab.Settlement], columns: exportableColumns[PageTab.Settlement], isDisabled: !settlements[PageTab.Settlement].length }, void 0))] }), void 0) }), void 0), _jsx(Card, { children: isLoading && !((_j = settlements[activeTab]) === null || _j === void 0 ? void 0 : _j.length) ? (_jsx(ContentLoader, { isLoading: isLoading }, void 0)) : (_jsxs(PageTabs, __assign({ index: activeTab, tabs: [
                        'Daily Settlements',
                        'Customer Payments',
                        'Settlement Payments',
                    ], onChange: function (newIndex) { return setActiveTab(newIndex); } }, { children: [activeTab === PageTab.Daily && (_jsx(CardBody, __assign({ p: 0 }, { children: _jsx(DistributorDailySettlements, { settlements: settlements[activeTab], showHeader: false }, void 0) }), void 0)), activeTab === PageTab.Customer && (_jsx(CardBody, __assign({ p: 0 }, { children: _jsx(DistributorCustomerPayments, { settlements: settlements[activeTab], showHeader: false }, void 0) }), void 0)), activeTab === PageTab.Settlement && (_jsx(CardBody, __assign({ p: 0 }, { children: _jsx(DistributorSettlementPayments, { settlements: settlements[activeTab], showHeader: false }, void 0) }), void 0))] }), void 0)) }, void 0)] }), void 0));
};
